import {useEffect, useState} from "react";
import {anonymousUser, currentUserSubject, getCurrentUser, setCurrentUser} from "./current-user";
import {AuthenticationNeededException, getJsonFromBackend} from "./network";
import {AuthenticatedUser, BackendError, ReturnedError} from "./BackendClasses";

export async function getUserFromBackend() {
    await getJsonFromBackend("/auth/getAuthenticatedUser")
        // .then((user: AuthenticatedUser) => {
        .then((user: AuthenticatedUser) => {
            setCurrentUser(user);
        })
        .catch((err: any) => {
            setCurrentUser(err);
        })
}

getUserFromBackend();


export function useCurrentUser(): AuthenticatedUser {
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser>(getCurrentUser());

    useEffect(() => {
        const subscription = currentUserSubject.subscribe({
            next: (user) => user && isAuthenticatedUser(user) ? setCurrentUser(user) : setCurrentUser(anonymousUser)
        });
        return () => subscription.unsubscribe();
    }, []);

    return currentUser;
}

export function useCurrentUserOrError(): AuthenticatedUser | BackendError | ReturnedError {
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser | BackendError>(getCurrentUser());

    useEffect(() => {
        const subscription = currentUserSubject.subscribe({
            next: (user) => user ? setCurrentUser(user) : setCurrentUser(anonymousUser)
        });
        return () => subscription.unsubscribe();
    }, []);
    return currentUser;
}

export function isAuthenticatedUser(userOrError: any): userOrError is AuthenticatedUser {
    return "username" in userOrError && userOrError["username"] != null && userOrError["username"] !== '';
}


export function isAuthenticationNeededException(userOrError: any): userOrError is AuthenticationNeededException {
    return userOrError instanceof AuthenticationNeededException;
}

export function isUserError(userOrError: AuthenticatedUser | BackendError | ReturnedError): userOrError is BackendError {
    if(userOrError instanceof TypeError || userOrError instanceof  SyntaxError){
        return true;
    }

    return 'type' in userOrError && 'message' in userOrError;
}

export function isAnonymous(user: AuthenticatedUser){
    return user.username === '';
}