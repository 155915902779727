import {BehaviorSubject} from "rxjs";
import {AuthenticatedUser, BackendError} from "./BackendClasses";

export const anonymousUser: AuthenticatedUser = {
    id: 0,
    username: "",
    roles: [],
    fullName: "",
    hotels: []
};

let currentUser: AuthenticatedUser = anonymousUser;

export const currentUserSubject = new BehaviorSubject<AuthenticatedUser | BackendError | null> (anonymousUser);

export function setCurrentUser(user: AuthenticatedUser){
    currentUser = user;
    currentUserSubject.next(user);
}

export function getCurrentUser(): AuthenticatedUser {
    return currentUser;
}