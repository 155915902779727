import {useContext, useEffect} from "react";
import {GlobalContext} from "../../data/GlobalContext";
import {Link} from "react-router-dom";
import {useCurrentUser} from "../../data/user";
import {Role} from "../../data/BackendClasses";


export default function LogsPage() {

    const currentUser = useCurrentUser();


    return <div className="container mx-auto py-8 h-full">
        <div className="flex flex-wrap justify-center content-center w-full gap-2 h-full">

            {currentUser.roles.includes(Role.SUPERVISOR) && <>

                <div className="w-full md:w-1/2 lg:w-1/5 p-4 bg-white/70 h-1/2 backdrop-blur rounded-xl hover:opacity-50 shadow-md">
                    <Link to="/supervising">
                        <div className="h-1/2 flex flex-col grid content-end">
                            <i className="fa-solid fa-file-waveform fa-4x align-baseline m-auto"/>
                        </div>
                        <div className="h-1/2 flex flex-col">
                            <div className="m-auto text-xl">Supervising</div>
                        </div>
                    </Link>
                </div>

            </>}

            {currentUser.roles.includes(Role.BEDS24_INFO) && <>
                <div className="w-full md:w-1/2 lg:w-1/5 p-4 bg-white/70 h-1/2 backdrop-blur rounded-xl hover:opacity-50 shadow-md">
                    <Link to="/drop-down">
                        <div className="h-1/2 flex flex-col grid content-end">
                            <i className="fa-solid fa-circle-info fa-4x align-baseline m-auto"/>
                        </div>
                        <div className="h-1/2 flex flex-col">
                            <div className="m-auto text-xl">Drop Down</div>
                        </div>
                    </Link>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/5 p-4 bg-white/70 h-1/2 backdrop-blur rounded-xl hover:opacity-50 shadow-md">
                    <Link to="/internal-logs">
                        <div className="h-1/2 flex flex-col grid content-end">
                            <i className="fa-solid fa-circle-info fa-4x align-baseline m-auto"/>
                        </div>
                        <div className="h-1/2 flex flex-col">
                            <div className="m-auto text-xl">Internal Logs</div>
                        </div>
                    </Link>
                </div>

                <div className="w-full md:w-1/2 lg:w-1/5 p-4 bg-white/70 h-1/2 backdrop-blur rounded-xl hover:opacity-50 shadow-md">
                    <Link to="/internal-notes">
                        <div className="h-1/2 flex flex-col grid content-end">
                            <i className="fa-solid fa-circle-info fa-4x align-baseline m-auto"/>
                        </div>
                        <div className="h-1/2 flex flex-col">
                            <div className="m-auto text-xl">Internal Notes</div>
                        </div>
                    </Link>
                </div>
            </>}

        </div>
    </div>

}