import {Sidebar} from "primereact/sidebar";
import React, {useState} from "react";
import {useCurrentUser} from "../data/user";
import {Link, matchPath, useLocation} from "react-router-dom";
import {Role} from "../data/BackendClasses";
import {AnimatePresence, motion} from "framer-motion";
import FlipingText from "../components/FlipingText";
import BouncingRightText from "../components/BouncingRightText";


export default function CustomSidebar({sidebarVisible, setSidebarVisible}:{sidebarVisible: boolean, setSidebarVisible: (visible: boolean) => void}) {
    const location = useLocation();
    const currentUser = useCurrentUser();

    const [managementExpanded, setManagementExpanded] = useState<boolean>(false);
    const [logsExpanded, setLogsExpanded] = useState<boolean>(false);
    const [siteminderExpanded, setSiteminderExpanded] = useState<boolean>(false);
    const [reviewsExpanded, setReviewsExpanded] = useState<boolean>(false);
    const [cloudbedsExpanded, setCloudbedsExpanded] = useState<boolean>(false);
    const [derbysoftExpanded, setDerbysoftExpanded] = useState<boolean>(false);

    function pathBeginsWith(path: string) {
        return matchPath(
            {
                path: path,
                end: true
            },
            location.pathname
        )
    }

    function isCurrentPath(path: string){
        const isCurrentPath = pathBeginsWith(path);
        return isCurrentPath ? 'text-blue-500' : ''
    }

    return <Sidebar visible={sidebarVisible} onHide={() => setSidebarVisible(false)} className="!w-fit">
        <div className="flex flex-col gap-2 font-normal text-gray-500">
            <span className={`text-lg mt-2 hover:text-blue-500 ${isCurrentPath("/")}`}>
                <Link to="/" onClick={() => setSidebarVisible(false)} className="flex flex-row">
                    <i className="fa-solid fa-home mr-2 my-auto"/>
                    Home
                </Link>
            </span>
            {currentUser.roles.includes(Role.ADMIN) &&<>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer hover:text-blue-500 flex flex-row"
                    onClick={() => setManagementExpanded((managementExpanded) => !managementExpanded)}
                >
                    <i className="fa-solid fa-cog mr-2 my-auto"/>
                    Management
                    <i className={`fa-solid fa-caret-${managementExpanded ? "up" : "down"} ml-2 my-auto`}/>
                </motion.header>

                <AnimatePresence initial={false}>
                    {managementExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.5 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/management-users")}`}>
                                <Link to="/management-users" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-user mr-2"/>
                                    Users
                                </Link>
                            </span>
                                <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/management-hotels")}`}>
                                <Link to="/management-hotels" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Hotels
                                </Link>
                            </span>
                        </motion.div>

                    </motion.section>}
                </AnimatePresence>
            </>}

            {(currentUser.roles.includes(Role.TRANSACTION_DEFAULT) || currentUser.roles.includes(Role.TRANSACTION_GLOBAL)) &&<span className={`text-lg mt-2 hover:text-blue-500 ${isCurrentPath("/transaction-history")}`}>
                <Link to="/transaction-history" onClick={() => setSidebarVisible(false)} className="flex flex-row">
                    <i className="fa-solid fa-file-arrow-down mr-2 my-auto"/>
                    Transaction History
                </Link>
            </span>}

            {currentUser.roles.includes(Role.CHAT) &&<span className={`text-lg mt-2 hover:text-blue-500 ${isCurrentPath("/chat")}`}>
                <Link to="/chat" onClick={() => setSidebarVisible(false)} className="flex flex-row">
                    <i className="fa-solid fa-comment-dots mr-2 my-auto"/>
                    Chat
                </Link>
            </span>}

            {currentUser.roles.includes(Role.BEDS24_INFO) &&<span className={`text-lg mt-2 hover:text-blue-500 ${isCurrentPath("/low-inventory-scanner")}`}>
                <Link to="/low-inventory-scanner" onClick={() => setSidebarVisible(false)} className="flex flex-row">
                    <i className="fa-solid fa-boxes-stacked mr-2 my-auto"/>
                    Low Inventory Scanner
                </Link>
            </span>}

            {(currentUser.roles.includes(Role.SUPERVISOR) || currentUser.roles.includes(Role.BEDS24_INFO)) &&<>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer hover:text-blue-500 flex flex-row"
                    onClick={() => setLogsExpanded((logsExpanded) => !logsExpanded)}
                >
                    <i className="fa-solid fa-file-waveform mr-2 my-auto"/>

                    Logs
                    <i className={`fa-solid fa-caret-${logsExpanded ? "up" : "down"} ml-2 my-auto`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {logsExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/supervising")}`}>
                                <Link to="/supervising" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-file-waveform mr-2"/>
                                    Supervising
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/drop-down")}`}>
                                <Link to="/drop-down" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-circle-info mr-2"/>
                                    Drop Down
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/internal-logs")}`}>
                                <Link to="/internal-logs" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-circle-info mr-2"/>
                                    Internal Logs
                                </Link>
                            </span>
                                <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/internal-notes")}`}>
                                <Link to="/internal-notes" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-circle-info mr-2"/>
                                    Internal Notes
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/transaction-logs")}`}>
                                <Link to="/transaction-logs" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-file-waveform mr-2"/>
                                    Transaction Logs
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.REVIEW) &&<>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer hover:text-blue-500"
                    onClick={() => setReviewsExpanded((reviewsExpanded) => !reviewsExpanded)}
                >
                    <i className="fa-solid fa-file-waveform mr-2"/>
                    Reviews
                    <i className={`fa-solid fa-caret-${reviewsExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {reviewsExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/reviews-hotel")}`}>
                                    <Link to="/reviews-hotel" onClick={() => setSidebarVisible(false)}>
                                        <i className="fa-solid fa-hotel mr-2"/>
                                        Reviews Hotel
                                    </Link>
                                </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/reviews-hotel-public")}`}>
                                <Link to="/reviews-hotel-public" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Reviews Hotel Public
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.SITEMINDER) && <>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer hover:text-blue-500"
                    onClick={() => setSiteminderExpanded((siteminderExpanded) => !siteminderExpanded)}
                >
                    <i className="fa-solid fa-file-waveform mr-2"/>
                    Siteminder
                    <i className={`fa-solid fa-caret-${siteminderExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {siteminderExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/hotels-spreadsheet")}`}>
                                <Link to="/hotels-spreadsheet" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Siteminder Hotels
                                </Link>
                            </span>
                                <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/siteminder-history")}`}>
                                <Link to="/siteminder-history" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-file-waveform mr-2"/>
                                    Siteminder Bookings
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/siteminder-receiver")}`}>
                                <Link to="/siteminder-receiver" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-circle-info mr-2"/>
                                    Siteminder Receiver Bookings
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/siteminder-receiver-history")}`}>
                                <Link to="/siteminder-receiver-history" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-circle-info mr-2"/>
                                    Siteminder Receiver Inventory
                                </Link>
                            </span>
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/siteminder-cancellation")}`}>
                                <Link to="/siteminder-cancellation" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-circle-info mr-2"/>
                                    Siteminder Booking Cancellation
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.CANCELLATION) &&<>
                <span className={`text-lg mt-2 hover:text-blue-500 ${isCurrentPath("/cancellation")}`}>
                    <Link to="/cancellation" onClick={() => setSidebarVisible(false)}>
                        <i className="fa-solid fa-cloud-arrow-up mr-2"/>
                        Cancellation Airbnb Bookings
                    </Link>
                </span>
            </>}

            {(currentUser.roles.includes(Role.CLOUDBEDS_RESTRICTED) || currentUser.roles.includes(Role.CLOUDBEDS_MASTER) || currentUser.roles.includes(Role.ADMIN) || currentUser.roles.includes(Role.CLOUDBEDS_LOGS)) && <>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer hover:text-blue-500"
                    onClick={() => setCloudbedsExpanded((cloudbedsExpanded) => !cloudbedsExpanded)}
                >
                    <i className="fa-solid fa-cloud mr-2"/>
                    Cloundbeds
                    <i className={`fa-solid fa-caret-${cloudbedsExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>

                <AnimatePresence initial={false}>
                    {cloudbedsExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >

                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            {(currentUser.roles.includes(Role.CLOUDBEDS_MASTER) || currentUser.roles.includes(Role.CLOUDBEDS_RESTRICTED)) && <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/cloudbeds-calendar")}`}>
                                <Link to="/cloudbeds-calendar" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-calendar mr-2"/>
                                    Details Calendar
                                </Link>
                            </span>}

                            {(currentUser.roles.includes(Role.CLOUDBEDS_MASTER) || currentUser.roles.includes(Role.CLOUDBEDS_RESTRICTED))  && <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/cloudbeds-bookings")}`}>
                                <Link to="/cloudbeds-bookings" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-calendar mr-2"/>
                                    Bookings
                                </Link>
                            </span>}

                            {(currentUser.roles.includes(Role.ADMIN) || currentUser.roles.includes(Role.CLOUDBEDS_LOGS)) && <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/cloudbeds-calendar-logs")}`}>
                                <Link to="/cloudbeds-calendar-logs" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-file-waveform mr-2"/>
                                    Logs
                                </Link>
                            </span>}
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}

            {currentUser.roles.includes(Role.SITEMINDER) && <>
                <motion.header
                    className="text-lg mt-2 hover:cursor-pointer hover:text-blue-500"
                    onClick={() => setDerbysoftExpanded((derbysoftExpanded) => !derbysoftExpanded)}
                >
                    <i className="fa-solid fa-file-waveform mr-2"/>
                    Derbysoft
                    <i className={`fa-solid fa-caret-${derbysoftExpanded ? "up" : "down"} ml-2`}/>
                </motion.header>
                <AnimatePresence initial={false}>
                    {derbysoftExpanded && <motion.section
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        className="flex flex-col"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                        <motion.div
                            variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                            transition={{ duration: 0.98 }}
                            className="flex flex-col"
                        >
                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/derbysoft-hotels-spreadsheet")}`}>
                                <Link to="/derbysoft-hotels-spreadsheet" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Derbysoft Hotels
                                </Link>
                            </span>

                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/derbysoft-history")}`}>
                                <Link to="/derbysoft-history" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Derbysoft History
                                </Link>
                            </span>

                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/derbysoft-receiver")}`}>
                                <Link to="/derbysoft-receiver" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Derbysoft Receiver
                                </Link>
                            </span>

                            <span className={`text-lg ml-5 hover:text-blue-500 ${isCurrentPath("/derbysoft-receiver-history")}`}>
                                <Link to="/derbysoft-receiver-history" onClick={() => setSidebarVisible(false)}>
                                    <i className="fa-solid fa-hotel mr-2"/>
                                    Derbysoft Receiver History
                                </Link>
                            </span>
                        </motion.div>
                    </motion.section>}
                </AnimatePresence>
            </>}
        </div>

    </Sidebar>
}