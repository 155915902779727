import {Dialog} from "primereact/dialog";
import {Booking} from "../../../data/BackendClasses";
import {useState} from "react";
import {formatDateWithoutTime} from "../../../lib/helpers";


export default function BookingDetailsDialog({showDialog, booking, onHide}:{showDialog: boolean, booking: Booking, onHide: () => void}) {

    const [showPhone, setShowPhone] = useState<boolean>(false);

    function daysBetween(startDate: string, endDate: string): number {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const differenceInMilliseconds = endDateObj.getTime() - startDateObj.getTime();
        // One day in milliseconds
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.ceil(differenceInMilliseconds / oneDay);
    }

    return <>
        <Dialog
            header={
                <div className="flex flex-row">
                    <div className="flex flex-col text-xl">
                        <div>
                            Reservation id:
                            <span className="font-bold ml-2 italic">{booking.id}</span>
                        </div>
                        <div>
                            Room:
                            <span className="font-bold italic ml-2">{booking.roomId}</span>
                        </div>
                    </div>
                    <div className="ml-auto mr-5 px-1 rounded-full px-3 py-2 text-sm bg-green-300 content-center h-fit my-auto">
                        Booked - {formatDateWithoutTime(booking.bookingTime)}
                    </div>

                </div>

            }
            visible={showDialog}
            style={{ width: '50vw', height: "70vw" }}
            onHide={onHide}
        >

            <div className="flex flex-row h-full italic">
                <div className="flex flex-col w-1/3 h-full gap-2 pl-2">
                    <div className="flex flex-col">
                        <div className="text-slate-500">Status</div>
                        <div className="text-xl font-semibold text-md">{booking.status.toUpperCase()}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-500">Check in</div>
                        <div className="text-2xl font-semibold">{booking.arrival}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-500">Check out</div>
                        <div className="text-2xl font-semibold">{booking.departure}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-500">Length of stay</div>
                        <div className="font-semibold">{daysBetween(booking.arrival, booking.departure)} nights</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-500">Total guests</div>
                        <div className="font-semibold">{`${booking.numAdult} adults ${booking.numChild > 0 ? `, ${booking.numChild} ${booking.numChild > 1 ? "children" : "child"}` : ""}`}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-500">Total rooms</div>
                        <div className="font-semibold">{booking.roomQty}</div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-slate-500">Total price</div>
                        <div className="text-2xl font-semibold">US${Number(booking.price + booking.commission + booking.tax).toFixed(2)}</div>
                    </div>

                </div>
                <div className="flex flex-col w-2/3 h-full pr-2">
                    <div className="flex flex-col">
                        <div className="text-slate-500">Guest name:</div>
                        <div className="text-2xl font-bold text-blue-400">{booking.firstName} {booking.lastName}</div>
                    </div>

                    <div className="flex flex-col mt-10">
                        {booking.email && <div className="flex flex-row mb-1">
                            Email:
                            <a className="text-blue-400 hover:underline ml-2" href={`mailto:${booking.email}`}>{booking.email}</a>
                        </div>}

                        <div className="flex flex-col mb-5 gap-2">
                            <span>
                                Connect with your guests! Let them know what time you'd like to welcome them or where they should pick up their keys.
                                They're just a phone call away:
                            </span>
                            <div className="text-blue-400 font-bold">
                                {showPhone
                                    ? <><i className="fa-solid fa-phone mr-1"/> <a href={`tel:${booking.phone}`}>{booking.phone}</a></>
                                    : <div className="hover:cursor-pointer hover:text-blue-500" onClick={() => setShowPhone(true)}>
                                        <i className="fa-regular fa-eye" /> Show phone number
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col">
                                <div className="text-slate-500">Channel:</div>
                                <div className="text-xl font-bold text-blue-400">{booking.referer}</div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-col w-1/2">
                                    <div className="text-slate-500">Booking number:</div>
                                    <div className="text-xl font-bold text-blue-400">{booking.id}</div>
                                </div>
                                <div className="flex flex-col 1/2">
                                    <div className="text-slate-500">Commisionable account:</div>
                                    <div className="text-xl font-bold text-blue-400">{booking.price}</div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-col w-1/2">
                                    <div className="text-slate-500">Commission:</div>
                                    <div className="text-xl font-bold text-blue-400">{booking.commission}</div>
                                </div>
                                <div className="flex flex-col 1/2">
                                    <div className="text-slate-500">Tax:</div>
                                    <div className="text-xl font-bold text-blue-400">{booking.tax}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Dialog>
    </>

}