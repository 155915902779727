import {Link} from "react-router-dom";
import {Conversation} from "../../data/BackendClasses";
import {useContext, useEffect, useRef, useState} from "react";
import {postJsonToBackend} from "../../data/network";
import {formatDate} from "../../lib/helpers";
import {GlobalContext} from "../../data/GlobalContext";

export default function DetailsPannel({currentConversation} : {currentConversation: Conversation}) {

    const [adminComment, setAdminComment] = useState<string | undefined>(currentConversation.admin_comment? currentConversation.admin_comment : '');
    const {showToastMessage} = useContext(GlobalContext);

    const contentRef = useRef(null);
    const [showExpandButton, setShowExpandButton] = useState<boolean>(false);
    const [expandDetails, setExpandDetails] = useState<boolean>(false);

    function addComment() {
        postJsonToBackend("/chat/conversations/add-comment", {...currentConversation, 'admin_comment': adminComment})
    }

    useEffect(() => {
        setAdminComment(currentConversation.admin_comment);

        const contentDiv = contentRef.current;

        // @ts-ignore
        if (contentDiv.scrollHeight > contentDiv.clientHeight) {
            setShowExpandButton(true);
        } else {
            setShowExpandButton(false);
        }

    }, [currentConversation])

    function currentConversationHasSpecialStatus(): boolean|undefined {
        return currentConversation.wantToChange || currentConversation.unlisted || currentConversation.preApproved;
    }

    function currentConversationIsInquiry() {
        return currentConversation.status == 'inquiry';
    }

    function specialStatusWasDone() : boolean {
        return currentConversation.specialStatusDone ? currentConversation.specialStatusDone : false
    }

    function renderSpecialStatus() {
        if(currentConversation.preApproved)
            return "Pre Approve"
        if(currentConversation.unlisted)
            return "Unlisted"
        if(currentConversation.wantToChange)
            return "Wants to change"
    }

    function showModifyAdminCommentButton() {
        if(adminComment == "" && currentConversation.admin_comment == undefined)
            return false;
        return (adminComment != currentConversation.admin_comment)
    }

    function markAsDone() {
        postJsonToBackend("/chat/conversations/mark-as-done", currentConversation.bookingId)
            .then(rsp => showToastMessage("Success", "Special status was marked as done", "success"))
            .catch(err => showToastMessage("Error", "Something went wrong", "error"));
    }


    return <div className="flex h-full w-1/4 rounded-lg shadow-md">
        <div className="flex flex-col w-full h-full">
            <div className="px-6 py-4 bg-white/95 flex flex-row justify-between rounded-t-lg">
                <h5 className="font-bold text-xl">Details</h5>
            </div>
            <div className="flex flex-col flex-auto flex-grow bg-white h-full overflow-y-auto p-1 rounded-b-lg">
                <div className={`flex flex-col grow ${!expandDetails ? "overflow-y-auto max-h-1/3" : ""} bg-white/50 rounded pl-1 pt-1`} ref={contentRef}>
                    <div className="rounded shadow p-1 mb-1"><span>Booking ID:</span> {currentConversation.bookingId}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Hotel Name:</span> {currentConversation.hotel_name}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Hotel Address:</span> {currentConversation.hotel_address}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Hotel Phone:</span> {currentConversation.hotel_phone}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Room Name:</span> {currentConversation.room_name}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Guest Name:</span> {currentConversation.guest_name}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Guest Email:</span> {currentConversation.guest_email}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Phone Number:</span> {currentConversation.phone_number}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Arrival:</span> {currentConversation.arrival}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Departure:</span> {currentConversation.departure}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Unit ID:</span> {currentConversation.unitId}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Room Quantity:</span> {currentConversation.roomQty}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Adults:</span> {currentConversation.numAdult}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Children:</span> {currentConversation.numChild}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Booking time:</span> {formatDate(currentConversation.bookingTime)}</div>
                    <div className="rounded shadow p-1 mb-1"><span>Price:</span> {currentConversation.price}</div>
                </div>

                {showExpandButton && !expandDetails && <div className="flex flex-row justify-center">
                    <button
                        className="text-xs w-fit rounded text-slate-500"
                        onClick={() => setExpandDetails(true)}
                    ><i className="fa-solid fa-chevron-down"/></button>
                </div>}

                {showExpandButton && expandDetails && <div className="flex flex-row justify-center">
                    <button
                        className="text-xs w-fit rounded text-slate-500"
                        onClick={() => setExpandDetails(false)}
                    ><i className="fa-solid fa-chevron-up"/></button>
                </div>}

                <div className="flex flex-col max-h-1/4 bg-white/50 rounded p-2 justify-center mt-1">
                    <div className="mt-2 mb-2 flex flex-col">
                        <label htmlFor="admin_comment" className="font-bold ml-1">Admin Comment</label>
                        <textarea className="custom-form bg-amber-100" name="admin_comment" id="admin_comment" value={adminComment? adminComment : ''} onChange={(e: any) => setAdminComment(e.target.value)}/>
                    </div>
                    <div className="text-center">
                        {showModifyAdminCommentButton() && <button className="text-sm p-1 rounded text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500" onClick={addComment}>Modify</button>}
                    </div>
                </div>

                {/*SPECIAL STATUS*/}
                {currentConversationHasSpecialStatus() && <div className={`flex flex-col max-h-1/3 bg-white/50 rounded p-2 items-center mt-1 ${specialStatusWasDone() ? "opacity-60" : ""}`}>
                    <span className="font-bold">
                        {!specialStatusWasDone() && <i className="fa-solid fa-exclamation fa-shake text-orange-500 fa-2x mr-1"/>}
                        {renderSpecialStatus()}
                    </span>

                    <span className="whitespace-pre-wrap">{currentConversation.specialStatusText}</span>

                    <div className="flex flex-row items-center gap-1 mt-1">
                        {currentConversation.specialStatusUrl && <Link
                            target="_blank"
                            to={currentConversation.specialStatusUrl}
                            className={`${specialStatusWasDone() ? "text-white bg-gray-500 rounded w-fit text-sm p-1" : "text-sm w-fit p-1 rounded text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500"}`}
                        >
                            <button disabled={specialStatusWasDone()}>{currentConversation.specialStatusButtonText}</button>
                        </Link>}
                        {!specialStatusWasDone() && (currentConversation.wantToChange || currentConversation.preApproved) && <button className="text-sm w-fit p-1 rounded text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500" onClick={markAsDone}>Mark as done</button>}
                    </div>

                </div>}

                {/*INQUIRY*/}
                {!currentConversationHasSpecialStatus() && currentConversationIsInquiry() && <div className={`flex flex-col max-h-1/3 bg-white/50 rounded p-2 items-center mt-1 ${specialStatusWasDone() ? "opacity-60" : ""}`}>
                    <span className="font-bold">
                        {!specialStatusWasDone() && <i className="fa-solid fa-exclamation fa-shake text-orange-500 fa-2x mr-1"/>}
                        Inquiry
                    </span>

                    <span className="whitespace-pre-wrap">This guest requested an inquiry</span>
                    {/*<span className="whitespace-pre-wrap">If you want to pre-approve click here</span>*/}

                    <div className="flex flex-row items-center gap-1 mt-1">
                        {<Link
                            target="_blank"
                            to={`https://www.airbnb.com/hosting/reservations/thread/${currentConversation.apiReference}?isBessieThread=true`}
                            className={`${specialStatusWasDone() ? "text-sm w-fit p-1 rounded text-white bg-gray-500" : "text-sm w-fit p-1 rounded text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500"}`}
                        >
                            <button disabled={specialStatusWasDone()}>Pre-approve</button>
                        </Link>}
                        {!specialStatusWasDone() && (currentConversationIsInquiry()) && <button className="text-sm p-1 rounded w-fit text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500" onClick={markAsDone}>Mark as done</button>}
                    </div>

                </div>}

                <div className="flex flex-row mt-1 bg-white/50 rounded p-2 gap-1 justify-center">
                    <Link target="_blank" to={currentConversation.beds24_url} className="text-sm p-1 rounded text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500">Access Beds24 information</Link>
                    <Link target="_blank" to={currentConversation.airbnb_id} className="text-sm p-1 rounded text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500">Access AirBnb information</Link>
                </div>


            </div>
        </div>
    </div>
}