export default function Login({children}: { children: any }) {

    const baseUrl = process.env.PUBLIC_URL + "";

    return <div className="flex mx-auto py-8 px-4 h-screen w-full">
        <div className="flex flex-col flex-wrap justify-center content-center w-full gap-2 h-full">
            <div className="container mb-20 w-full">
                <img src={baseUrl + "/name_logo.png"} className="w-1/3 m-auto"/>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 p-4 mx-auto bg-white/70 backdrop-blur rounded-xl shadow-xl max-w-[400px] max-h-[400px]">
                {children}
            </div>

        </div>
    </div>

}