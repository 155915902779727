import {useFormState} from "../../../data/Form";
import {postJsonToBackend} from "../../../data/network";
import {formHasErrors, isNumber} from "../../../lib/helpers";
import React, {useContext} from "react";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {GlobalContext} from "../../../data/GlobalContext";

const initialState = {hotelName: '', hotelProfile: '', propertyId: '', category: '', taxPercent: '', airbnbCom: '', rocketstayShare: ''};
export default function AddHotelComponent({afterAdd, hideComponent}:{afterAdd: () => void, hideComponent: () => void}) {

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState)
    const erori: { [x: string]: string[] } = {hotelName: [], hotelProfile: [], propertyId: [], category: [], taxPercent: [], airbnbCom: [], rocketstayShare: []};
    const {showToastMessage} = useContext(GlobalContext);

    async function addHotel(e: any) {
        e.preventDefault();
        // await postJsonToBackend("/hotels/add", {hotelName: inputValues.hotelName, hotelProfile: inputValues.hotelProfile, propertyId: inputValues.propertyId});
        await postJsonToBackend("/hotels/add", inputValues).then(rsp => {
            resetInputValues(initialState);
            afterAdd();
        }).catch(err => {
            console.log("ERR ", err);
            if(isReturnedErrors(err)){
                showToastMessage("Error", err.errors[0].message, "error");
            } else {
                showToastMessage("Error", "Someting went bat", "error");
            }
        });

    }

    if (!inputValues.hotelName && dirty) {
        erori.hotelName.push("Hotel name is mandatory");
    }

    if(inputValues.propertyId && !/^\d+$/.test(inputValues.propertyId)){
        erori.propertyId.push("Property Id should be a number");
    }

    if(dirty && !(inputValues.propertyId.length > 0 || inputValues.hotelProfile.length > 0)){
        erori.propertyId.push("At least one of this fields is mandatory");
        erori.hotelProfile.push("At least one of this fields is mandatory");
    }

    if(dirty && (inputValues.category != '') && !isNumber(inputValues.category)){
        erori.category.push("Category should be a numeric value");
    }

    if(dirty && (inputValues.taxPercent != '') && !isNumber(inputValues.taxPercent)){
        erori.taxPercent.push("Tax Percent should be a numeric value");
    }

    if(dirty && (inputValues.airbnbCom != '') && !isNumber(inputValues.airbnbCom)){
        erori.airbnbCom.push("Airbnb Com should be a numeric value");
    }

    if(dirty && (inputValues.rocketstayShare != '') && !isNumber(inputValues.rocketstayShare)){
        erori.rocketstayShare.push("Rocketstay Share should be a numeric value");
    }


    return <>
        <div className="shadow-md bg-white/70 rounded-lg flex flex-col">
            <div className="px-6 py-4 bg-white/50 flex rounded-t-lg text-[#644243] font-bold text-md">
                <div>New Hotel</div>
                <div className="ml-auto">
                    <i
                        className="fa-regular fa-circle-xmark fa-lg text-red-900 hover:text-red-700 cursor-pointer"
                        onClick={(e) => hideComponent()}
                    />
                </div>
            </div>
            <div className="flex flex-col py-4 px-6">
                <form onSubmit={addHotel}>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelName.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="hotelName">Full Name</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelName.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="hotelName" id="hotelName" type="text" value={inputValues.hotelName} onChange={handleInputChange}/>
                        {erori.hotelName.length > 0 && <div className="text-red-500 text-sm">{erori.hotelName[erori.hotelName.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelProfile.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="hotelProfile">Hotel Profile</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelProfile.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="hotelProfile" id="hotelProfile" type="text" value={inputValues.hotelProfile} onChange={handleInputChange}/>
                        {erori.hotelProfile.length > 0 && <div className="text-red-500 text-sm">{erori.hotelProfile[erori.hotelProfile.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.propertyId.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="propertyId">Property ID</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.propertyId.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="propertyId" id="propertyId" type="text" value={inputValues.propertyId} onChange={handleInputChange}/>
                        {erori.propertyId.length > 0 && <div className="text-red-500 text-sm">{erori.propertyId[erori.propertyId.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.category.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="category">Category</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.category.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="category" id="category" type="text" value={inputValues.category} onChange={handleInputChange}/>
                        {erori.category.length > 0 && <div className="text-red-500 text-sm">{erori.category[erori.category.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.taxPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPercent">Tax Percent</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPercent" id="taxPercent" type="text" value={inputValues.taxPercent} onChange={handleInputChange}/>
                        {erori.taxPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPercent[erori.taxPercent.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.airbnbCom.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="airbnbCom">Airbnb Com</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.airbnbCom.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="airbnbCom" id="airbnbCom" type="text" value={inputValues.airbnbCom} onChange={handleInputChange}/>
                        {erori.airbnbCom.length > 0 && <div className="text-red-500 text-sm">{erori.airbnbCom[erori.airbnbCom.length-1]}</div>}
                    </div>

                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.rocketstayShare.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="rocketstayShare">Rocketstay Share</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.rocketstayShare.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="rocketstayShare" id="rocketstayShare" type="text" value={inputValues.rocketstayShare} onChange={handleInputChange}/>
                        {erori.rocketstayShare.length > 0 && <div className="text-red-500 text-sm">{erori.rocketstayShare[erori.rocketstayShare.length-1]}</div>}
                    </div>

                    <div className="w-full text-center">
                        {!formHasErrors(erori) && dirty && <button
                            type="submit"
                            className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 px-2 py-1"
                        >
                            Add
                        </button>}
                    </div>
                </form>
            </div>
        </div>
    </>
}