import React, {useContext, useState} from 'react';
import {isAnonymous, isAuthenticatedUser, useCurrentUser} from "../data/user";
import {postJsonToBackend} from "../data/network";
import {GlobalContext} from "../data/GlobalContext";
import "./Header.scss";
import {Dialog} from "primereact/dialog";
import ChangePasswordComponent from "../components/ChangePasswordComponent";


export default function Header() {

    const currentUser = useCurrentUser();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const {showToastMessage, sidebarVisible, setSidebarVisible} = useContext(GlobalContext);
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState<boolean>(false);

    const toggleDropdown = () => {
        setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
    };

    function logout() {
        postJsonToBackend("/auth/logout").then(res => window.location.reload());
    }

    function toggleSidebarVisible(e: any) {
        // @ts-ignore
        setSidebarVisible(sidebarVisible => !sidebarVisible);
    }

    return <div className="flex flex-row h-fit items-center" id="header">

        <i
            className={`fa-solid fa-bars fa-xl ml-5 hover:text-[#644243]-500 hover:cursor-pointer`}
            onClick={toggleSidebarVisible}
        />

        {isAuthenticatedUser(currentUser) && !isAnonymous(currentUser) && <div className="ml-auto">
            <i className="fa-regular fa-user fa-xl mr-5 mt-5 hover:cursor-pointer" onClick={toggleDropdown}/>
            {isDropdownOpen && (
                <div className="mt-2 min-w-fit bg-neutral-200 rounded-xl absolute right-0 origin-top-right transform transition-transform flex flex-col z-50 text-center">
                    <div className="p-4 flex flex-col w-full">
                        <p className="text-center font-bold">{currentUser.fullName}</p>
                        <p className="text-center font-semibold">({currentUser.username})</p>
                        {/*{currentUser.roles.map((role, index) => {*/}
                        {/*    return <p key={index} className="w-full text-center text-gray-800 mt-2">{role}</p>*/}
                        {/*})}*/}
                    </div>

                    <div className="text-center w-full">
                        <button
                            className="w-3/4 text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                            onClick={logout}
                        >
                            Logout

                        </button>
                    </div>

                    <div className="text-center w-full">
                        <button
                            className="w-3/4 text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                            onClick={() => setShowChangePasswordDialog((showChangePasswordDialog) => !showChangePasswordDialog)}
                        >
                            Change Password

                        </button>
                    </div>

                </div>

            )}
        </div>}

        <Dialog
            header="Change password"
            visible={showChangePasswordDialog}
            onHide={() => setShowChangePasswordDialog((showChangePasswordDialog) => !showChangePasswordDialog)}
            style={{width: '30vw'}}
        >
            <ChangePasswordComponent
                onSuccess={() => {
                    setShowChangePasswordDialog(false);
                    showToastMessage("Success", "Password changed successfully", "success");
                    toggleDropdown();
                }}
            />

        </Dialog>

    </div>
}