/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-09-09 18:51:12.

export class AppUser {
    id: number;
    username: string;
    password: string;
    fullName: string;
    roles: UserRole[];
    hotels: Hotel[];

    constructor(data: AppUser) {
        this.id = data.id;
        this.username = data.username;
        this.password = data.password;
        this.fullName = data.fullName;
        this.roles = data.roles;
        this.hotels = data.hotels;
    }
}

export class AppUserWithHotelsWithoutUsers {
    id: number;
    username: string;
    password: string;
    fullName: string;
    roles: UserRole[];
    hotels: HotelWithoutUsers[];

    constructor(data: AppUserWithHotelsWithoutUsers) {
        this.id = data.id;
        this.username = data.username;
        this.password = data.password;
        this.fullName = data.fullName;
        this.roles = data.roles;
        this.hotels = data.hotels;
    }
}

export class BackendError {
    type: string;
    message: string;
    cause?: BackendError;

    constructor(data: BackendError) {
        this.type = data.type;
        this.message = data.message;
        this.cause = data.cause;
    }
}

export class ReturnedError {
    errors: BackendError[];

    constructor(data: ReturnedError) {
        this.errors = data.errors;
    }
}

export class AuthenticatedUser {
    id: number;
    username: string;
    roles: Role[];
    fullName: string;
    hotels: HotelWithoutUsers[];

    constructor(data: AuthenticatedUser) {
        this.id = data.id;
        this.username = data.username;
        this.roles = data.roles;
        this.fullName = data.fullName;
        this.hotels = data.hotels;
    }
}

export class Conversation {
    bookingId: number;
    roomId: number;
    propertyId: number;
    guest_email: string;
    guest_name: string;
    phone_number: string;
    arrival: string;
    departure: string;
    unitId: number;
    roomQty: number;
    numAdult: number;
    numChild: number;
    apiReference: string;
    bookingTime: Date;
    price: number;
    url: string;
    media_type: string;
    lastMessageTime: Date;
    lastMessage: string;
    lastMessageSource: string;
    hotel_name: string;
    hotel_address: string;
    hotel_phone: string;
    room_name: string;
    status: string;
    referer: string;
    beds24_url: string;
    airbnb_id: string;
    admin_comment: string;
    markedAsRead?: boolean;
    unlisted: boolean;
    preApproved: boolean;
    wantToChange: boolean;
    specialStatusText?: string;
    specialStatusUrl?: string;
    specialStatusButtonText?: string;
    listingId?: string;
    specialStatusDone?: boolean;

    constructor(data: Conversation) {
        this.bookingId = data.bookingId;
        this.roomId = data.roomId;
        this.propertyId = data.propertyId;
        this.guest_email = data.guest_email;
        this.guest_name = data.guest_name;
        this.phone_number = data.phone_number;
        this.arrival = data.arrival;
        this.departure = data.departure;
        this.unitId = data.unitId;
        this.roomQty = data.roomQty;
        this.numAdult = data.numAdult;
        this.numChild = data.numChild;
        this.apiReference = data.apiReference;
        this.bookingTime = data.bookingTime;
        this.price = data.price;
        this.url = data.url;
        this.media_type = data.media_type;
        this.lastMessageTime = data.lastMessageTime;
        this.lastMessage = data.lastMessage;
        this.lastMessageSource = data.lastMessageSource;
        this.hotel_name = data.hotel_name;
        this.hotel_address = data.hotel_address;
        this.hotel_phone = data.hotel_phone;
        this.room_name = data.room_name;
        this.status = data.status;
        this.referer = data.referer;
        this.beds24_url = data.beds24_url;
        this.airbnb_id = data.airbnb_id;
        this.admin_comment = data.admin_comment;
        this.markedAsRead = data.markedAsRead;
        this.unlisted = data.unlisted;
        this.preApproved = data.preApproved;
        this.wantToChange = data.wantToChange;
        this.specialStatusText = data.specialStatusText;
        this.specialStatusUrl = data.specialStatusUrl;
        this.specialStatusButtonText = data.specialStatusButtonText;
        this.listingId = data.listingId;
        this.specialStatusDone = data.specialStatusDone;
    }
}

export class Message {
    id: number;
    time: Date;
    read: boolean;
    message: string;
    source: string;
    guest_email: string;
    guest_name: string;
    bookingId: number;
    imageUrl: string;

    constructor(data: Message) {
        this.id = data.id;
        this.time = data.time;
        this.read = data.read;
        this.message = data.message;
        this.source = data.source;
        this.guest_email = data.guest_email;
        this.guest_name = data.guest_name;
        this.bookingId = data.bookingId;
        this.imageUrl = data.imageUrl;
    }
}

export class MessageTemplate {
    id: number;
    templateName: string;
    templateText: string;

    constructor(data: MessageTemplate) {
        this.id = data.id;
        this.templateName = data.templateName;
        this.templateText = data.templateText;
    }
}

export class BlockedConversation {
    bookingId: number;
    blockedBy: string;

    constructor(data: BlockedConversation) {
        this.bookingId = data.bookingId;
        this.blockedBy = data.blockedBy;
    }
}

export class BackendEvent {
    eventType: EventType;
    data: any;

    constructor(data: BackendEvent) {
        this.eventType = data.eventType;
        this.data = data.data;
    }
}

export class UserRoleSelectedOrAvailable {
    available: UserRole[];
    selected: UserRole[];

    constructor(data: UserRoleSelectedOrAvailable) {
        this.available = data.available;
        this.selected = data.selected;
    }
}

export class UserHotelsSelectedOrAvailable {
    available: Hotel[];
    selected: Hotel[];

    constructor(data: UserHotelsSelectedOrAvailable) {
        this.available = data.available;
        this.selected = data.selected;
    }
}

export class UpdateRolesDTO {
    userId?: number;
    newRoles?: UserRole[];

    constructor(data: UpdateRolesDTO) {
        this.userId = data.userId;
        this.newRoles = data.newRoles;
    }
}

export class NewHotelDTO {
    hotelName?: string;
    hotelProfile?: string;
    propertyId?: string;
    category?: string;
    taxPercent?: number;
    airbnbCom?: number;
    rocketstayShare?: number;

    constructor(data: NewHotelDTO) {
        this.hotelName = data.hotelName;
        this.hotelProfile = data.hotelProfile;
        this.propertyId = data.propertyId;
        this.category = data.category;
        this.taxPercent = data.taxPercent;
        this.airbnbCom = data.airbnbCom;
        this.rocketstayShare = data.rocketstayShare;
    }
}

export class Hotel {
    id: number;
    name: string;
    profile: string;
    propertyId: string;
    users: AppUser[];
    category: string;
    taxPercent: number;
    airbnbCom: number;
    rocketstayShare: number;

    constructor(data: Hotel) {
        this.id = data.id;
        this.name = data.name;
        this.profile = data.profile;
        this.propertyId = data.propertyId;
        this.users = data.users;
        this.category = data.category;
        this.taxPercent = data.taxPercent;
        this.airbnbCom = data.airbnbCom;
        this.rocketstayShare = data.rocketstayShare;
    }
}

export class Transaction {
    hotel_profile?: string[];
    transaction_history?: string[];
    from_date?: string;
    to_date?: string;
    email?: string[];

    constructor(data: Transaction) {
        this.hotel_profile = data.hotel_profile;
        this.transaction_history = data.transaction_history;
        this.from_date = data.from_date;
        this.to_date = data.to_date;
        this.email = data.email;
    }
}

export class TransactionLog {
    id: number;
    serializedTransaction: string;
    username: string;
    generationDate: Date;

    constructor(data: TransactionLog) {
        this.id = data.id;
        this.serializedTransaction = data.serializedTransaction;
        this.username = data.username;
        this.generationDate = data.generationDate;
    }
}

export class ActionLog {
    id: number;
    action?: ActionLogType;
    username: string;
    successful: boolean;
    error: string;
    date: Date;
    conversationId: number;

    constructor(data: ActionLog) {
        this.id = data.id;
        this.action = data.action;
        this.username = data.username;
        this.successful = data.successful;
        this.error = data.error;
        this.date = data.date;
        this.conversationId = data.conversationId;
    }
}

export class DropDown {
    bookId: number;
    before: string;
    after: string;
    inserted: Date;

    constructor(data: DropDown) {
        this.bookId = data.bookId;
        this.before = data.before;
        this.after = data.after;
        this.inserted = data.inserted;
    }
}

export class InternalLog {
    bookId: string;
    property: string;
    unit: string;
    room: string;
    logs: string;

    constructor(data: InternalLog) {
        this.bookId = data.bookId;
        this.property = data.property;
        this.unit = data.unit;
        this.room = data.room;
        this.logs = data.logs;
    }
}

export class InternalNote {
    bookId: string;
    property: string;
    unit: string;
    room: string;
    notes: string;

    constructor(data: InternalNote) {
        this.bookId = data.bookId;
        this.property = data.property;
        this.unit = data.unit;
        this.room = data.room;
        this.notes = data.notes;
    }
}

export class AvailableAndSelectedHotelsB24Info {
    available: HotelB24Info[];
    selected: HotelB24Info[];

    constructor(data: AvailableAndSelectedHotelsB24Info) {
        this.available = data.available;
        this.selected = data.selected;
    }
}

export class HotelSpreadsheet {
    id: number;
    name: string;
    propertyId: string;
    category: string;
    taxPercent: number;
    airbnbCom: number;
    rocketstayShare: number;
    pms: string;
    accessKey: string;
    rooms: RoomSpreadsheet[];
    currency: string;

    constructor(data: HotelSpreadsheet) {
        this.id = data.id;
        this.name = data.name;
        this.propertyId = data.propertyId;
        this.category = data.category;
        this.taxPercent = data.taxPercent;
        this.airbnbCom = data.airbnbCom;
        this.rocketstayShare = data.rocketstayShare;
        this.pms = data.pms;
        this.accessKey = data.accessKey;
        this.rooms = data.rooms;
        this.currency = data.currency;
    }
}

export class RoomSpreadsheet {
    id: number;
    name: string;
    hotelSpreadsheet: HotelSpreadsheet;

    constructor(data: RoomSpreadsheet) {
        this.id = data.id;
        this.name = data.name;
        this.hotelSpreadsheet = data.hotelSpreadsheet;
    }
}

export class SiteminderHistory {
    id: string;
    typeBooking: string;
    property: string;
    bookingId: string;
    receivedTimestamp: Date;
    completed?: boolean;

    constructor(data: SiteminderHistory) {
        this.id = data.id;
        this.typeBooking = data.typeBooking;
        this.property = data.property;
        this.bookingId = data.bookingId;
        this.receivedTimestamp = data.receivedTimestamp;
        this.completed = data.completed;
    }
}

export class SiteminderReceiver {
    id: number;
    property: string;
    roomId: string;
    receivedTimestamp: Date;
    messageIn: string;
    messageOut: string;
    bookingId: string;
    typeBooking: string;

    constructor(data: SiteminderReceiver) {
        this.id = data.id;
        this.property = data.property;
        this.roomId = data.roomId;
        this.receivedTimestamp = data.receivedTimestamp;
        this.messageIn = data.messageIn;
        this.messageOut = data.messageOut;
        this.bookingId = data.bookingId;
        this.typeBooking = data.typeBooking;
    }
}

export class ReviewHotel {
    id: string;
    bookingId: string;
    checkOut: string;
    processedTimestamp: Date;
    processed: boolean;
    reason: string;

    constructor(data: ReviewHotel) {
        this.id = data.id;
        this.bookingId = data.bookingId;
        this.checkOut = data.checkOut;
        this.processedTimestamp = data.processedTimestamp;
        this.processed = data.processed;
        this.reason = data.reason;
    }
}

export class ReviewHotelPublic {
    id: string;
    bookingId: string;
    checkOut: string;
    messages: string;
    processedTimestamp: Date;
    processed: boolean;
    reason: string;
    hotel: string;
    responseRate: boolean;
    resolved: boolean;

    constructor(data: ReviewHotelPublic) {
        this.id = data.id;
        this.bookingId = data.bookingId;
        this.checkOut = data.checkOut;
        this.messages = data.messages;
        this.processedTimestamp = data.processedTimestamp;
        this.processed = data.processed;
        this.reason = data.reason;
        this.hotel = data.hotel;
        this.responseRate = data.responseRate;
        this.resolved = data.resolved;
    }
}

export class UploadFileResponse {
    csv_files: string[];
    message: string;

    constructor(data: UploadFileResponse) {
        this.csv_files = data.csv_files;
        this.message = data.message;
    }
}

export class CancellationBooking {
    id: string;
    bookingId: string;
    airbnbId: string;
    processedTimestamp: Date;
    processed: boolean;
    reason: string;
    checkOut: string;

    constructor(data: CancellationBooking) {
        this.id = data.id;
        this.bookingId = data.bookingId;
        this.airbnbId = data.airbnbId;
        this.processedTimestamp = data.processedTimestamp;
        this.processed = data.processed;
        this.reason = data.reason;
        this.checkOut = data.checkOut;
    }
}

export class SiteminderReceiverHistory {
    uniqueToken: string;
    propertyId: string;
    roomId: string;
    receivedTimestamp: Date;
    messageIn: string;
    messageOut: string;
    success: string;
    requestType: string;
    b24Response: string;
    b24Send: string;

    constructor(data: SiteminderReceiverHistory) {
        this.uniqueToken = data.uniqueToken;
        this.propertyId = data.propertyId;
        this.roomId = data.roomId;
        this.receivedTimestamp = data.receivedTimestamp;
        this.messageIn = data.messageIn;
        this.messageOut = data.messageOut;
        this.success = data.success;
        this.requestType = data.requestType;
        this.b24Response = data.b24Response;
        this.b24Send = data.b24Send;
    }
}

export class ModifyResponse {
    success: boolean;
    warnings: WarningResponse[];
    errors: ErrorResponse[];

    constructor(data: ModifyResponse) {
        this.success = data.success;
        this.warnings = data.warnings;
        this.errors = data.errors;
    }
}

export class ModifyErrorResponse {
    code: number;
    error: string;
    message: string;
    success: boolean;
    type: string;

    constructor(data: ModifyErrorResponse) {
        this.code = data.code;
        this.error = data.error;
        this.message = data.message;
        this.success = data.success;
        this.type = data.type;
    }
}

export class Booking {
    apiReference: string;
    arrival: string;
    arrivalTime: string;
    bookingTime: Date;
    cancelTime: Date;
    comments: string;
    commission: number;
    departure: string;
    deposit: number;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    modifiedTime: Date;
    numAdult: number;
    numChild: number;
    phone: string;
    price: number;
    propertyId: number;
    rateDescription: string;
    referer: string;
    roomId: string;
    roomQty: number;
    status: string;
    tax: number;

    constructor(data: Booking) {
        this.apiReference = data.apiReference;
        this.arrival = data.arrival;
        this.arrivalTime = data.arrivalTime;
        this.bookingTime = data.bookingTime;
        this.cancelTime = data.cancelTime;
        this.comments = data.comments;
        this.commission = data.commission;
        this.departure = data.departure;
        this.deposit = data.deposit;
        this.email = data.email;
        this.firstName = data.firstName;
        this.id = data.id;
        this.lastName = data.lastName;
        this.modifiedTime = data.modifiedTime;
        this.numAdult = data.numAdult;
        this.numChild = data.numChild;
        this.phone = data.phone;
        this.price = data.price;
        this.propertyId = data.propertyId;
        this.rateDescription = data.rateDescription;
        this.referer = data.referer;
        this.roomId = data.roomId;
        this.roomQty = data.roomQty;
        this.status = data.status;
        this.tax = data.tax;
    }
}

export class ModifyCalendarLog {
    id: number;
    username: string;
    date: Date;
    requestData: string;
    response: string;
    error: string;
    prettyRequestData: string;

    constructor(data: ModifyCalendarLog) {
        this.id = data.id;
        this.username = data.username;
        this.date = data.date;
        this.requestData = data.requestData;
        this.response = data.response;
        this.error = data.error;
        this.prettyRequestData = data.prettyRequestData;
    }
}

export class PrettyHotelChangeRequest {
    property: number;
    propertyName: string;
    changes: { [index: string]: PrettyChange[] };

    constructor(data: PrettyHotelChangeRequest) {
        this.property = data.property;
        this.propertyName = data.propertyName;
        this.changes = data.changes;
    }
}

export class DerbysoftHistory {
    id: string;
    typeBooking: string;
    property: string;
    bookingId: string;
    receivedTimestamp: Date;
    completed?: boolean;
    derbyResId: string;
    supplierResId: string;

    constructor(data: DerbysoftHistory) {
        this.id = data.id;
        this.typeBooking = data.typeBooking;
        this.property = data.property;
        this.bookingId = data.bookingId;
        this.receivedTimestamp = data.receivedTimestamp;
        this.completed = data.completed;
        this.derbyResId = data.derbyResId;
        this.supplierResId = data.supplierResId;
    }
}

export class DerbysoftReceiver {
    id: number;
    property: string;
    roomId: string;
    receivedTimestamp: Date;
    messageIn: string;
    messageOut: string;
    bookingId: string;
    typeBooking: string;

    constructor(data: DerbysoftReceiver) {
        this.id = data.id;
        this.property = data.property;
        this.roomId = data.roomId;
        this.receivedTimestamp = data.receivedTimestamp;
        this.messageIn = data.messageIn;
        this.messageOut = data.messageOut;
        this.bookingId = data.bookingId;
        this.typeBooking = data.typeBooking;
    }
}

export class DerbysoftReceiverHistory {
    uniqueToken: string;
    propertyId: string;
    roomId: string;
    receivedTimestamp: Date;
    messageIn: string;
    messageOut: string;
    success: string;
    requestType: string;
    b24Response: string;
    b24Send: string;

    constructor(data: DerbysoftReceiverHistory) {
        this.uniqueToken = data.uniqueToken;
        this.propertyId = data.propertyId;
        this.roomId = data.roomId;
        this.receivedTimestamp = data.receivedTimestamp;
        this.messageIn = data.messageIn;
        this.messageOut = data.messageOut;
        this.success = data.success;
        this.requestType = data.requestType;
        this.b24Response = data.b24Response;
        this.b24Send = data.b24Send;
    }
}

export class DerbysoftHotelSpreadsheet {
    id: number;
    name: string;
    propertyId: string;
    category: string;
    taxPercent: number;
    airbnbCom: number;
    rocketstayShare: number;
    pms: string;
    accessKey: string;
    currency: string;
    active: boolean;
    supplierId: string;
    derbysoftHotelId: string;
    ariType: string;
    rateType: string;

    constructor(data: DerbysoftHotelSpreadsheet) {
        this.id = data.id;
        this.name = data.name;
        this.propertyId = data.propertyId;
        this.category = data.category;
        this.taxPercent = data.taxPercent;
        this.airbnbCom = data.airbnbCom;
        this.rocketstayShare = data.rocketstayShare;
        this.pms = data.pms;
        this.accessKey = data.accessKey;
        this.currency = data.currency;
        this.active = data.active;
        this.supplierId = data.supplierId;
        this.derbysoftHotelId = data.derbysoftHotelId;
        this.ariType = data.ariType;
        this.rateType = data.rateType;
    }
}

export class DerbysoftRoomSpreadsheet {
    id: string;
    hotelId: string;
    rateId: string;
    status: boolean;
    roomName: string;
    maxAdults: number;
    maxChildren: number;
    beds24RoomId: string;
    roomIdDerbysoft: string;

    constructor(data: DerbysoftRoomSpreadsheet) {
        this.id = data.id;
        this.hotelId = data.hotelId;
        this.rateId = data.rateId;
        this.status = data.status;
        this.roomName = data.roomName;
        this.maxAdults = data.maxAdults;
        this.maxChildren = data.maxChildren;
        this.beds24RoomId = data.beds24RoomId;
        this.roomIdDerbysoft = data.roomIdDerbysoft;
    }
}

export class UserRole {
    id?: number;
    role?: Role;
    users: AppUser[];

    constructor(data: UserRole) {
        this.id = data.id;
        this.role = data.role;
        this.users = data.users;
    }
}

export class HotelWithoutUsers {
    id: number;
    name: string;
    profile: string;

    constructor(data: HotelWithoutUsers) {
        this.id = data.id;
        this.name = data.name;
        this.profile = data.profile;
    }
}

export class HotelB24Info {
    id: number;
    hotelName: string;
    hotelRooms: string;

    constructor(data: HotelB24Info) {
        this.id = data.id;
        this.hotelName = data.hotelName;
        this.hotelRooms = data.hotelRooms;
    }
}

export class WarningResponse {
    action: string;
    message: string;

    constructor(data: WarningResponse) {
        this.action = data.action;
        this.message = data.message;
    }
}

export class ErrorResponse {
    action: string;
    message: string;

    constructor(data: ErrorResponse) {
        this.action = data.action;
        this.message = data.message;
    }
}

export class PrettyChange {
    whatChanged?: string;
    date?: string;
    value?: string;

    constructor(data: PrettyChange) {
        this.whatChanged = data.whatChanged;
        this.date = data.date;
        this.value = data.value;
    }
}

export enum Role {
    ADMIN = "ADMIN",
    CHAT = "CHAT",
    TRANSACTION_DEFAULT = "TRANSACTION_DEFAULT",
    TRANSACTION_GLOBAL = "TRANSACTION_GLOBAL",
    SUPERVISOR = "SUPERVISOR",
    BEDS24_INFO = "BEDS24_INFO",
    SITEMINDER = "SITEMINDER",
    CANCELLATION = "CANCELLATION",
    REVIEW = "REVIEW",
    CLOUDBEDS_RESTRICTED = "CLOUDBEDS_RESTRICTED",
    CLOUDBEDS_MASTER = "CLOUDBEDS_MASTER",
    CLOUDBEDS_LOGS = "CLOUDBEDS_LOGS",
}

export enum EventType {
    RELOAD_BLOCKED_CONVERSATIONS = "RELOAD_BLOCKED_CONVERSATIONS",
    RELOAD_CONVERSATIONS = "RELOAD_CONVERSATIONS",
    UPDATE_CONVERSATION = "UPDATE_CONVERSATION",
    NEW_MESSAGE = "NEW_MESSAGE",
}

export enum ActionLogType {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    BLOCK = "BLOCK",
    UNBLOCK = "UNBLOCK",
}
