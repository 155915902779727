import {Subject} from "rxjs";
import {useEffect, useState} from "react";
import {BackendError, ReturnedError} from "../data/BackendClasses";


export default function ErrorModal({error, hide, show = true, title = "Error"}:{error: any, show?: boolean, hide: () => void, title?: string}) {
    return <div className="modal modal-danger" style={{display:(show ? 'block' : 'none')}}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <i className="fa fa-exclamation-triangle"> {title}</i>
                </div>
                <div className="modal-body">
                    <ErrorInner error={error}/>
                </div>
                <div className="modal-footer">
                    <button type={"button"} className="btn btn-primary pull-right" onClick={hide}>Close</button>
                </div>
            </div>
        </div>

    </div>
}

export type ERR = any;
type ERR_WRAP = {id: string, err: ERR};
const errorsSubject = new Subject<ERR_WRAP>();

export function pushErrorModal(error: ERR){
    errorsSubject.next({id: Math.random() + '', err: error});
}

function useErrorModal() {
    const [errors, setErrors] = useState<ERR_WRAP[]>([]);

    useEffect(() => {
        const subscriptions = errorsSubject.subscribe(newErr => {
            setErrors(errors => [...errors, newErr])
        });

        return () => subscriptions.unsubscribe()
    }, []);

    function removeError(id: string){
        setErrors(errors => errors.filter(e => e.id !== id));
    }

    return <div id="modals">
        {errors.map(err => <ErrorModal error={err.err} key={err.id} show={true} hide={() => removeError(err.id)}/>)}
    </div>
}

export function ErrorModals() {
    const modals = useErrorModal();
    return <>{modals}</>
}

export function ErrorInner({error}: {error: any}){
    let errorElement = <div>error</div>
    if(error instanceof TypeError && error.message == "Failed to fetch"){
        error = "Network Error";
    }
    if(isReturnedErrors(error)){
        errorElement = <div>{error.errors.map(e => <div key={Math.random()}>{e.message}</div>)}</div>
    }else if (isReturnedSingleError(error) || isReturnedSingleErrorWithoutType(error)){
        errorElement = <div>{error.message}</div>
    }else if (typeof error === 'string'){
        errorElement = <div>{error}</div>
    }else if ('toString' in error){
        errorElement = <div>{error.toString()}</div>
    }

    return errorElement;
}

export function isReturnedErrors(e: any): e is ReturnedError {
    return (e as ReturnedError).errors !== undefined;
}

export function isReturnedSingleError(e: any): e is Error {
    return (e as BackendError).type != undefined && (e as BackendError).message !== undefined;
}

export function isReturnedSingleErrorWithoutType(e: any): e is {message: string}{
    return typeof e === 'object' && 'message' in e && e.message != undefined;
}