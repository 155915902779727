import {postJsonThenJsonToBackend} from "../../data/network";
import {useFormState} from "../../data/Form";
import {useContext, useState} from "react";
import {isDev} from "../../lib/helpers";
import CommandError from "../../components/CommandError";
import {GlobalContext} from "../../data/GlobalContext";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const initialState = {username: '', password: ''}
const initialStateReset = {username: ''};
const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

export default function LoginWithUserAndPasswordComponent() {

    const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

    return <>
        {!showResetPassword &&
            <LoginWithUserAndPasswordForm setShowResetPassword={setShowResetPassword}/>
        }

        {showResetPassword && <ResetPassword
            setShowResetPassword={setShowResetPassword}
            afterSubmit={() => setShowResetPassword(showResetPassword => !showResetPassword)}
        />}

    </>
}

function LoginWithUserAndPasswordForm({setShowResetPassword}:{setShowResetPassword: (newState: boolean) => void}) {

    const {inputValues, handleInputChange, resetInputValues} = useFormState(initialState);
    const[capsLock, setCapsLock] = useState<boolean>(false)
    const [submitErrors, setSubmitErrors] = useState<any>(null);

    const [recaptchaToken, setRecaptchaToken] = useState<string|null>(null);

    const handleCaptchaVerify = (recaptchaToken: string) => {
        setRecaptchaToken(recaptchaToken);
    };

    function checkCapsLock(keyEvent: any){
        setCapsLock(keyEvent.getModifierState("CapsLock"));
    }

    async function onSubmitLogin(e: any){
        e.preventDefault();
        setSubmitErrors(null);

        await postJsonThenJsonToBackend("/auth/login", {username: inputValues.username, password: inputValues.password})
            .then(res => {
                console.log("RES LOGIN ", res);
                window.location.reload()
            })
            .catch(err => {
                console.log("ERR LOGIN ", err);
                setSubmitErrors(err);
                resetInputValues(initialState);
            });
    }

    return <>
        <div>

            <form className="space-y-6" onSubmit={onSubmitLogin}>

                {/*<GoogleReCaptcha onVerify={handleCaptchaVerify} />*/}

                <h5 className="text-xl font-medium text-blue-500">Login to your account {capsLock ? <span className="ml-5 text-amber-300"><i className="fa fa-exclamation-triangle"/> CapsLock on</span> : ""}</h5>
                <div>
                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-blue-500">Username</label>
                    <input type="text"
                           name="username"
                           id="username"
                           className="focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                           required
                           value={inputValues.username}
                           onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-blue-500">Your password</label>
                    <input type={isDev() ? "text" : "password"}
                           name="password"
                           id="password"
                           placeholder="••••••••"
                           className="focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                           required
                           value={inputValues.password}
                           onChange={handleInputChange}
                           onKeyDown={checkCapsLock}
                    />
                </div>
                <button
                    type="submit"
                    className="w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500">
                    Login to your account
                </button>

                <div className="w-full text-center">
                        <span
                            className="text-xs text-blue-500 hover:cursor-pointer"
                            onClick={() => setShowResetPassword(true)}
                        >
                            Reset password
                        </span>
                </div>

            </form>
            {submitErrors && <div className="flex justify-center mt-5 text-red-700 font-medium">
                <CommandError error={submitErrors}/>
            </div>}
        </div>
    </>
}

function ResetPassword({setShowResetPassword, afterSubmit}:{setShowResetPassword: (newState: boolean) => void, afterSubmit: () => void}) {
    const {inputValues, handleInputChange, resetInputValues} = useFormState(initialStateReset);
    const[capsLock, setCapsLock] = useState<boolean>(false);
    const [submitErrors, setSubmitErrors] = useState<any>(null);
    const {showToastMessage} = useContext(GlobalContext);

    async function onSubmitReset(e: any){

        console.log("SEND RESET CALL")

        e.preventDefault();
        setSubmitErrors(null);

        await postJsonThenJsonToBackend("/users/reset-password", inputValues.username)
            .then(res => {
                console.log("RES RESET ", res);
                showToastMessage("Success", "The new password was sent on the email", "success");
                resetInputValues(initialStateReset);
            })
            .catch(err => {
                console.log("ERR RESET ", err);
                setSubmitErrors(err);
                resetInputValues(initialStateReset);
            });

        // resetInputValues(initialStateReset);
        // afterSubmit();
    }

    return <div>
        <form className="space-y-6" onSubmit={onSubmitReset}>
            <h5 className="text-xl font-medium text-blue-500">Reset Password {capsLock ? <span className="ml-5 text-amber-300"><i className="fa fa-exclamation-triangle"/> CapsLock on</span> : ""}</h5>
            <div>
                <label htmlFor="username" className="block mb-2 text-sm font-medium text-blue-500">Username</label>
                <input type="text"
                       name="username"
                       id="username"
                       className="focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5 bg-white"
                       required
                       value={inputValues.username}
                       onChange={handleInputChange}
                />
            </div>

            <button
                type="submit"
                className="w-full focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-blue-500 border border-blue-500 hover:text-white hover:bg-blue-500">
                Reset
            </button>

            <div className="w-full text-center">
                    <span
                        className="text-xs text-blue-500 hover:cursor-pointer"
                        onClick={() => setShowResetPassword(false)}
                    >
                        Back to login
                    </span>
            </div>

        </form>
        {submitErrors && <div className="flex justify-center mt-5 text-red-700 font-medium">
            <CommandError error={submitErrors}/>
        </div>}
    </div>
}