import {BlockedConversation, Conversation} from "../../data/BackendClasses";
import {useCurrentUser} from "../../data/user";
import {postJsonToBackend} from "../../data/network";
import {Tooltip} from "primereact/tooltip";
import Markdown from 'react-markdown';


export default function ConversationCallout(
    {
        conversation,
        isActive,
        setCurrentConversation,
        blockedConversations,
        addRecentConversation
    } : {
        conversation: Conversation,
        isActive: boolean,
        setCurrentConversation: (item: Conversation) => void,
        blockedConversations: BlockedConversation[],
        addRecentConversation: () => void
    }
){

    const currentUser = useCurrentUser();
    const blockedConversation = blockedConversations.find(item => item.bookingId == conversation.bookingId);

    function selectConversation(e: any){
        e.preventDefault();
        addRecentConversation();
        setCurrentConversation(conversation);
    }

    function blockConversation(e: any){
        e.preventDefault();
        postJsonToBackend("/chat/conversations/block", {bookingId: conversation.bookingId, blockedBy: currentUser.username})
    }

    function unblockConversation(e: any){
        e.preventDefault();
        postJsonToBackend("/chat/conversations/unblock", {bookingId: conversation.bookingId, blockedBy: currentUser.username})
    }

    function conversationStatusClass(status: string): string {
        if(status === "cancelled"){
            return "bg-red-500";
        }

        if(status === "new"){
            return "bg-yellow-500";
        }

        if(status === "confirmed"){
            return "bg-green-500";
        }

        return "bg-blue-500";
    }

    function markConversationAsRead(){
        console.log("Mark conversation as read ", conversation);
        postJsonToBackend("/chat/conversations/mark-as-read", conversation.bookingId);
    }

    return <div className={"rounded shadow-md px-4 py-2 flex flex-col mb-3 " + (isActive ? "border-l-4 border-green-500" : "") + ((conversation.lastMessageSource == "guest" && !conversation.markedAsRead) ? " bg-white/70 font-bold" : " bg-[#efefef]")} onClick={selectConversation}>
        <div className="w-full flex-col">
            <div className="flex flex">
                <div className="flex-col w-3/4">
                    <div className={`truncate guest-name-target-${conversation.bookingId} cursor-pointer m-w-fit`}>
                        <i className="fa-solid fa-user mb-1 mr-1"/>
                        {conversation.guest_name}
                        <Tooltip target={`.guest-name-target-${conversation.bookingId}`}>
                            <span>{conversation.guest_name}</span>
                        </Tooltip>
                    </div>
                    <div className={`truncate hotel-name-target-${conversation.bookingId} cursor-pointer m-w-fit`}>
                        <i className="fa-solid fa-hotel mr-1"/>
                        {conversation.hotel_name}
                        <Tooltip target={`.hotel-name-target-${conversation.bookingId}`}>
                            <span>{conversation.hotel_name}</span>
                        </Tooltip>
                    </div>
                    <div>
                        <i className="fa-solid fa-globe mr-1"/>
                        {conversation.referer}
                    </div>
                </div>
                <div className="w-1/4 flex flex-col">
                    <div className="text-right">
                        {conversation.status && <span className={"text-white text-xs rounded p-1 " + conversationStatusClass(conversation.status)}>{conversation.status}</span>}
                    </div>
                    <div className="text-right mt-1">
                        {conversation.preApproved && !conversation.specialStatusDone && <span className="text-xs rounded p-1 bg-orange-500">pre approve</span>}
                        {conversation.wantToChange && !conversation.specialStatusDone && <span className="text-xs rounded p-1 bg-orange-500">change</span>}
                        {conversation.unlisted && !conversation.specialStatusDone && <span className="text-xs rounded p-1 bg-orange-500">unlisted</span>}
                    </div>
                </div>

            </div>

            <div className={`truncate hotel-last-message-target-${conversation.bookingId} cursor-pointer m-w-fit`}>
                <span>{conversation.lastMessageSource}</span> : <span>{conversation.lastMessage}</span>
                <Tooltip target={`.hotel-last-message-target-${conversation.bookingId}`}>
                    <Markdown>{conversation.lastMessage}</Markdown>
                </Tooltip>
            </div>

            <div className="mt-2">
                {!blockedConversation && <button onClick={blockConversation} className="text-xs text-blue-500 border border-blue-500 rounded p-1 hover:text-white hover:bg-blue-500">block</button>}
                {blockedConversation && (currentUser.username == blockedConversation.blockedBy) && <button onClick={unblockConversation} className="text-xs text-white p-1 bg-blue-500 border border-blue-500 rounded hover:text-white hover:bg-blue-600 hover:border-blue-600">unblock</button>}
                {blockedConversation && (currentUser.username != blockedConversation.blockedBy) && <button title={"blocked by " + blockedConversation.blockedBy} disabled={true} className="text-xs text-white bg-blue-500 border border-blue-500 rounded p-1">blocked</button>}

                {!conversation.markedAsRead && <button onClick={markConversationAsRead} className="text-xs text-blue-500 border border-blue-500 rounded p-1 ml-2 hover:text-white hover:bg-blue-500">Mark as read</button>}
                {conversation.markedAsRead && <button onClick={markConversationAsRead} className="text-xs text-white bg-blue-500 border border-blue-500 rounded p-1 ml-2" disabled={true}>Marked as read</button>}
            </div>
        </div>
    </div>
}