import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useState} from "react";
import {DerbysoftHotelSpreadsheet} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {FilterMatchMode} from "primereact/api";
import EditDerbysoftHotelSpreadsheetDialog from "./EditDerbysoftHotelSpreadsheetDialog";
import DerbysoftRoomsDialog from "./DerbysoftRoomsDialog";
import {Tooltip} from "primereact/tooltip";


export default function DerbysoftHotelsSpreadsheetPage() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filters, setFilters] = useState<DataTableFilterMeta>({global: { value: null, matchMode: FilterMatchMode.CONTAINS}});
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const [data, setData] = useState<DerbysoftHotelSpreadsheet[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const [reload, setReload] = useState<boolean>(false);

    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const [selectedHotel, setSelectedHotel] = useState<DerbysoftHotelSpreadsheet | undefined>(undefined);

    const [showRoomsDialog, setShowRoomsDialog] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);


    useEffect(() => {
        getJsonFromBackend("/derbysoft-hotels-spreadsheet/get")
            .then((rsp: DerbysoftHotelSpreadsheet[]) => {
                setData(rsp);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting hotels data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload])


    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-content-end">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </div>
        );
    };

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    function renderRooms(item: DerbysoftHotelSpreadsheet){
        return <div
            className={`truncate guest-name-target-${item.id} cursor-pointer m-w-fit`}
        >
            <i
                className="fa-solid fa-door-closed hover:text-yellow-500"
                onClick={() => {
                    setSelectedHotel(prevState => item);
                    setShowRoomsDialog(prevState => true);
                }}
            />
        </div>
    }

    function renderRocketstayShare(item: DerbysoftHotelSpreadsheet) {
        return <>{item.rocketstayShare}%</>
    }

    function renderAirbnbCom(item: DerbysoftHotelSpreadsheet) {
        return <>{item.airbnbCom}%</>
    }

    function renderTaxPercent(item: DerbysoftHotelSpreadsheet) {
        return <>{item.taxPercent}%</>
    }

    function renderActions(item: DerbysoftHotelSpreadsheet){
        return <>
            <i
                className="fa-solid fa-pen-to-square text-green-500 hover:cursor-pointer hover:text-green-600"
                onClick={() => {
                    setSelectedHotel(item);
                    setShowEditDialog(true);
                }}
            />

            <i
                className="fa-solid fa-trash text-red-500 hover:cursor-pointer hover:text-red-600 ml-2"
                onClick={() => removeHotel(item)}
            />

        </>
    }

    async function removeHotel(hotel: DerbysoftHotelSpreadsheet) {
        if(!window.confirm(`Are you sure you want to remove ${hotel.name}?`)){
            return;
        }
        try {
            await postJsonToBackend("/derbysoft-hotels-spreadsheet/remove", hotel.id);
            showToastMessage("Success", "Hotel removed", "success");
            setReload(!reload);
        } catch (err){
            showToastMessage("Error", "Something bad happened", "error");
        }

    }

    function renderActive(hotel: DerbysoftHotelSpreadsheet){
        return hotel.active ? <i className="fa-solid fa-check"/> : <i className="fa-solid fa-xmark"/>
    }

    function renderHotelName(hotel: DerbysoftHotelSpreadsheet){
        return <>
            <div
                className="line-clamp-1 roomNameTarget hover:cursor-pointer"
                data-pr-tooltip={`${hotel.name}`}
                data-pr-position="right"
                data-pr-at="right center"
                data-pr-my="left center-2"
            >
                {hotel.name}
            </div>
            <Tooltip target=".roomNameTarget" />
        </>
    }


    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="derbysoft-hotel-spreadsheet-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ?
                <DataTable
                    value={data}
                    first={first}
                    filters= {filters}
                    rows={pageSize}
                    paginator
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    stripedRows
                    emptyMessage="No data found."
                    tableStyle={{minWidth: '50rem'}}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    header= {renderHeader()}
                    pt={{
                        header: {className: "!bg-white !border-0"},
                    }}
                >
                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="id"*/}
                    {/*    header="Hotel ID"*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Column
                        className="!bg-white "
                        field="name"
                        body={renderHotelName}
                        header="Hotel"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="propertyId"
                        header="Property ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="category"
                        header="Category"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="taxPercent"
                        header="Tax/VAT Percent"
                        body={renderTaxPercent}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="airbnbCom"
                        header="Airbnb Com"
                        body={renderAirbnbCom}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="rocketstayShare"
                        header="Rocketstay Share"
                        body={renderRocketstayShare}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="pms"
                        header="PMS"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="accessKey"*/}
                    {/*    header="Access Key"*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Column
                        className="!bg-white "
                        header="Rooms"
                        body={renderRooms}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="currency"
                        header="Currency"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="active"
                        header="Active"
                        body={renderActive}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="supplierId"
                        header="Supplier Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="derbysoftHotelId"
                        header="Derbysoft Hotel Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="ariType"*/}
                    {/*    header="Ari Type"*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*<Column*/}
                    {/*    className="!bg-white "*/}
                    {/*    field="rateType"*/}
                    {/*    header="Rate Type"*/}
                    {/*    pt={{*/}
                    {/*        headerTitle: {className: "!font-bold"}*/}
                    {/*    }}*/}
                    {/*/>*/}

                    <Column
                        className="!bg-white "
                        header="Actions"
                        body={renderActions}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                </DataTable> : <></>
            }
        </div>

        {selectedHotel && <EditDerbysoftHotelSpreadsheetDialog
            showEditDialog={showEditDialog}
            selectedHotel={selectedHotel}
            onClose={() => {
                setSelectedHotel(undefined);
                setShowEditDialog(false);
            }}
            triggerReload={() => setReload(!reload)}
        />}

        {selectedHotel && <DerbysoftRoomsDialog
            selectedHotel={selectedHotel}
            onClose={() => {
                setSelectedHotel(prevState => undefined);
                setShowRoomsDialog(prevState => false);
            }}
            showRoomsDialog={showRoomsDialog}
        />}

    </div>
}