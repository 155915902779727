import {PickList, PickListChangeEvent} from "primereact/picklist";
import {Dialog} from "primereact/dialog";
import {UserRole, UserRoleSelectedOrAvailable} from "../../../data/BackendClasses";
import {useContext, useEffect, useState} from "react";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";


export default function ManagementUserRoles(
    {
        showUserRolesPickList,
        setShowUserRolesPickList,
        selectedUserId,
        onClose
    }:{
        showUserRolesPickList: boolean,
        setShowUserRolesPickList: (show: boolean) => void,
        selectedUserId: number,
        onClose: () => void
    }
) {

    const [availableRoles, setAvailableRoles] = useState<UserRole[]>([]);
    const [selectedRoles, setSelectedRoles] = useState<UserRole[]>([]);
    const [rolesChanged, setRolesChanged] = useState<boolean>(false);
    const {showToastMessage} = useContext(GlobalContext);

    useEffect(() => {
        getJsonFromBackend("/roles/available-and-selected?userId=" + selectedUserId)
            .then((rsp: UserRoleSelectedOrAvailable) => {
                setSelectedRoles(rsp.selected);
                setAvailableRoles(rsp.available);
            })
            .catch(err => {
                console.log("ERR getting user roles", err);

            })
    }, [])

    function onChange(event: PickListChangeEvent) {
        setRolesChanged(true);
        setAvailableRoles(event.source);
        setSelectedRoles(event.target);
    };

    async function saveRoles() {
        postJsonToBackend("/users/update-roles", {userId: selectedUserId, newRoles: selectedRoles})
            .then(rsp => {
                showToastMessage("Success", "Roles granted successfully", "success");
                onClose();
            })
            .catch(err => {
                showToastMessage("Error", "Error setting user roles", "error");
                console.log(err);
            });
    }

    const itemTemplate = (item: UserRole) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.role}</span>
                </div>
            </div>
        );
    };

    return <div id="management-user-roles">
        <Dialog
            header="Choose hotels"
            visible={showUserRolesPickList}
            style={{ width: '50vw' }}
            onHide={() => setShowUserRolesPickList(false)}
        >
            <div className="flex flex-col w-full">
                <PickList
                    source={availableRoles}
                    target={selectedRoles}
                    onChange={onChange}
                    itemTemplate={itemTemplate}
                    filter
                    filterBy="name,address"
                    breakpoint="1400px"
                    sourceHeader="Available"
                    targetHeader="Selected"
                    sourceStyle={{ height: '24rem' }}
                    targetStyle={{ height: '24rem' }}
                    sourceFilterPlaceholder="Search by name or address"
                    targetFilterPlaceholder="Search by name or address"
                    showSourceControls={false}
                    showTargetControls={false}
                />
                <div className="w-full mt-2 flex justify-center">
                    {rolesChanged && <button
                        onClick={saveRoles}
                        // className="border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 mt-5"
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                    >
                        Done
                    </button>}
                </div>
            </div>
        </Dialog>
    </div>

}