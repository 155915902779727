import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {SiteminderReceiver} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {createPaginationParams, createQueryString, getJsonFromBackend} from "../../../data/network";
import {compareObjects, formatDate} from "../../../lib/helpers";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Skeleton} from "primereact/skeleton";
import {Dialog} from "primereact/dialog";
import {useFormState} from "../../../data/Form";
import XMLViewer from "react-xml-viewer";


const initialState = {filterProperty: '', filterRoomId: '', filterMessageIn: '', filterMessageOut: '', filterBookingId: '', filterTypeBooking: ''};

export default function SiteminderReceiverPage() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const [data, setData] = useState<SiteminderReceiver[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<string>("");
    const [dialogHeader, setDialogHeader] = useState<string>("");

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [numberOfFilters, setNumberOfFilters] = useState<number>(0);
    const [filtersChanged, setFiltersChanged] = useState<boolean>(false);

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState);


    useEffect(() => {
        getJsonFromBackend("/siteminder-receiver/get" + createPaginationParams(page, pageSize) + createQueryString(inputValues))
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting Siteminder Receiver data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload, page, pageSize, filtersChanged])


    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    function applyFilters(e: any){
        e.preventDefault();
        setNumberOfFilters(compareObjects(inputValues, initialState));
        resetInputValues(inputValues);
        // setShowFilters((showFilters) => !showFilters);
        setFiltersChanged((filtersChanged) => !filtersChanged);
    }

    function resetFilters(e: any){
        e.preventDefault();
        setNumberOfFilters(0);
        resetInputValues(initialState);
        // setShowFilters((showFilters) => !showFilters);
        setFiltersChanged((filtersChanged) => !filtersChanged);
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col content-center justify-center">
                        <i
                            className={`fa-solid fa-arrow-down-short-wide fa-lg ml-2 hover:cursor-pointer relative ${showFilters ? "text-blue-500" : ""}`}
                            onClick={() => setShowFilters((showFilters) => !showFilters)}
                        >
                            {numberOfFilters > 0 && <span className="text-xs absolute bottom-0 right-[-1]">{numberOfFilters}</span>}
                        </i>
                    </div>

                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>
                </div>
                <div className={`${showFilters ? "" : "hidden"}`}>
                    <div className={`flex flex-row justify-between gap-3 mt-1`}>
                        <div className="flex flex-col w-full">
                            <label htmlFor="filterProperty">Property</label>
                            <input type="text" name="filterProperty" id="filterProperty" value={inputValues.filterProperty} onChange={handleInputChange} className="custom-form"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="filterRoomId">Room Id</label>
                            <input type="text" name="filterRoomId" value={inputValues.filterRoomId} onChange={handleInputChange} className="custom-form"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="filterMessageIn">Message In</label>
                            <input type="text" name="filterMessageIn" value={inputValues.filterMessageIn} onChange={handleInputChange} className="custom-form"/>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between gap-3 mt-3">
                        <div className="flex flex-col w-full">
                            <label htmlFor="filterMessageOut">Message Out</label>
                            <input type="text" name="filterMessageOut" value={inputValues.filterMessageOut} onChange={handleInputChange} className="custom-form"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="filterBookingId">Booking Id</label>
                            <input type="text" name="filterBookingId" value={inputValues.filterBookingId} onChange={handleInputChange} className="custom-form"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="filterTypeBooking">Type Booking</label>
                            <input type="text" name="filterTypeBooking" value={inputValues.filterTypeBooking} onChange={handleInputChange} className="custom-form"/>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 justify-center mt-2">
                        {dirty && <button
                            className="text-xs text-blue-500 border border-blue-500 rounded p-1 hover:text-white hover:bg-blue-500"
                            onClick={applyFilters}
                        >
                            Apply
                        </button>}
                        {numberOfFilters > 0 && <button
                            className="text-xs text-blue-500 border border-blue-500 rounded p-1 hover:text-white hover:bg-blue-500"
                            onClick={resetFilters}
                        >
                            Reset
                        </button>}
                    </div>
                </div>

            </div>
        );
    };


    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };


    function renderDateColumn(data: SiteminderReceiver){
        return data.receivedTimestamp ? formatDate(data.receivedTimestamp) : "-";
    }

    function renderMessageIn(data: SiteminderReceiver){
        return data.messageIn ? <div
            className="max-w-xs overflow-hidden overflow-ellipsis hover:cursor-pointer"
            onClick={() => showCustomDialog(
                `Message In (${data.id})`,
                <XMLViewer
                    xml={data.messageIn}
                    collapsible
                    invalidXml={<div>{data.messageIn}</div>}
                />
            )}
        >
            <span className="text-blue-500">View</span>
        </div> : data.messageIn
    }

    function renderMessageOut(data: SiteminderReceiver){
        return data.messageIn ? <div
            className="max-w-xs overflow-hidden overflow-ellipsis hover:cursor-pointer"
            onClick={() => showCustomDialog(
                `Message Out (${data.id})`,
                <XMLViewer
                    xml={data.messageOut}
                    collapsible
                    invalidXml={<div>{data.messageOut}</div>}
                />
            )}
        >
            <span className="text-blue-500">View</span>
        </div> : data.messageOut
    }

    function showCustomDialog(header: string, content: any){
        setDialogContent(content);
        setDialogHeader(header);
        setShowDialog(true);
    }

    function hideDialog(){
        setDialogContent("");
        setDialogHeader("");
        setShowDialog(false);
    }


    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md "},
                        thead: {className: "bg-gray-400"}
                    }}
                    header= {renderHeader()}
                >


                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="property"
                        header="Property"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="roomId"
                        header="Room Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="receivedTimestamp"
                        header="Received"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="messageIn"
                        header="Message In"
                        body={renderMessageIn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="messageOut"
                        header="Message Out"
                        body={renderMessageOut}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="bookingId"
                        header="Booking Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="typeBooking"
                        header="Type Booking"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                </DataTable>
                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white"},
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>

        <Dialog header={dialogHeader} visible={showDialog} style={{ width: '50vw' }} onHide={hideDialog}>
            <p className="m-0">
                {dialogContent}
            </p>
        </Dialog>

    </div>

}