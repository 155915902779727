import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import React, {useContext, useEffect, useRef, useState} from "react";
import {createQueryString, getJsonFromBackend} from "../../../data/network";
import {Booking, Hotel} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {esteDeTipulDate} from "../../../lib/helpers";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {DataTable, DataTableFilterMeta} from "primereact/datatable";
import {Column} from "primereact/column";
import {RadioButton, RadioButtonChangeEvent} from "primereact/radiobutton";
import BookingDetailsDialog from "./BookingDetailsDialog";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";

export default function CloudbedsBookingsPage() {

    const [hotels, setHotels] = useState<Hotel[]>([]);

    const [data, setData] = useState<Booking[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<string | Date | Date[] | undefined | null>(new Date());
    const [endDate, setEndDate] = useState<string | Date | Date[] | undefined | null>(new Date());
    const [filterType, setFilterType] = useState<string>('checkingIn');
    const [selectedHotel, setSelectedHotel] = useState<number>(0);

    const [dirty, setDirty] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(true);
    const {showToastMessage} = useContext(GlobalContext);

    const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<Booking|undefined>(undefined);

    const [filters, setFilters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS}
    });
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    // @ts-ignore
    const dt = useRef<DataTable>(null);

    useEffect(() => {
        getJsonFromBackend("/hotels/get-my-hotels-with-property-id")
            .then((rsp: Hotel[]) => {
                setHotels(rsp);
            })
            .catch(err => {
                showToastMessage("Error", "Error getting the data about the hotels", "error");
                console.log(err);
            })
    }, [])

    function changeStartDate(e: CalendarChangeEvent) {
        setStartDate(prevValue => e.value);
        setDirty(prevState => true);
    }

    function changeEndDate(e: CalendarChangeEvent) {
        setEndDate(prevValue => e.value);
        setDirty(prevState => true);
    }

    function handleHotelChanged(e: any){
        setSelectedHotel(prevState => e.target.value);
        setDirty(prevState => true);
    }

    function getBookingsData(){

        const queryParamsObject = {
            hotelId: selectedHotel,
            start_date: esteDeTipulDate(startDate) ? startDate.getTime() : null,
            end_date: esteDeTipulDate(endDate) ? endDate.getTime() : null,
            filterType: filterType
        }

        console.log(createQueryString(queryParamsObject));

        setLoading(prevState => true);
        setDataLoaded(prevState => false);

        getJsonFromBackend("/cloudbeds-bookings/get?"+createQueryString(queryParamsObject))
            .then((res : Booking[]) => {
                console.log("RES ", res);

                setLoading(prevState => false);
                setData(prevState => res);
                setDataLoaded(prevState => true);

            })
            .catch(err => {
                console.log("ERR ", err);
                setLoading(prevState => false);
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Error getting the data", "error");
                }
            })

        setDirty(prevState => false);
    }

    function renderGuest(data: Booking) {
        return <div className="flex flex-col">
            <div
                className="text-blue-500 font-medium hover:cursor-pointer hover:underline"
                onClick={() => {
                    setSelectedBooking(prevState => data);
                    setShowDetailsDialog(prevState => true);
                }}
            >
                {data.firstName} {data.lastName}
            </div>
            <div className="text-xs">Adults: {data.numAdult}</div>
            {(data.numChild > 0) && <div className="text-xs">Children: {data.numChild}</div>}
        </div>
    }

    function renderStatus(data: Booking) {
        return <>
            {data.status == "cancelled" && <i className="fas fa-exclamation mr-2 text-red-500"/>}
            {data.status.toUpperCase()}
        </>
    }

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let _filters = { ...filters };

        // @ts-ignore
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const renderHeader = () => {
        return (

            <div className="flex flex-col w-full gap-4 bg-white min-w-fit">

                <div className="flex flex-row gap-2">

                    {hotels && hotels.length > 0 && <div className="flex flex-col max-w-[150px]">
                        <label className="font-medium text-blue-500" htmlFor="selected-hotel">Hotel</label>
                        <select
                            className={`custom-form rounded-lg block 2.5`}
                            name="selected-hotel"
                            id="selected-hotel"
                            value={selectedHotel}
                            onChange={handleHotelChanged}
                        >
                            <option key={0} value={0}>Select a hotel</option>
                            {hotels.map(hotel => <option key={hotel.id} value={hotel.id}>{hotel.name}</option>)}

                        </select>
                    </div>}

                    {dirty && startDate && selectedHotel != 0 && <div className="flex flex-col">
                        <button
                            className="text-white py-2 px-3 bg-blue-500 border border-blue-500 rounded hover:text-white hover:bg-blue-600 hover:border-blue-600 mt-auto"
                            onClick={getBookingsData}
                        >
                            Get Data
                        </button>
                    </div>}


                    <div className="content-center ml-auto">
                        <i
                            className={`fa-solid fa-arrow-down-short-wide fa-lg ml-2 hover:cursor-pointer relative ${showFilters ? "text-blue-500" : ""}`}
                            onClick={() => setShowFilters(!showFilters)}
                        />
                    </div>

                </div>

                {showFilters && <div className="flex flex-col gap-3">
                    {/*<div>*/}
                    {/*    <div className="card flex justify-content-center">*/}
                    {/*        <div className="flex flex-wrap gap-3">*/}
                    {/*            <div className="flex align-items-center">*/}
                    {/*                <RadioButton inputId="input1" name="checkingIn" value="checkingIn" onChange={(e: RadioButtonChangeEvent) => {setFilterType(prevState => e.value); setDirty(prevState => true)}} checked={filterType === 'checkingIn'} />*/}
                    {/*                <label htmlFor="checkingIn" className="ml-2">Checking in</label>*/}
                    {/*            </div>*/}
                    {/*            <div className="flex align-items-center">*/}
                    {/*                <RadioButton inputId="input2" name="checkingOut" value="checkingOut" onChange={(e: RadioButtonChangeEvent) => {setFilterType(prevState => e.value); setDirty(prevState => true)}} checked={filterType === 'checkingOut'} />*/}
                    {/*                <label htmlFor="checkingOut" className="ml-2">Checking out</label>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="flex flex-row gap-2">

                        <div className="flex flex-col w-1/6">
                            <label className="font-medium text-blue-500" htmlFor="booking-type">Type</label>
                            <select
                                name="booking-type"
                                className="custom-form"
                                value={filterType}
                                onChange={(e) => {setFilterType(e.target.value); setDirty(prevState => true)}}
                            >
                                <option value="checkingIn">Checking in</option>
                                <option value="checkingOut">Checking out</option>
                            </select>
                        </div>

                        <div className="flex flex-col w-1/6">
                            <label className="font-medium text-blue-500" htmlFor="start-date">Start Date</label>
                            <Calendar
                                id="calendar-24h"
                                value={startDate}
                                onChange={(e) => changeStartDate(e)}
                                showIcon
                                showButtonBar
                                view="date"
                                pt={{
                                    day:{className: "!m-[1px] !p-[1px]"},
                                    container: {className: "!p-[2px]"}
                                }}
                            />
                        </div>

                        <div className="flex flex-col w-1/6">
                            <label className="font-medium text-blue-500" htmlFor="start-date">End Date</label>
                            <Calendar
                                id="calendar-24h"
                                value={endDate}
                                onChange={(e) => changeEndDate(e)}
                                showIcon
                                showButtonBar
                                view="date"
                                pt={{
                                    day:{className: "!m-[1px] !p-[1px]"},
                                    container: {className: "!p-[2px]"}
                                }}
                            />
                        </div>

                        <div className="pt-2 mt-auto">
                            <button
                                className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                                onClick={() => exportCSV(false)}
                            >
                                <i className="fa-solid fa-file-csv fa-xl"/>
                            </button>
                        </div>


                        {dataLoaded && data.length > 0 && <div className="flex flex-row ml-auto">
                            <span className="p-input-icon-left mt-auto">
                                <i className="fa fa-search" />
                                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>

                        </div>}
                    </div>
                </div>}

            </div>
        );
    };


    return <div className="flex flex-col min-h-full bg-white beds-24-calendar-page overflow-x-scroll min-w-full p-2">

        <div className="">
            <DataTable
                ref={dt}
                stripedRows={true}
                value={data}
                sortField="arrival"
                sortOrder={1}
                filters= {filters}
                loading={loading}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                rowsPerPageOptions={[5, 10, 25, 50]}
                first={first}
                rows={pageSize}
                totalRecords={data.length}
                tableStyle={{ minWidth: '50rem'}}
                globalFilterFields= {['roomId', 'id', 'firstName', 'lastName', 'arrival', 'departure', 'status']}
                header= {renderHeader()}
                emptyMessage= "No data found."
                pt={{
                    header: {className: "! !border-0 !rounded-t-md !bg-white"},
                }}
            >
                <Column
                    sortable
                    field="firstName"
                    header="Guest"
                    body={renderGuest}
                    pt={{headerTitle: {className: "!font-bold"}}}
                />

                <Column
                    sortable
                    field="lastName"
                    header="Last Name"
                    filter={false}
                    body={(data) => {return <></>}}
                    pt={{
                        headerTitle: {className: "!font-bold"},
                        root: {className: "!hidden"}
                    }}
                />

                <Column
                    sortable
                    field="roomId"
                    header="Room"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />
                <Column
                    sortable
                    field="id"
                    header="Reservation"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />

                <Column
                    sortable
                    field="arrival"
                    header="Arrival"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />
                <Column
                    sortable
                    field="departure"
                    header="Departure"
                    pt={{headerTitle: {className: "!font-bold"}}}
                />
                <Column
                    sortable
                    field="status"
                    header="Status"
                    body={renderStatus}
                    pt={{
                        headerTitle: {className: "!font-bold"},
                    }}
                />
            </DataTable>
        </div>

        {showDetailsDialog && selectedBooking && <BookingDetailsDialog
            showDialog={showDetailsDialog}
            booking={selectedBooking}
            onHide={() => {setShowDetailsDialog(() => false); setSelectedBooking(() => undefined)}}
        />}

     </div>
}