import BouncingRightText from "../components/BouncingRightText";

export default function Home() {
    const baseUrl = process.env.PUBLIC_URL + "";

    return <div className="container mx-auto py-8 px-4 h-full">

        <div className="flex flex-col h-full justify-center content-center">
            <div className="w-full pt-10 text-xl">
                <img src={baseUrl + "/name_logo.png"} className="w-1/3 m-auto"/>
            </div>
        </div>
    </div>
}