import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {postJsonFileToBackend} from "../../data/network";
import {UploadFileResponse} from "../../data/BackendClasses";
import React, {useContext} from "react";
import {GlobalContext} from "../../data/GlobalContext";
import CancellationDataTable from "./CancellationDataTable";


export default function CancellationPage() {

    const {showToastMessage} = useContext(GlobalContext);


    function uploadHandler(event: FileUploadHandlerEvent) {

        const formData = new FormData();

        event.files.forEach((file : File) => {
            formData.append('data[]', file);
        })


        postJsonFileToBackend("/cancellation/upload", formData)
            .then((rsp: UploadFileResponse) => {
                console.log("RSP UPLOAD ", rsp)
                showToastMessage(rsp.message, rsp.csv_files.join(","), "success");
                event.options.clear();
            })
            .catch(err => {
                console.log("ERR", err)
                showToastMessage("Error", "something went wrong", "error");
                console.log(err);
            })
    }

    return (
        <div className="flex flex-col h-full pt-10">
            <div className="w-3/4 m-auto">
                <FileUpload

                    name="data"
                    multiple
                    accept=".csv"
                    withCredentials={true}
                    maxFileSize={1000000}
                    emptyTemplate={<p className="m-0">Drag and drop files here to upload.</p>}
                    customUpload
                    uploadHandler={uploadHandler}
                    pt={{
                        buttonbar: {className: "!bg-white"},
                        content: {className: "!max-h-[25vh] !overflow-auto"},
                    }}
                    onClear={() => console.log("CLEAR")}
                />
            </div>
            <div className="grow">
                <CancellationDataTable/>
            </div>

        </div>
    )

}