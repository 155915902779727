import {Link} from "react-router-dom";

export default function Management() {


    return <div className="container mx-auto py-8 px-4 h-full">
        <div className="flex flex-wrap justify-center content-center w-full gap-2 h-full">

            <div className="w-full md:w-1/2 lg:w-1/4 p-4 bg-white/70 h-1/2 backdrop-blur rounded-xl hover:opacity-50 shadow-md">
                <Link to="/management-users">
                    <div className="h-1/2 flex flex-col grid content-end">
                        <i className="fa-solid fa-user fa-4x align-baseline m-auto"/>
                    </div>
                    <div className="h-1/2 flex flex-col">
                        <div className="m-auto text-xl">Users</div>
                    </div>
                </Link>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4 p-4 bg-white/70 h-1/2 backdrop-blur rounded-xl hover:opacity-50 shadow-md">
                <Link to="/management-hotels">
                    <div className="h-1/2 flex flex-col grid content-end">
                        <i className="fa-solid fa-hotel fa-4x align-baseline m-auto"/>
                    </div>
                    <div className="h-1/2 flex flex-col">
                        <div className="m-auto text-xl">Hotels</div>
                    </div>
                </Link>
            </div>


        </div>
    </div>
}