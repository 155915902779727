import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {BackendError, ReturnedError, SiteminderHistory} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {getJsonFromBackend, postJsonThenJsonToBackend, postJsonToBackend} from "../../../data/network";
import {InputText} from "primereact/inputtext";
import {Column} from "primereact/column";
import {Skeleton} from "primereact/skeleton";
import {formatDate, formHasErrors} from "../../../lib/helpers";
import '@fortawesome/fontawesome-free/css/all.min.css';
import {useFormState} from "../../../data/Form";

const initialState = {bookingId: '', propertyId: ''};

export default function SiteminderBookingCancellationPage() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [data, setData] = useState<SiteminderHistory[]>([]);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState);

    const erori: { [x: string]: string[] } = {bookingId: [], propertyId: []};


    useEffect(() => {

        console.log("GET CALLED ", new Date());

        getJsonFromBackend("/siteminder-history/get-last-cancellations")
            .then((rsp) => {
                setData(rsp);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting Siteminder Logs data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setReload((reload) => !reload);
        }, 10000);
        return () => clearInterval(intervalId);
    }, []);


    if(inputValues.bookingId == ''){
        erori.bookingId.push("Booking ID is mandatory");
    }

    if(inputValues.propertyId == ''){
        erori.propertyId.push("Property ID is mandatory");
    }

    function sendCancellation() {

        if(!window.confirm(`Are you sure you want to continue?`)){
            return;
        }

        postJsonToBackend("/siteminder-history/send-cancellation", {bookingId: inputValues.bookingId, propertyId: inputValues.propertyId})
            .then(rsp => {
                showToastMessage("Cancellation sent", rsp, "success");
                resetInputValues(initialState);
            })
            .catch((err: ReturnedError) => {
                console.log("ERR ", err)
                showToastMessage("Error", err.errors[0].message, "error");
            })
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-col gap-2">

                <div className="flex flex-row gap-2">
                    <div className="flex flex-col w-1/4">
                        <label htmlFor="bookingId">Booking ID</label>
                        <input type="text" name="bookingId" id="bookingId" value={inputValues.bookingId} onChange={handleInputChange} className="custom-form"/>
                    </div>
                    <div className="flex flex-col w-1/4">
                        <label htmlFor="propertyId">Property ID</label>
                        <input type="propertyId" name="propertyId" value={inputValues.propertyId} onChange={handleInputChange} className="custom-form"/>
                    </div>

                    {dirty && !formHasErrors(erori) && <div className="flex flex-col">
                        <label htmlFor="send-button"/>
                        <button
                            name="send-button"
                            className="text-sm text-blue-500 border border-blue-500 rounded p-1 hover:text-white hover:bg-blue-500 h-1/2 mt-auto"
                            onClick={sendCancellation}
                        >
                            Send
                        </button>

                    </div>}
                </div>

                {dirty && formHasErrors(erori) && <div className="flex flex-col">
                    {(erori.bookingId && erori.bookingId.length > 0) && <span className="text-red-500 font-sm">{erori.bookingId[erori.bookingId.length-1]}</span>}
                    {(erori.propertyId && erori.propertyId.length > 0) && <span className="text-red-500 font-sm">{erori.propertyId[erori.propertyId.length-1]}</span>}
                </div>}

            </div>
        )}




    function renderCompleted(data: SiteminderHistory){
        return <div>
            {undefined == data.completed ? <i className="fa-solid fa-question"/> : (data.completed ? <i className="fa-solid fa-check"/> : <i className="fa-solid fa-times"/>)}
        </div>
    }

    function renderDateColumn(data: SiteminderHistory){
        return data.receivedTimestamp ? formatDate(data.receivedTimestamp) : "-";
    }



    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={0}
                    totalRecords={data.length}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    rows={10}
                    paginator
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md"},
                    }}
                    header= {renderHeader()}
                >

                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="typeBooking"
                        header="Booking Type"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="property"
                        header="Property"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="bookingId"
                        header="Booking Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="receivedTimestamp"
                        header="Received"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="completed"
                        header="Completed"
                        body={renderCompleted}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                </DataTable>

            </> : <Skeleton className="h-72"/>}
        </div>

    </div>

}