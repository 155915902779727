import {setCurrentUser} from "./current-user";
import {AnyObject} from "../lib/helpers";

const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const getDefaultHeaders = () => ({
   cache: 'no-cache'
});

export class AuthenticationNeededException {}

export async function postJsonThenJsonToBackend(url, body, headers = {}){
    return postJsonThenJson(backendBaseUrl + url, body, headers);
}

export async function postJsonToBackend(url, body, headers = {}){
    return postJson(backendBaseUrl + url, body, headers);
}

export async function postJsonFileToBackend(url, body, headers = {}){
    return postJsonFile(backendBaseUrl + url, body, headers);
}

export function getJsonFromBackend(url, headers = {}){
    return getJson(backendBaseUrl + url, headers);
}

function createBody(body){
    if (typeof body === 'string' || body instanceof String)
        return body;
    else return JSON.stringify(body)
}

export function createPaginationParams(pageNumber, pageSize){
    return "?pageNumber="+pageNumber+"&pageSize="+pageSize;
}

export function createSortingParams(sortField, sortOrder){
    return "&sortField="+sortField+"&sortOrder="+sortOrder;
}

export function createPaginationAndSortingParams(pageNumber, pageSize, sortField, sortOrder){
    return createPaginationParams(pageNumber, pageSize) + createSortingParams(sortField, sortOrder);
}

export function createQueryString(obj) {
    // Object.entries(obj).forEach(([key,value]) => {
    //     console.log(`${key} : ${value}  ${value !== ''}`)
    // })

    const queryString = Object.entries(obj)
        .filter(([key, value]) => value !== undefined && value !== '')
        .map(([key, value]) => `&${key}=${encodeURIComponent(value)}`)
        .join('');

    return queryString;
}

export async function postJson(url, body, headers = {}) {
    const retry = async () => await fetch(url, {
      method: 'POST',
      credentials: "include",
      headers: {
          ...getDefaultHeaders(),
          ...headers,
          'Content-Type': 'application/json',
      },
      body: createBody(body)
    }).then(async rsp => {
        if(!rsp.ok){
            if(rsp.status === 401){
                const authenticationNeededException = new AuthenticationNeededException();
                setCurrentUser(authenticationNeededException);
                throw AuthenticationNeededException;
            }
            return rsp.json()
                .catch(err => {
                    throw {type: "Unknown error", message: "Unknown error"};
                })
                .then(async data => {
                    throw data;
                })
        } else {
            return rsp.text().then(data => {
                return data;
            });
        }
    });

    return retry();
}

export async function postJsonFile(url, body, headers = {}) {
    const retry = async () => await fetch(url, {
        method: 'POST',
        credentials: "include",
        headers: {
            ...getDefaultHeaders(),
            ...headers
        },
        body: body
    }).then(async rsp => {
        if(!rsp.ok){
            if(rsp.status === 401){
                const authenticationNeededException = new AuthenticationNeededException();
                setCurrentUser(authenticationNeededException);
                throw AuthenticationNeededException;
            }
            return rsp.json()
                .catch(err => {
                    throw {type: "Unknown error", message: "Unknown error"};
                })
                .then(async data => {
                    throw data;
                })
        } else {

            return rsp.json()
                .then(data => {
                    return data;
                })
                .catch(err => {
                    return rsp.text()
                        .then(data => {
                            return data;
                        })
                        .catch(err => {
                            return rsp;
                        });
                });

            // return rsp.text().then(data => {
            //     return data;
            // });

        }
    });

    return retry();
}

export async function postJsonThenJson(url, body, headers = {}) {
    const retry = async () => await fetch(url, {
        method: 'POST',
        credentials: "include",
        headers: {
            ...getDefaultHeaders(),
            ...headers,
            'Content-Type': 'application/json',
        },
        body: createBody(body)
    }).then(async rsp => {
        if(!rsp.ok){
            if(rsp.status === 401){
                const authenticationNeededException = new AuthenticationNeededException();
                setCurrentUser(authenticationNeededException);
                throw AuthenticationNeededException;
            }
            return rsp.json()
                .catch(err => {
                    throw Error(`Eroare necunoscuta ${err}`);
                })
                .then(async data => {
                    throw data;
                })
        } else {

            return rsp.json()
                .then(data => {
                    return data;
                })
                .catch(err => {
                    return rsp;
                });
        }
    });

    return retry();
}

export function getJson(url, headers = {}){
    return fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            ...getDefaultHeaders(),
            ...headers,
            'Accept': 'application/json'
        }
    }).then(async rsp => {
        if(!rsp.ok){
            if(rsp.status === 401) {
                throw new AuthenticationNeededException();
            }
            return rsp.json()
                .catch(err => {
                    throw {type: "Unknown error", message: "Unknown error"};
                })
                .then(async data => {
                    throw data;
                })
        } else {
            return rsp.json();
        }
    })
}