import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {ActionLog, InternalLog, TransactionLog} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {createPaginationParams, getJsonFromBackend} from "../../../data/network";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Skeleton} from "primereact/skeleton";
import {Dialog} from "primereact/dialog";
import {formatDate} from "../../../lib/helpers";
import ReactJson from "@microlink/react-json-view";


export default function TransactionLogsPage() {
    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const [data, setData] = useState<TransactionLog[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>("");
    const [dialogHeader, setDialogHeader] = useState<string>("");



    useEffect(() => {
        getJsonFromBackend("/transactions/logs" + createPaginationParams(page, pageSize))
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting transaction logs data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload, page, pageSize])

    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-between">
                <div className="flex align-items-center justify-content-end gap-2">
                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>

                </div>
            </div>
        );
    };

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };



    function renderLogsData(data: TransactionLog){
        return <div
            className="max-w-sm overflow-hidden overflow-ellipsis hover:cursor-pointer"
            // onClick={() => showCustomDialog(`Logs (${data.id})`, data.serializedTransaction)}
            onClick={() => {
                try {
                    showCustomDialog(
                        `Logs (${data.id})`,
                        <ReactJson src={JSON.parse(data.serializedTransaction.replace(/'/g, '"').replaceAll("True", "true").replaceAll("False", "false"))}/>
                    )
                } catch (err){
                    showCustomDialog(
                        `Logs (${data.id})`,
                        data.serializedTransaction
                    )
                }
            }}
        >
            <span className="whitespace-nowrap">{data.serializedTransaction}</span>
        </div>
    }

    function renderDateColumn(data: TransactionLog){
        return formatDate(data.generationDate);
    }

    function showCustomDialog(header: string, content: any){
        setDialogContent(content);
        setDialogHeader(header);
        setShowDialog(true);
    }

    function hideDialog(){
        setDialogContent("");
        setDialogHeader("");
        setShowDialog(false);
    }


    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md"}
                    }}
                    header= {renderHeader()}
                >

                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="username"
                        header="Username"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="serializedTransaction"
                        header="Serialized Transaction"
                        body={renderLogsData}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="generationDate"
                        header="Date"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />



                </DataTable>
                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white"},
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>


        <Dialog header={dialogHeader} visible={showDialog} style={{ width: '50vw' }} onHide={hideDialog}>
            <p className="m-0">
                {dialogContent}
            </p>
        </Dialog>

    </div>

}