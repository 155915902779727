import {AppUser, Hotel} from "../../../data/BackendClasses";
import {Dialog} from "primereact/dialog";
import {formHasErrors, isNumber} from "../../../lib/helpers";
import React, {useContext} from "react";
import {useFormState} from "../../../data/Form";
import {postJsonToBackend} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";
import {isReturnedErrors} from "../../../components/ErrorModal";


export default function EditHotelDialog(
    {
        showEditDialog,
        selectedHotel,
        onClose
    }:{
        showEditDialog: boolean,
        selectedHotel: Hotel,
        onClose: () => void
    }) {
    const {inputValues, handleInputChange, dirty} = useFormState({
        ...selectedHotel,
        profile: selectedHotel.profile ? selectedHotel.profile : '',
        propertyId: selectedHotel.propertyId ? selectedHotel.propertyId : '',
        category: selectedHotel.category ? selectedHotel.category : '',
        taxPercent: selectedHotel.taxPercent.toString(),
        airbnbCom: selectedHotel.airbnbCom.toString(),
        rocketstayShare: selectedHotel.rocketstayShare.toString()
    })

    // const {inputValues, handleInputChange, dirty} = useFormState({...selectedHotel, taxPercent: selectedHotel.taxPercent.toString(), airbnbCom: selectedHotel.airbnbCom.toString(), rocketstayShare: selectedHotel.rocketstayShare.toString()})
    const erori: { [x: string]: string[] } = {hotelName: [], hotelProfile: [], propertyId: [], category: [], taxPercent: [], airbnbCom: [], rocketstayShare: []};
    const {showToastMessage} = useContext(GlobalContext);

    function editHotel(e: any){
        e.preventDefault();
        console.log("EDIT HOTEL ", inputValues);
        console.log("INITIAL HOTEL ", selectedHotel)

        postJsonToBackend("/hotels/edit", inputValues)
            .then(rsp => {
                showToastMessage("Success", "Hotel modified", "success");
                onClose();
            })
            .catch(err => {
                console.log("ERR ", err);
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Something went bad and the hotel was not modified", "error");
                }
            })
    }

    function hotelIsModified(){
        return inputValues.name != selectedHotel.name ||
            inputValues.profile != selectedHotel.profile ||
            inputValues.propertyId != selectedHotel.propertyId ||
            inputValues.category != selectedHotel.category ||
            inputValues.taxPercent != selectedHotel.taxPercent.toString() ||
            inputValues.airbnbCom != selectedHotel.airbnbCom.toString() ||
            inputValues.rocketstayShare != selectedHotel.rocketstayShare.toString()
    }

    if (!inputValues.name && dirty) {
        erori.hotelName.push("Hotel name is mandatory");
    }

    if(inputValues.propertyId && !/^\d+$/.test(inputValues.propertyId)){
        erori.propertyId.push("Property Id should be a number");
    }

    if(dirty && !(inputValues.propertyId.length > 0 || inputValues.profile.length > 0)){
        erori.propertyId.push("At least one of this fields is mandatory");
        erori.hotelProfile.push("At least one of this fields is mandatory");
    }

    if(dirty && (inputValues.category != '') && !isNumber(inputValues.category)){
        erori.category.push("Category should be a numeric value");
    }

    if(dirty && (inputValues.taxPercent != '') && !isNumber(inputValues.taxPercent)){
        erori.taxPercent.push("Tax Percent should be a numeric value");
    }

    if(dirty && (inputValues.airbnbCom != '') && !isNumber(inputValues.airbnbCom)){
        erori.airbnbCom.push("Airbnb Com should be a numeric value");
    }

    if(dirty && (inputValues.rocketstayShare != '') && !isNumber(inputValues.rocketstayShare)){
        erori.rocketstayShare.push("Rocketstay Share should be a numeric value");
    }

    return <>
        <Dialog
            header={`Edit ${selectedHotel.name}`}
            visible={showEditDialog}
            style={{ width: '50vw'}}
            onHide={onClose}
            pt={{
                root: {className: "!shadow-2xl"},
                content: {className: "!bg-white/70  backdrop-blur-3xl"},
                header: {className: "!bg-white/70  backdrop-blur-3xl !text-[#644243]"},
                headerIcons: {className: "!text-white "},
                closeButton: {className: "!text-white "}
            }}
        >
            <div>
                <form onSubmit={editHotel}>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelName.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="name">Full Name</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.hotelName.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="name" id="name" type="text" value={inputValues.name} onChange={handleInputChange}/>
                        {erori.hotelName.length > 0 && <div className="text-red-500 text-sm">{erori.hotelName[erori.hotelName.length-1]}</div>}
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.hotelProfile.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="profile">Hotel Profile</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelProfile.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="profile" id="profile" type="text" value={inputValues.profile} onChange={handleInputChange}/>
                            {erori.hotelProfile.length > 0 && <div className="text-red-500 text-sm">{erori.hotelProfile[erori.hotelProfile.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.propertyId.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="propertyId">Property ID</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.propertyId.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="propertyId" id="propertyId" type="text" value={inputValues.propertyId} onChange={handleInputChange}/>
                            {erori.propertyId.length > 0 && <div className="text-red-500 text-sm">{erori.propertyId[erori.propertyId.length-1]}</div>}
                        </div>
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.category.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="category">Category</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.category.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="category" id="category" type="text" value={inputValues.category} onChange={handleInputChange}/>
                            {erori.category.length > 0 && <div className="text-red-500 text-sm">{erori.category[erori.category.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.taxPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPercent">Tax Percent</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPercent" id="taxPercent" type="text" value={inputValues.taxPercent} onChange={handleInputChange}/>
                            {erori.taxPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPercent[erori.taxPercent.length-1]}</div>}
                        </div>
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.airbnbCom.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="airbnbCom">Airbnb Com</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.airbnbCom.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="airbnbCom" id="airbnbCom" type="text" value={inputValues.airbnbCom} onChange={handleInputChange}/>
                            {erori.airbnbCom.length > 0 && <div className="text-red-500 text-sm">{erori.airbnbCom[erori.airbnbCom.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.rocketstayShare.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="rocketstayShare">Rocketstay Share</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.rocketstayShare.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="rocketstayShare" id="rocketstayShare" type="text" value={inputValues.rocketstayShare} onChange={handleInputChange}/>
                            {erori.rocketstayShare.length > 0 && <div className="text-red-500 text-sm">{erori.rocketstayShare[erori.rocketstayShare.length-1]}</div>}
                        </div>
                    </div>

                    <div className="w-full text-center">
                        {!formHasErrors(erori) && dirty && hotelIsModified() && <button
                            type="submit"
                            className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 px-2 py-1"
                        >
                            Edit
                        </button>}
                    </div>
                </form>
            </div>
        </Dialog>
    </>


}