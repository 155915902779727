import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {ReviewHotel} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {createPaginationParams, createQueryString, getJsonFromBackend} from "../../../data/network";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Skeleton} from "primereact/skeleton";
import {Dialog} from "primereact/dialog";
import {compareObjects, formatDate} from "../../../lib/helpers";
import {useFormState} from "../../../data/Form";

interface Filter {
    bookingId: string;
    checkOut: string;
    processed: string;
    reason: string;
}

const initialState : Filter = {bookingId: '', checkOut: '', processed: 'NOTHING', reason: ''};

export default function ReviewsHotelPage() {
    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const [data, setData] = useState<ReviewHotel[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [numberOfFilters, setNumberOfFilters] = useState<number>(0);
    const [filtersChanged, setFiltersChanged] = useState<boolean>(false);

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState);

    const {showToastMessage} = useContext(GlobalContext);

    useEffect(() => {
        getJsonFromBackend("/reviews-hotel/get" + createPaginationParams(page, pageSize) + createQueryString(inputValues))
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload, page, pageSize, filtersChanged])

    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col content-center justify-center">
                        <i
                            className={`fa-solid fa-arrow-down-short-wide fa-lg ml-2 hover:cursor-pointer relative ${showFilters ? "text-blue-500" : ""}`}
                            onClick={() => setShowFilters((showFilters) => !showFilters)}
                        >
                            {numberOfFilters > 0 && <span className="text-xs absolute bottom-0 right-[-1]">{numberOfFilters}</span>}
                        </i>
                    </div>

                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>
                </div>

                <div className={`${showFilters ? "" : "hidden"}`}>
                    <div className={`flex flex-row justify-between gap-3 mt-1`}>
                        <div className="flex flex-col w-full">
                            <label htmlFor="bookingId">Booking ID</label>
                            <input type="text" name="bookingId" id="bookingId" value={inputValues.bookingId} onChange={handleInputChange} className="custom-form"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="checkOut">Check Out</label>
                            <input type="text" name="checkOut" value={inputValues.checkOut} onChange={handleInputChange} className="custom-form"/>
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="reason">Reason</label>
                            <input type="text" name="reason" value={inputValues.reason} onChange={handleInputChange} className="custom-form"/>
                        </div>

                        <div className="flex flex-col w-full">
                            {/*<label htmlFor="processed">Processed</label>*/}
                            <select
                                className="custom-form text-center mt-auto"
                                name="processed"
                                value={inputValues.processed}
                                onChange={handleInputChange}
                            >
                                <option value="NOTHING">Filter by processed</option>
                                <option className="font-bold" value="TRUE">&#x2713;</option>
                                <option className="font-bold" value="FALSE">&#x2715;</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 justify-center mt-2">
                        {dirty && <button
                            className="text-xs text-blue-500 border border-blue-500 rounded p-1 hover:text-white hover:bg-blue-500"
                            onClick={applyFilters}
                        >
                            Apply
                        </button>}
                        {numberOfFilters > 0 && <button
                            className="text-xs text-blue-500 border border-blue-500 rounded p-1 hover:text-white hover:bg-blue-500"
                            onClick={resetFilters}
                        >
                            Reset
                        </button>}
                    </div>
                </div>
            </div>
        );
    };

    function applyFilters(e: any){
        e.preventDefault();
        setNumberOfFilters(compareObjects(inputValues, initialState));
        resetInputValues(inputValues);
        // setShowFilters((showFilters) => !showFilters);
        setFiltersChanged((filtersChanged) => !filtersChanged);
    }

    function resetFilters(e: any){
        e.preventDefault();
        setNumberOfFilters(0);
        resetInputValues(initialState);
        // setShowFilters((showFilters) => !showFilters);
        setFiltersChanged((filtersChanged) => !filtersChanged);
    }

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };


    function renderDateColumn(data: ReviewHotel){
        return formatDate(data.processedTimestamp);
    }

    function renderProcessed(data: ReviewHotel){
        return data.processed ? <i className="fa-solid fa-check"/> : <i className="fa-solid fa-xmark"/>;
    }



    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md"}
                    }}
                    header= {renderHeader()}
                >

                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="bookingId"
                        header="Booking ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="checkOut"
                        header="Check Out"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="processedTimestamp"
                        header="Processed Timestamp"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="processed"
                        header="Processed"
                        body={renderProcessed}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="reason"
                        header="Reason"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />


                </DataTable>
                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white"},
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>

    </div>

}