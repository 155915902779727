import {DerbysoftHotelSpreadsheet, DerbysoftRoomSpreadsheet} from "../../../data/BackendClasses";
import {Dialog} from "primereact/dialog";
import React, {useContext, useEffect, useState} from "react";
import {getJsonFromBackend, postJsonToBackend} from "../../../data/network";
import {Column, ColumnEditorOptions} from "primereact/column";
import {DataTable, DataTableRowEditCompleteEvent} from "primereact/datatable";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {GlobalContext} from "../../../data/GlobalContext";


export default function DerbysoftRoomsDialog(
    {
        selectedHotel,
        showRoomsDialog,
        onClose,
    }:{
        selectedHotel: DerbysoftHotelSpreadsheet,
        onClose: () => void,
        showRoomsDialog: boolean,
    }) {

    const [loading, setLoading] = useState<boolean>(false);
    const [rooms, setRooms] = useState<DerbysoftRoomSpreadsheet[]>([]);
    const [dirty, setDirty] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const {showToastMessage} = useContext(GlobalContext);

    useEffect(() => {
        setLoading(prevState => true);
        getJsonFromBackend("/derbysoft-rooms-spreadsheet/get-by-hotel-id?hotelId=" + selectedHotel.derbysoftHotelId)
            .then(rsp => {
                console.log("ROOMS RSP ", rsp);
                setRooms(prevState => rsp);
                setLoading(prevState => false);
            })
            .catch(err => {
                setLoading(prevState => false);
                console.log("ERR ", err);
            })
    }, [reload])


    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };

    const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
        let _rooms = [...rooms];
        let { newData, index } = e;
        _rooms[index] = newData as DerbysoftRoomSpreadsheet;

        setRooms(prevState => _rooms);
        setDirty(prevState => true);
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return <InputText type="text" value={options.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)} />;
    };

    function onCancel() {
        setReload(prevState => !prevState)
        setDirty(prevState => false);
    }

    function onApply() {
        console.log()
        postJsonToBackend("/derbysoft-rooms-spreadsheet/update", rooms)
            .then(rsp => {
                console.log("ROOMS UPDATED SUCCESSFULLY")
                setReload(prevState => !prevState)
                setDirty(prevState => false);
                showToastMessage("Success", "Rooms updated", "success");
            })
            .catch(err => {
                console.log("ERR ", err);
                showToastMessage("Error", err.errors[0].message, "error");
            })
    }


    return <>
        <Dialog
            header={`Rooms for ${selectedHotel.name}`}
            visible={showRoomsDialog}
            style={{ width: '70vw'}}
            onHide={onClose}
            pt={{
                root: {className: "!shadow-2xl"},
            }}
        >

            <DataTable
                value={rooms}
                first={0}
                rows={10}
                loading={loading}
                paginator
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                rowsPerPageOptions={[5, 10, 25, 50]}
                stripedRows
                emptyMessage="No data found."
                tableStyle={{minWidth: '50rem'}}
                rowClassName={rowClass}
                className={tableClassNames}
                editMode="row"
                onRowEditComplete={onRowEditComplete}
                pt={{
                    header: {className: "!bg-white !border-0"},
                }}
            >
                <Column
                    className="!bg-white "
                    field="status"
                    header="Status"
                    pt={{
                        headerTitle: {className: "!font-bold"}
                    }}
                />

                <Column
                    className="!bg-white "
                    field="roomName"
                    header="Room Name"
                    pt={{
                        headerTitle: {className: "!font-bold"}
                    }}
                />

                <Column
                    className="!bg-white "
                    field="maxAdults"
                    header="Max Adults"
                    pt={{
                        headerTitle: {className: "!font-bold"}
                    }}
                />

                <Column
                    className="!bg-white "
                    field="maxChildren"
                    header="Max Children"
                    pt={{
                        headerTitle: {className: "!font-bold"}
                    }}
                />

                <Column
                    className="!bg-white "
                    field="beds24RoomId"
                    header="Beds24 Room Id"
                    editor={(options) => textEditor(options)}
                    pt={{
                        headerTitle: {className: "!font-bold"}
                    }}
                />


                <Column rowEditor={true} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}/>

            </DataTable>

            {dirty && <div className="flex flex-row justify-center gap-2 mt-2">
                <button
                    className="border p-1 px-2 rounded bg-blue-500 text-white"
                    onClick={onApply}
                >
                    Apply
                </button>
                <button
                    className="border p-1 px-2 rounded bg-blue-500 text-white"
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>}

        </Dialog>
    </>

}