import {Dialog} from "primereact/dialog";
import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import React, {useEffect, useState} from "react";
import {RoomData} from "./CloudbedsCalendarPage";
import {Room} from "./BulkUpdateDialog";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";


export default function ChangeInventoryDialog(
        {
            showDialog,
            hideDialog,
            startDate,
            room,
            inventoryValue,
            onApply,
            rooms
        } : {
            showDialog: boolean,
            hideDialog: () => void,
            startDate: string | Date | Date[] | undefined | null,
            room: RoomData,
            inventoryValue:number,
            onApply: (roomIds: number[], dates: string[], newInventory: number) => void,
            rooms: Room[]
        }
    ) {

    const [internalStartDate, setInternalStartDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [internalEndDate, setInternalEndDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [internalInventoryValue, setInternalInventoryValue] = useState<number | undefined>(inventoryValue);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const [selectedDays, setSelectedDays] = useState<boolean[]>(Array(7).fill(true));

    const [selectedRooms, setSelectedRooms] = useState<Room[]>([{roomId: room.roomId, name: room.name, linked: room.linked}]);



    function changeEndDate(e: CalendarChangeEvent) {
        setInternalEndDate(e.value);
    }

    const handleInventoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.value == ''){
            setInternalInventoryValue(undefined);
        } else {
            const newValue = parseFloat(event.target.value);
            if (!isNaN(newValue)) {
                setInternalInventoryValue(newValue);
            }
        }
    };

    function isDate(obj: any): obj is Date {
        return obj instanceof Date;
    }

    function handleDayChange(newState: boolean, index: number) {
        setSelectedDays(prevState => [
                ...selectedDays.slice(0, index),
                newState,
                ...selectedDays.slice(index + 1)
            ]
        )
    }

    function applyChanges() {
        if(isDate(internalStartDate) && isDate(internalEndDate) && internalInventoryValue != undefined){
            const dates = [];
            let currentDate = new Date(internalStartDate.getTime());

            while (currentDate <= internalEndDate) {
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Zero-pad the month
                const day = String(currentDate.getDate()).padStart(2, '0'); // Zero-pad the day

                if(selectedDays[currentDate.getDay()]){
                    dates.push(`${year}-${month}-${day}`);
                }
                currentDate.setDate(currentDate.getDate() + 1);
            }

            onApply(selectedRooms.map(room => room.roomId), dates, internalInventoryValue);
            hideDialog();
        }
    }

    useEffect(() => {
        setInternalStartDate(prevState => typeof startDate === 'string' ? new Date(startDate) : startDate);
        setInternalEndDate(prevState => typeof startDate === 'string' ? new Date(startDate) : startDate);
    }, [])

    return <>
        <Dialog
            header={`Change Inventory`}
            visible={showDialog}
            pt={{
                root: {className: "w-full md:w-[50vw]"}
            }}
            onHide={hideDialog}
        >
            <div className="flex flex-col w-full">
                <div className="flex flex-row w-full gap-2">
                    <div className="flex flex-col w-full">
                        <label className="font-medium text-blue-500" htmlFor="start-date">Start Date</label>
                        <Calendar
                            id="calendar-24h"
                            value={internalStartDate}
                            // onChange={(e) => changeStartDate(e)}
                            showIcon
                            showButtonBar
                            view="date"
                            minDate={new Date()}
                            disabled={true}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <label className="font-medium text-blue-500" htmlFor="end-date">End Date</label>
                        <Calendar
                            id="calendar-24h"
                            value={internalEndDate}
                            onChange={(e) => changeEndDate(e)}
                            showIcon
                            showButtonBar
                            view="date"
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full gap-4">
                    <div className="flex flex-col w-1/3">
                        <label className="font-medium text-blue-500" htmlFor="end-date">Inventory</label>
                        <input
                            type="number"
                            className="custom-form"
                            value={internalInventoryValue === undefined ? '' : internalInventoryValue}
                            onChange={handleInventoryChange}
                        />
                    </div>

                    <div className="flex flex-row w-full mt-5 gap-3 justify-end">
                        {daysOfWeek.map((day, index) => (
                            <button
                                key={day}
                                value={day}
                                className={`${selectedDays[index] ? "text-white" : "text-blue-500"} py-2 px-3 ${selectedDays[index] ? "bg-blue-500" : "bg-white"} border border-blue-500 rounded hover:text-white hover:bg-blue-600 hover:border-blue-600 mt-auto text-xs sm:text-base`}
                                onClick={() => handleDayChange(!selectedDays[index], index)}
                            >
                                {day}
                            </button>
                        ))}
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full gap-4">
                    <div className="mt-2 max-w-[400px]">
                        <label className="font-medium text-blue-500" htmlFor="apply-to">Apply to</label>
                        <MultiSelect
                            name="apply-to"
                            value={selectedRooms}
                            onChange={(e: MultiSelectChangeEvent) => {
                                setSelectedRooms(e.value)}
                            }
                            options={rooms}
                            optionLabel="name"
                            placeholder="Select Rooms"
                            maxSelectedLabels={3}
                            className="w-full"
                        />
                    </div>
                </div>


                <div className="flex flex-row justify-center pt-5 gap-3">
                    { internalInventoryValue != undefined && (internalInventoryValue >= 0) && (selectedRooms.length > 0) && <div>
                        <button
                            className="border p-1 px-2 rounded bg-blue-500 text-white"
                            onClick={applyChanges}
                        >
                            Apply
                        </button>
                    </div>}
                    <div>
                        <button
                            className="border p-1 px-2 rounded bg-blue-500 text-white"
                            onClick={hideDialog}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    </>

}