import {ChangeEvent, Dispatch, SetStateAction, useCallback, useRef, useState} from "react";

interface FormState<STATE> {
    inputValues: STATE,
    handleInputChange: (e: ChangeEvent<any>) => void,
    resetInputValues: Dispatch<SetStateAction<STATE>>,
    dirty: boolean, formRef:React.MutableRefObject<any>,
    formHasErrors: boolean
}

export function useFormState<STATE>(initialState: STATE): FormState<STATE>{
    const [inputValues, setInputValues] = useState<STATE>(initialState);
    const [dirty, setDirty] = useState(false);
    const formRef = useRef(null);
    const [formHasErrors, setFormHasErrors] = useState(false);
    const handleInputChange = useCallback(onInputChange, []);

    return {inputValues, handleInputChange, resetInputValues, dirty, formRef, formHasErrors};

    function resetInputValues(e: SetStateAction<STATE>){
        setInputValues(e);
        setDirty(false);
    }

    function onInputChange(e: ChangeEvent<any>){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setInputValues(inputValues => ({...inputValues, [name]: value}));
        setDirty(true);
        if(formRef.current){
            //@ts-ignore
            setFormHasErrors(!formRef.current.checkValidity())
        }
    }
}