import {ErrorInner} from "./ErrorModal";


export default function CommandError({error, title = 'Eroare la executarea acţiunii'} : {error: any, title?: string}){
    return <>
        <div className="callout callout-danger">
            <ErrorInner error={error}/>
        </div>
    </>
}

export function CommandErrorAlert({error, onClose, title='Eroare la executarea acţiunii'}: {error: any, title?: string, onClose: () => void}){
    return <div className="alert alert-danger alert-dismissible">
        <button className="close" type="button" data-dismiss="alert" onClick={onClose}>x</button>
        <h4>
            <i className="icon fa fa-ban"/>
            {title}
        </h4>
        <ErrorInner error={error}/>
    </div>
}