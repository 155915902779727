import ConversationsPannel from "./ConversationsPannel";
import ChatPannel from "./ChatPannel";
import DetailsPannel from "./DetailsPannel";
import {useEffect, useState} from "react";
import {BackendEvent, BlockedConversation, Conversation, EventType, Message} from "../../data/BackendClasses";
import {getJsonFromBackend, postJsonThenJsonToBackend} from "../../data/network.js";
import {ConversationsPage} from "../../data/CustomBackendClasses";


export default function Chat({sseLoginEvent} : {sseLoginEvent: EventSource}) {

    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [totalNumberOfConversations, setTotalNumberOfConversations] = useState<number | undefined>(undefined)
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [lastPage, setLastPage] = useState<boolean>(false);

    const [reload, setReload] = useState<boolean>(false);
    const [reloadBlocked, setReloadBlocked] = useState<boolean>(false);
    const [submitErrors, setSubmitErrors] = useState<any>(null);
    const [currentConversation, setCurrentConversation] = useState<Conversation | undefined>(undefined);
    const [newMessage, setNewMessage] = useState<Message|undefined>(undefined);
    const [blockedConversations, setBlockedConversations] = useState<BlockedConversation[] | undefined>(undefined);

    const [filterByConversationStatus, setFilterByConversationStatus] = useState<string|undefined>(undefined);
    const [filterByConversationReferer, setFilterByConversationReferer] = useState<string|undefined>(undefined);
    const [unreadOnly, setUnreadOnly] = useState<boolean>(false);
    const [blockedOnly, setBlockedOnly] = useState<boolean>(false);
    const [globalSearch, setGlobalSearch] = useState<string | undefined>(undefined);
    const [sortByLastMessageTimeAsc, setSortByLastMessageTimeAsc] = useState<boolean>(true);

    //get conversations
    useEffect(() => {
        setPageNumber(0);
        setConversations([]);

        postJsonThenJsonToBackend("/chat/conversations/get-by-filter?pageNumber=" + 0, {unreadOnly: unreadOnly, blockedOnly: blockedOnly, status: filterByConversationStatus, globalSearch: globalSearch, referer: filterByConversationReferer, sortByLastMessageTimeAsc: sortByLastMessageTimeAsc})
            .then((rsp: ConversationsPage) => {

                setTotalNumberOfConversations(rsp.totalElements)
                setLastPage(rsp.last)
                // setConversations([...conversations,...rsp.content]);
                setConversations(rsp.content);
            })
            .catch(err => {
                setSubmitErrors(err);
            })

    }, [reload, unreadOnly, blockedOnly, filterByConversationStatus, globalSearch, filterByConversationReferer, sortByLastMessageTimeAsc])


    //increase by page number
    useEffect(() => {


        {pageNumber != 0 && postJsonThenJsonToBackend("/chat/conversations/get-by-filter?pageNumber=" + pageNumber, {unreadOnly: unreadOnly, blockedOnly: blockedOnly, status: filterByConversationStatus, globalSearch: globalSearch})
            .then((rsp: ConversationsPage) => {
                if(pageNumber != rsp.number){
                    setPageNumber(rsp.number);
                }
                setLastPage(rsp.last)
                setConversations([...conversations,...rsp.content]);
            })
            .catch(err => {
                setSubmitErrors(err);
            })}

    }, [pageNumber])


    //get blocked conversations
    useEffect(() => {
        getJsonFromBackend("/chat/conversations/blocked")
            .then((rsp: BlockedConversation[]) => {
                setBlockedConversations(rsp);
            })
            .catch(err => {
                setSubmitErrors(err);
            })

    }, [reloadBlocked])


    if(sseLoginEvent){
        sseLoginEvent.onmessage = (event) => {

            let backendEvent : BackendEvent = JSON.parse(event.data);

            if(backendEvent.eventType == EventType.RELOAD_BLOCKED_CONVERSATIONS){
                setBlockedConversations(backendEvent.data);
            }
            if(backendEvent.eventType == EventType.UPDATE_CONVERSATION){
                const newConversation: Conversation = backendEvent.data;

                if(currentConversation && currentConversation.bookingId == newConversation.bookingId){
                    setCurrentConversation(newConversation);
                }
                setReload(!reload);
            }
            if(backendEvent.eventType == EventType.NEW_MESSAGE){
                const newMessage: Message = backendEvent.data;
                setNewMessage(newMessage);
            }
        };


        sseLoginEvent.onerror = (error) => {
            //CONNECTION ERROR
            getJsonFromBackend("/chat/sse-server/error");
        };
    }

    function increasePageNumber() {
        var newPageNumber = pageNumber + 1;
        setPageNumber(newPageNumber);
    }


    return <div className="flex gap-1 mx-auto py-8 px-4 h-full w-full">
        {blockedConversations && <ConversationsPannel
            conversations={conversations}
            currentConversation={currentConversation}
            setCurrentConversation={setCurrentConversation}
            blockedConversations={blockedConversations}
            filterByConversationStatus={filterByConversationStatus}
            setFilterByConversationStatus={setFilterByConversationStatus}
            unreadOnly={unreadOnly}
            setUnreadOnly={setUnreadOnly}
            blockedOnly={blockedOnly}
            setBlockedOnly={setBlockedOnly}
            totalNumberOfConversations={totalNumberOfConversations}
            increasePageNumber={increasePageNumber}
            lastPage={lastPage}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            filterByConversationReferer={filterByConversationReferer}
            setFilterByConversationReferer={setFilterByConversationReferer}
            sortByLastMessageTimeAsc={sortByLastMessageTimeAsc}
            setSortByLastMessageTimeAsc={setSortByLastMessageTimeAsc}
        />}

        {blockedConversations && <ChatPannel
            currentConversation={currentConversation}
            blockedConversation={currentConversation ? blockedConversations.find(item => item.bookingId == currentConversation.bookingId) : undefined}
            newMessage={newMessage}
        />}
        {currentConversation && <DetailsPannel currentConversation={currentConversation}/>}
    </div>
}