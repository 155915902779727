import React, {useContext} from "react";
import {useFormState} from "../data/Form";
import {formHasErrors} from "../lib/helpers";
import {postJsonToBackend} from "../data/network";
import {GlobalContext} from "../data/GlobalContext";
import {isReturnedErrors} from "./ErrorModal";

const initialState = {currentPass: '', newPass: '', retypeNewPass: ''}
export default function ChangePasswordComponent({onSuccess}:{onSuccess: () => void}) {

    const {inputValues, handleInputChange, resetInputValues, dirty} = useFormState(initialState)
    const erori: { [x: string]: string[] } = {currentPass: [], newPass: [], retypeNewPass: []};
    const {showToastMessage} = useContext(GlobalContext);


    function checkForErrors() {
        if (!inputValues.currentPass && dirty) {
            erori.currentPass.push("Current password is mandatory");
        }

        if (!inputValues.newPass && dirty) {
            erori.newPass.push("Field is mandatory");
        }

        if (!inputValues.retypeNewPass && dirty) {
            erori.retypeNewPass.push("Field is mandatory");
        }

        if(inputValues.newPass && inputValues.retypeNewPass && (inputValues.newPass !== inputValues.retypeNewPass)){
            erori.retypeNewPass.push("Field does not match the New Password");
        }
    }

    checkForErrors();

    function submit() {
        postJsonToBackend("/users/change-my-password", {oldPassword: inputValues.currentPass, newPassword: inputValues.newPass})
            .then(rsp => {
                resetInputValues(initialState);
                onSuccess();
            })
            .catch(err => {
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Something went wrong", "error");
                    console.log(err);
                }

            })

    }

    return <div className="flex flex-col">

        <div className="mb-2">
            <label className={`block mb-2 font-medium ${erori.currentPass.length > 0 ? "text-red-500" : "text-black"}`} htmlFor="currentPass">Current Password</label>
            <input className={`custom-form w-full ${erori.currentPass.length > 0 ? " border-solid border-red-500" : ""}`} name="currentPass" id="currentPass" type="password" value={inputValues.currentPass} onChange={handleInputChange}/>
            {erori.currentPass.length > 0 && <div className="text-red-500 text-sm">{erori.currentPass[erori.currentPass.length-1]}</div>}
        </div>

        <div className="mb-2">
            <label className={`block mb-2 font-medium ${erori.newPass.length > 0 ? "text-red-500" : "text-black"}`} htmlFor="newPass">New Password</label>
            <input className={`custom-form w-full ${erori.newPass.length > 0 ? " border-solid border-red-500" : ""}`} name="newPass" id="newPass" type="password" value={inputValues.newPass} onChange={handleInputChange}/>
            {erori.newPass.length > 0 && <div className="text-red-500 text-sm">{erori.newPass[erori.newPass.length-1]}</div>}
        </div>

        <div className="mb-2">
            <label className={`block mb-2 font-medium ${erori.retypeNewPass.length > 0 ? "text-red-500" : "text-black"}`} htmlFor="retypeNewPass">Retype New Password</label>
            <input className={`custom-form w-full ${erori.retypeNewPass.length > 0 ? " border-solid border-red-500" : ""}`} name="retypeNewPass" id="retypeNewPass" type="password" value={inputValues.retypeNewPass} onChange={handleInputChange}/>
            {erori.retypeNewPass.length > 0 && <div className="text-red-500 text-sm">{erori.retypeNewPass[erori.retypeNewPass.length-1]}</div>}
        </div>


        {!formHasErrors(erori) && <div className="text-center">
            <button
                className="w-fit text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white focus:ring-4 font-medium rounded-lg text-xs px-5 py-2.5 text-center mt-2"
                onClick={submit}
            >
                Change

            </button>
        </div>}


    </div>

}