import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {CancellationBooking} from "../../data/BackendClasses";
import {GlobalContext} from "../../data/GlobalContext";
import {createPaginationParams, getJsonFromBackend} from "../../data/network";
import {formatDate} from "../../lib/helpers";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Skeleton} from "primereact/skeleton";


export default function CancellationDataTable() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [page, setPage] = useState<number>(0);

    const [data, setData] = useState<CancellationBooking[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);


    useEffect(() => {

        console.log("USE EFFECT CANCELLATION DATA TABLE")

        getJsonFromBackend("/cancellation/data" + createPaginationParams(page, pageSize))
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting cancellation data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload, page, pageSize])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setPage(0);
            setFirst(0);
        }, 10000);

        return () => clearInterval(intervalId);
    }, []);

    function onPageChanged(e: any) {

        console.log("ON PAGE CHANGED")

        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-between">
                <div className="flex align-items-center justify-content-end gap-2">
                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>

                </div>
            </div>
        );
    };

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };


    function renderDateColumn(data: CancellationBooking){
        return formatDate(data.processedTimestamp);
    }



    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md"}
                    }}
                    header= {renderHeader()}
                >

                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="bookingId"
                        header="Booking ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="airbnbId"
                        header="Airbnb ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="processedTimestamp"
                        header="Processed Timestamp"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="processed"
                        header="Processed"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="reason"
                        header="Reason"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="check_out"
                        header="Check Out"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />


                </DataTable>
                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white"},
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>


    </div>

}