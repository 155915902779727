import {Dialog} from "primereact/dialog";
import React from "react";


export default function LegendDialog({showLegendDialog, onClose}:{showLegendDialog: boolean, onClose: () => void}){
    return <Dialog
        header={"Legend"}
        visible={showLegendDialog}
        style={{ width: '30vw' }}
        onHide={onClose}
    >

        <div className="flex flex-row">
            <div className="flex flex-col gap-2 w-1/2">
                <div className="flex flex-row gap-2">
                    <i className="fa-solid fa-square text-[#7bbf77] my-auto mr-2"/>
                    Room price
                </div>
                <div className="flex flex-row gap-2">
                    <i className="fa-solid fa-square text-yellow-500 my-auto mr-2"/>
                    Modified value
                </div>
                <div className="flex flex-row gap-2">
                    <i className="fa-solid fa-square text-[#7bbf77] my-auto mr-2 opacity-50"/>
                    Room price linked
                </div>
            </div>

            <div className="flex flex-col gap-2 w-1/2">
                <div className="flex flex-row gap-2">
                    <i className="fa-solid fa-square text-blue-100 my-auto mr-2 "/>
                    Weekend
                </div>
                <div className="flex flex-row gap-2">
                    <i className="fa-solid fa-square text-pink-100 my-auto mr-2 "/>
                    Minimum checkin days
                </div>
                <div className="flex flex-row gap-2">
                    {/*<i className="fa-solid fa-square text-red-500 my-auto mr-2"/>*/}
                    <span className="bg-black text-white px-1 rounded">Blackout</span>
                    Closed room
                </div>
            </div>
        </div>


    </Dialog>
}