import ConversationCallout from "./ConversationCallout";
import {BlockedConversation, Conversation} from "../../data/BackendClasses";
import {useEffect, useState} from "react";
import {getJsonFromBackend} from "../../data/network";
import {useCurrentUser} from "../../data/user";


export default function ConversationsPannel(
    {
        conversations,
        currentConversation,
        setCurrentConversation,
        blockedConversations,
        filterByConversationStatus,
        setFilterByConversationStatus,
        filterByConversationReferer,
        setFilterByConversationReferer,
        blockedOnly,
        setBlockedOnly,
        unreadOnly,
        setUnreadOnly,
        totalNumberOfConversations,
        increasePageNumber,
        lastPage,
        globalSearch,
        setGlobalSearch,
        sortByLastMessageTimeAsc,
        setSortByLastMessageTimeAsc
    } : {
        conversations: Conversation[],
        currentConversation: Conversation | undefined,
        setCurrentConversation: (item: Conversation) => void,
        blockedConversations: BlockedConversation[],
        filterByConversationStatus: string | undefined,
        setFilterByConversationStatus: (item: string) => void,
        filterByConversationReferer: string | undefined,
        setFilterByConversationReferer: (item: string) => void,
        blockedOnly: boolean,
        setBlockedOnly: (status: boolean) => void,
        unreadOnly: boolean,
        setUnreadOnly: (status: boolean) => void,
        totalNumberOfConversations: number | undefined,
        increasePageNumber: () => void,
        lastPage: boolean,
        globalSearch: string | undefined,
        setGlobalSearch: (item: string | undefined) => void,
        sortByLastMessageTimeAsc: boolean,
        setSortByLastMessageTimeAsc: (status: boolean) => void,
    }
) {

    const [conversationStatuses, setConversationStatuses] = useState<string[]|undefined>(undefined);
    const [conversationReferer, setConversationReferer] = useState<string[]|undefined>(undefined);
    const [internalGlobalSearch, setInternalGlobalSearch] = useState<string | undefined>(globalSearch);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showRecentConversations, setShowRecentConversations] = useState<boolean>(false);
    const [recentConversations, setRecentConversations] = useState<Conversation[]>([]);

    useEffect(() => {

        const updateState = () => {
            setGlobalSearch(internalGlobalSearch);
        };

        const delayTimer = setTimeout(updateState, 700);

        return () => clearTimeout(delayTimer);

    }, [internalGlobalSearch])


    useEffect(() => {
        getJsonFromBackend("/chat/conversations/statuses")
            .then(rsp => setConversationStatuses(rsp))

        getJsonFromBackend("/chat/conversations/referer")
            .then(rsp => setConversationReferer(rsp))
    }, [])

    const currentUser = useCurrentUser();

    function changeFilterByStatus(e: any){
        e.preventDefault();
        setFilterByConversationStatus(e.target.value);
    }

    function changeFilterByReferer(e: any){
        e.preventDefault();
        setFilterByConversationReferer(e.target.value);
    }

    function numberOfFilters(){
        var nr = 0;
        if(blockedOnly){
            nr++;
        }
        if(unreadOnly){
            nr++;
        }
        if(filterByConversationStatus != undefined && filterByConversationStatus != ""){
            nr++;
        }

        if(filterByConversationReferer != undefined && filterByConversationReferer != ""){
            nr++;
        }

        return nr;
    }



    return <div className="flex h-full w-1/4 rounded-lg shadow-md">
        <div className="flex flex-col w-full">
            {!showRecentConversations ? <AllConversations/> : <RecentConversations/>}
        </div>
    </div>


    function AllConversations() {
        return <>
            <div className="pl-2 pr-6 py-4 bg-white/95 flex flex-col rounded-t-lg">
                {/*FILTER INPUT*/}
                <div className="flex flex-row">
                    <div className="flex flex-col content-center justify-center mr-1">
                        {(recentConversations.length > 0) && <i
                            className="fa-solid fa-clock-rotate-left hover:cursor-pointer"
                            title="recent conversations"
                            onClick={() => setShowRecentConversations(prevState => true)}
                        />}
                    </div>
                    <input
                        type="text"
                        placeholder="filter"
                        className="custom-form grow"
                        value={internalGlobalSearch}
                        onChange={(e: any) => (setInternalGlobalSearch(e.target.value))}
                    />
                    <div className="flex flex-col content-center justify-center">
                        <i
                            className={`fa-solid fa-arrow-down-short-wide fa-lg ml-2 hover:cursor-pointer relative ${showFilters ? "text-blue-500" : ""}`}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {(blockedOnly || unreadOnly || filterByConversationStatus) &&
                            <span className="text-xs absolute bottom-0 right-[-1]">{numberOfFilters()}</span>}
                        </i>
                    </div>
                </div>

                <div className={`${showFilters ? "" : "hidden"}`}>
                    <div className="flex mt-2">
                        <div className="w-1/2">
                            Blocked by me only
                            <input className="ml-2" type="checkbox" checked={blockedOnly}
                                   onChange={() => setBlockedOnly(!blockedOnly)}/>
                        </div>
                        <div className="w-1/2 text-right">
                            Unanswered only
                            <input className="ml-2" type="checkbox" checked={unreadOnly}
                                   onChange={() => setUnreadOnly(!unreadOnly)}/>
                        </div>
                    </div>

                    <div className="flex mt-2 gap-2">
                        {conversationStatuses &&
                        <select className="custom-form w-1/2" value={filterByConversationStatus}
                                onChange={changeFilterByStatus}>
                            <option value="">Filter by status</option>
                            {conversationStatuses.map(status => <option value={status} key={status}>{status}</option>)}
                        </select>}

                        {conversationReferer &&
                        <select className="custom-form w-1/2" value={filterByConversationReferer}
                                onChange={changeFilterByReferer}>
                            <option value="">Filter by referer</option>
                            {conversationReferer.map(referer => <option value={referer}
                                                                        key={referer}>{referer}</option>)}
                        </select>}
                    </div>
                    <div className="flex mt-2">
                        <div className="w-1/2">
                            Oldest message first
                            <input className="ml-2" type="checkbox" checked={sortByLastMessageTimeAsc}
                                   onChange={() => setSortByLastMessageTimeAsc(!sortByLastMessageTimeAsc)}/>
                        </div>
                        <div className="w-1/2 text-right mt-auto">
                            {totalNumberOfConversations && <span
                                className="text-xs text-right bg-white rounded p-1">{totalNumberOfConversations}</span>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col flex-auto flex-grow bg-white h-full overflow-y-auto px-1 pt-1 rounded-b-lg">
                {conversations.map(item => {return <ConversationCallout
                    key={item.bookingId}
                    conversation={item}
                    isActive={currentConversation ? currentConversation.bookingId == item.bookingId : false}
                    setCurrentConversation={setCurrentConversation}
                    blockedConversations={blockedConversations}
                    addRecentConversation={() => {
                        addRecentConversation(item);
                    }}
                />})}

                {!lastPage && <>
                    <div className="w-full text-center">
                        <button className="text-blue-500 border border-blue-500 rounded p-1" onClick={increasePageNumber}>Load more</button>
                    </div>
                </>}

            </div>
        </>
    }


    function addRecentConversation(conversation: Conversation) {
        console.log("addRecentConversation ", [...recentConversations, conversation])

        if(recentConversations.find(item => item.bookingId == conversation.bookingId) != undefined)
            return;
        else {
            if(recentConversations.length == 10){
                recentConversations.pop();
            }

            recentConversations.push(conversation);
            let _recentConversations = [...recentConversations];
            _recentConversations.reverse();
            setRecentConversations(prevState => _recentConversations);
        }
    }

    function RecentConversations() {
        return <>
            <div className="pl-2 pr-6 py-4 bg-white/95 flex flex-row rounded-t-lg content-center">
                <div
                    className="w-fit hover:cursor-pointer hover:text-blue-500"
                    onClick={() => setShowRecentConversations(prevState => false)}
                >
                    <i className="fa-solid fa-arrow-left-long my-auto mr-1"/>
                    All conversations
                </div>
            </div>

            <div className="flex flex-col flex-auto flex-grow bg-white h-full overflow-y-auto px-1 pt-1 rounded-b-lg">
                {recentConversations.map(item => {return <ConversationCallout
                    key={item.bookingId}
                    conversation={item}
                    isActive={currentConversation ? currentConversation.bookingId == item.bookingId : false}
                    setCurrentConversation={setCurrentConversation}
                    blockedConversations={blockedConversations}
                    addRecentConversation={() => {}}
                />})}
            </div>

        </>

    }

}