import {Role} from "../data/BackendClasses";


export default function NotAllowed({requiredRoles} : {requiredRoles: Role[]}) {


    function renderRequiredRoles() {
        return (
            <>
                {requiredRoles.map((role, index) => (
                    <span
                        key={index}
                        className="font-bold"
                    >
                        "{role}"
                    </span>
                ))}
            </>
        );
    }

    return <div className="container mx-auto py-8 px-4 h-full flex flex-col content-center justify-center">
        <div className="w-fit p-4 bg-red-500/70 h-1/2 backdrop-blur rounded-xl m-auto">
            <div className="h-1/2 flex flex-col grid content-end">
                <i className="fa-solid fa-triangle-exclamation fa-4x align-baseline m-auto"/>
            </div>
            <div className="h-1/2 flex flex-col justify-center content-center break-keep">
                <span className="font-semibold">You need one of the following roles: {renderRequiredRoles()} to access this page</span>
            </div>
        </div>

    </div>

}