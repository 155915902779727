import {classNames} from "primereact/utils";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {DerbysoftHistory} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import {createPaginationParams, getJsonFromBackend} from "../../../data/network";
import {InputText} from "primereact/inputtext";
import {formatDate} from "../../../lib/helpers";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Skeleton} from "primereact/skeleton";

export default function DerbysoftHistoryPage() {

    const tableClassNames = classNames('w-full !rounded-md', {});
    const rowClass = (data: any) => {
        return {
            '!bg-transparent': true
        };
    };
    // @ts-ignore
    const dt = useRef<DataTable>(null);

    const [first, setFirst] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

    const [data, setData] = useState<DerbysoftHistory[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<string>("");
    const [dialogHeader, setDialogHeader] = useState<string>("");

    const [debouncedValue, setDebouncedValue] = useState('');
    const [completedFilter, setCompletedFilter] = useState<string>("nothing");


    useEffect(() => {
        getJsonFromBackend("/derbysoft-history/get" + createPaginationParams(page, pageSize) + "&id=" + debouncedValue + "&completedFilter=" + completedFilter.toUpperCase())
            .then((rsp) => {
                setTotalElements(rsp.totalElements)
                setData(rsp.content);
                setDataLoaded(true);
            })
            .catch(err => {
                showToastMessage("ERROR", "Error getting Derbysoft Logs data", "error");
                setDataLoaded(true);
                console.log(err)
            })
    }, [reload, page, pageSize, debouncedValue, completedFilter])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(globalFilterValue)
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [globalFilterValue]);

    const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    function onPageChanged(e: any) {
        if(pageSize != e.rows)
            setPageSize(e.rows);
        if(first != e.first){
            setFirst(e.first);
        }
        if(page != e.page){
            setPage(e.page)
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex flex-row justify-between">
                <span className="p-input-icon-left">
                    <i className="fa fa-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search by booking ID" />
                </span>
                <div className="flex flex-row gap-1">

                    <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2"
                        onClick={() => exportCSV(false)}
                    >
                        <i className="fa-solid fa-file-csv fa-xl"/>
                    </button>

                    <select
                        className="custom-form text-center"
                        name="completed"
                        value={completedFilter}
                        onChange={(e) => {
                            e.preventDefault();
                            setCompletedFilter(e.target.value);
                        }}
                    >
                        <option value="nothing">Filter by completed</option>
                        <option className="font-bold text-lg" value="null">&#63;</option>
                        <option className="font-bold" value="true">&#x2713;</option>
                        <option className="font-bold" value="false">&#x2715;</option>
                    </select>
                </div>
            </div>
        );
    };

    const exportCSV = (selectionOnly: boolean) => {
        dt.current.exportCSV({ selectionOnly });
    };



    function renderCompleted(data: DerbysoftHistory){
        return <div>
            {undefined == data.completed ? <i className="fa-solid fa-question"/> : (data.completed ? <i className="fa-solid fa-check"/> : <i className="fa-solid fa-times"/>)}
        </div>
    }

    function renderDateColumn(data: DerbysoftHistory){
        return data.receivedTimestamp ? formatDate(data.receivedTimestamp) : "-";
    }


    return <div className="flex gap-1 mx-auto pt-8 pb-4 px-4 w-full h-full" id="drop-down-page">
        <div className="flex flex-col h-fit !rounded-lg bg-white p-1 grow">
            {dataLoaded ? <>
                <DataTable
                    ref={dt}
                    value={data}
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    stripedRows
                    emptyMessage= "No data found."
                    tableStyle={{ minWidth: '50rem' }}
                    rowClassName={rowClass}
                    className={tableClassNames}
                    pt={{
                        header: {className: "!bg-white !border-0 !rounded-t-md"},
                    }}
                    header= {renderHeader()}
                >

                    <Column
                        className="!bg-white "
                        field="id"
                        header="ID"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="typeBooking"
                        header="Booking Type"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="property"
                        header="Property"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />
                    <Column
                        className="!bg-white "
                        field="bookingId"
                        header="Booking Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="receivedTimestamp"
                        header="Received"
                        body={renderDateColumn}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="completed"
                        header="Completed"
                        body={renderCompleted}
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="derbyResId"
                        header="Derby Res Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                    <Column
                        className="!bg-white "
                        field="supplierResId"
                        header="Supplier Res Id"
                        pt={{
                            headerTitle: {className: "!font-bold"}
                        }}
                    />

                </DataTable>
                <Paginator
                    first={first}
                    rows={pageSize}
                    totalRecords={totalElements}
                    onPageChange={onPageChanged}
                    template="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} -> {last} of {totalRecords}"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    pt={{
                        root: {className: "!bg-white"},
                    }}
                />
            </> : <Skeleton className="h-72"/>}
        </div>

    </div>

}