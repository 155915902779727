import {useState} from "react";
import {Dialog, DialogContent, IconButton} from "@material-ui/core";
import {Message} from "../../data/BackendClasses";
import {formatDate} from "../../lib/helpers";
import Markdown from 'react-markdown';

export function GuestChatMessage({message} : {message: Message}) {
    return <div className="flex flex-col p-3 rounded-lg">
        <div className="text-xs bottom-0 right-0 text-gray-500 mb-1 ml-1">
            {formatDate(message.time)}
        </div>
        <div className="flex items-center">
            <div className="relative text-sm bg-white py-2 px-4 shadow rounded-xl">
                <div className="whitespace-pre-wrap">
                    {message.imageUrl && <ImageThumbnail imageUrl={message.imageUrl}/>}
                    {!message.imageUrl && <Markdown>{message.message}</Markdown>}
                </div>
            </div>
        </div>
    </div>
}



export function HostChatMessage({message} : {message: Message}) {
    return <div className="flex flex-col p-3 rounded-lg">
        <div className="text-xs bottom-0 right-0 text-gray-500 text-right mb-1 mr-1">
            {formatDate(message.time)}
        </div>
        <div className="flex justify-start flex-row-reverse">
            <div className="text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                <div className="whitespace-pre-wrap">
                    {message.imageUrl && <ImageThumbnail imageUrl={message.id != 0 ? message.imageUrl : "https://rocketbox.s3.amazonaws.com/5326551.png?AWSAccessKeyId=AKIASZWBPV43VF6SMY2V&Signature=LMdA6xhf5dlLEKngexeL25a4xTI%3D&Expires=1730495201"}/>}
                    {!message.imageUrl && <Markdown>{message.message}</Markdown>}
                </div>
            </div>
        </div>
        {message.id == 0 && <div className="text-xs bottom-0 right-0 text-gray-500 text-right mt-1 mr-1">
            Sending...
        </div>}

    </div>
}


function ImageThumbnail({ imageUrl } : {imageUrl: string}) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <img
                src={imageUrl}
                alt="Thumbnail"
                onClick={handleOpen}
                style={{ cursor: 'pointer', maxHeight: "150px" }}
            />
            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: 'absolute', top: '8px', right: '8px' }}
                    >
                        {/*<Close/>*/}
                    </IconButton>
                    <img src={imageUrl} alt="Full Resolution" style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>
        </div>
    );
}