import {Card} from "primereact/card";
import "./CloudbedsCalendarPage.scss";
import React, {useContext, useEffect, useState} from "react";
import {Calendar, CalendarChangeEvent, CalendarDateTemplateEvent} from "primereact/calendar";
import {createQueryString, getJsonFromBackend, postJsonThenJsonToBackend} from "../../../data/network";
import {Hotel, ModifyErrorResponse, ModifyResponse, Role} from "../../../data/BackendClasses";
import {GlobalContext} from "../../../data/GlobalContext";
import ChangePriceDialog from "./ChangePriceDialog";
import ChangeInventoryDialog from "./ChangeInventoryDialog";
import ChangeMultiplierDialog from "./ChangeMultiplierDialog";
import ChangeMinStayDialog from "./ChangeMinStayDialog";
import ChangeMaxStayDialog from "./ChangeMaxStayDialog";
import ChangeOverrideDialog from "./ChangeOverrideDialog";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {esteDeTipulDate, getMonthName, isDate} from "../../../lib/helpers";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {Dialog, DialogPassThroughOptions} from "primereact/dialog";
import {ProgressBar} from "primereact/progressbar";
import {useCurrentUser} from "../../../data/user";
import LoadingOverlay from "../../../components/LoadingOverlay";
import BulkUpdateDialog, {BulkUpdate, Room} from "./BulkUpdateDialog";
import CloudbedsCalendarTable from "./CloudbedsCalendarTable";
import LegendDialog from "./LegendDialog";


export interface RoomData {
    roomId: number,
    propertyId: number,
    name: string,
    linked: number,
    qty: number,
    calendar: CalendarData[],
    min_check_in: number,
    currency_hotel: string
}

export interface CalendarData {
    from: string,
    to: string,
    numAvail: number,
    override: string,
    multiplier: number,
    price1: number
    minStay: number,
    maxStay: number
}


export default function CloudbedsCalendarPage() {

    const currentUser = useCurrentUser();

    const [data, setData] = useState<RoomData[]>([]);

    const [startDate, setStartDate] = useState<string | Date | Date[] | undefined | null>(new Date());
    const [endDate, setEndDate] = useState<string | Date | Date[] | undefined | null>(getInitialEndDate());
    const [hotels, setHotels] = useState<Hotel[]>([]);
    const [selectedHotel, setSelectedHotel] = useState<number>(0);
    const [reload, setReload] = useState<boolean>(false);

    const {showToastMessage} = useContext(GlobalContext);

    const [changeDialogStartDate, setChangeDialogStartDate] = useState<string|undefined>(undefined);
    const [changeDialogRoom, setChangeDialogRoom] = useState<RoomData|undefined>(undefined);

    const [showChangePriceDialog, setShowChangePriceDialog] = useState<boolean>(false);
    const [changePriceDialogValue, setChangePriceDialogValue] = useState<number|undefined>(undefined);
    const [newPricesMap, setNewPricesMap] = useState<Map<string, number>>(new Map<string, number>());

    const [showChangeInventoryDialog, setShowChangeInventoryDialog] = useState<boolean>(false);
    const [changeInventoryDialogValue, setChangeInventoryDialogValue] = useState<number|undefined>(undefined);
    const [newInventoryMap, setNewInventoryMap] = useState<Map<string, number>>(new Map<string, number>());

    const [showChangeMultiplierDialog, setShowChangeMultiplierDialog] = useState<boolean>(false);
    const [changeMultiplierDialogValue, setChangeMultiplierDialogValue] = useState<number|undefined>(undefined);
    const [newMultiplierMap, setNewMultiplierMap] = useState<Map<string, number>>(new Map<string, number>());

    const [showChangeMinStayDialog, setShowChangeMinStayDialog] = useState<boolean>(false);
    const [changeMinStayDialogValue, setChangeMinStayDialogValue] = useState<number|undefined>(undefined);
    const [newMinStayMap, setNewMinStayMap] = useState<Map<string, number>>(new Map<string, number>());

    const [showChangeMaxStayDialog, setShowChangeMaxStayDialog] = useState<boolean>(false);
    const [changeMaxStayDialogValue, setChangeMaxStayDialogValue] = useState<number|undefined>(undefined);
    const [newMaxStayMap, setNewMaxStayMap] = useState<Map<string, number>>(new Map<string, number>());

    const [showChangeOverrideDialog, setShowChangeOverrideDialog] = useState<boolean>(false);
    const [changeOverrideDialogValue, setChangeOverrideDialogValue] = useState<string|undefined>(undefined);
    const [newOverrideMap, setNewOverrideMap] = useState<Map<string, string>>(new Map<string, string>());

    const [dirty, setDirty] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const fields: string[] = currentUser.roles.includes(Role.CLOUDBEDS_MASTER) ? ["room", "inventory", "multiplier", "price", "min stay", "max stay", "override"] : ["room", "price"];

    const [selectedFields, setSelectedFields] = useState(fields);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<any>("");
    const [dialogHeader, setDialogHeader] = useState<string>("");
    const [dialogPassThrough, setDialogPassThrough] = useState< DialogPassThroughOptions | undefined>(undefined);

    const [showBulkUpdate, setShowBulkUpdate] = useState<boolean>(false);

    const [showLegendDialog, setShowLegendDialog] = useState<boolean>(false);


    useEffect(() => {
        if(selectedHotel != 0) {
            getBeds24CalendarData()
        }
    }, [reload])

    useEffect(() => {
        getJsonFromBackend("/hotels/get-my-hotels-with-property-id")
            .then((rsp: Hotel[]) => {
                setHotels(rsp);
            })
            .catch(err => {
                showToastMessage("Error", "Error getting the data about the hotels", "error");
                console.log(err);
            })
    }, [])

    function changeStartDate(e: CalendarChangeEvent) {
        setStartDate(prevState => e.value);
        setDirty(prevState => true);
    }

    function changeEndDate(e: CalendarChangeEvent) {
        setEndDate(prevState => e.value);
        setDirty(prevState => true);
    }

    function getInitialEndDate() : Date {
        const today = new Date();
        return new Date(today.setDate(today.getDate() + 30));
    }

    function handleHotelChanged(e: any){
        setSelectedHotel(prevState => e.target.value);
        setDirty(prevState => true);
    }

    function changePrices(roomIds:number[], dates: string[], newPrice: number){

        const updatedMap = new Map<string, number>(newPricesMap);
        dates.forEach(date => {
            roomIds.forEach(roomId => {
                updatedMap.set(JSON.stringify([roomId, date]), newPrice);
            })
        })

        setNewPricesMap((prevState) => (updatedMap));
    }

    function changeInventory(roomIds:number[], dates: string[], newInventoryValue: number){

        const updatedMap = new Map<string, number>(newInventoryMap);
        dates.forEach(date => {
            roomIds.forEach(roomId => {
                updatedMap.set(JSON.stringify([roomId, date]), newInventoryValue);
            })
        })

        setNewInventoryMap((prevState) => (updatedMap));
    }

    function changeMultiplier(roomIds:number[], dates: string[], newMultiplierValue: number){

        const updatedMap = new Map<string, number>(newMultiplierMap);
        dates.forEach(date => {
            roomIds.forEach(roomId => {
                updatedMap.set(JSON.stringify([roomId, date]), newMultiplierValue);
            })
        })

        setNewMultiplierMap((prevState) => (updatedMap));
    }

    function changeMinStay(roomIds:number[], dates: string[], newMinStayValue: number){

        const updatedMap = new Map<string, number>(newMinStayMap);
        dates.forEach(date => {
            roomIds.forEach(roomId => {
                updatedMap.set(JSON.stringify([roomId, date]), newMinStayValue);
            })
        })

        setNewMinStayMap((prevState) => (updatedMap));
    }

    function changeMaxStay(roomIds:number[], dates: string[], newMaxStayValue: number){

        const updatedMap = new Map<string, number>(newMaxStayMap);
        dates.forEach(date => {
            roomIds.forEach(roomId => {
                updatedMap.set(JSON.stringify([roomId, date]), newMaxStayValue);
            })
        })

        setNewMaxStayMap((prevState) => (updatedMap));
    }

    function changeOverride(roomIds:number[], dates: string[], newOverrideValue: string){

        const updatedMap = new Map<string, string>(newOverrideMap);
        dates.forEach(date => {
            roomIds.forEach(roomId => {
                updatedMap.set(JSON.stringify([roomId, date]), newOverrideValue);
            })
        })

        setNewOverrideMap((prevState) => (updatedMap));
    }

    function getBeds24CalendarData(){
        setLoading(prevState => true)

        const queryParamsObject = {
            hotelId: selectedHotel,
            start_date: esteDeTipulDate(startDate) ? startDate.getTime() : null,
            end_date: esteDeTipulDate(endDate) ? endDate.getTime() : null
        }

        getJsonFromBackend("/cloudbeds-calendar/get?"+createQueryString(queryParamsObject))
            .then((res : RoomData[]) => {
                console.log("RES ", res);
                setLoading(prevState => false);
                setData(prevState => res);
                resetStates();
            })
            .catch(err => {
                console.log("ERR ", err);
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Error getting the data", "error");
                }
                setLoading(prevState => false);
                resetStates();
            })
    }

    function resetStates() {
        setDirty(prevState => false);
        setNewPricesMap(prevState => new Map<string, number>());
        setNewInventoryMap(prevState => new Map<string, number>());
        setNewMultiplierMap(prevState => new Map<string, number>());
        setNewMinStayMap(prevState => new Map<string, number>());
        setNewMaxStayMap(prevState => new Map<string, number>());
        setNewOverrideMap(prevState => new Map<string, string>());
    }

    function checkIfThereAreChanges() : boolean {
        return newPricesMap.size > 0 || newOverrideMap.size > 0 || newMinStayMap.size > 0 || newMaxStayMap.size > 0 || newMultiplierMap.size > 0 || newInventoryMap.size > 0;
    }

    function serializeNumberMap(priceMap: Map<String, number>) {
        const serializedArray = [];
        // @ts-ignore
        for (const [key, value] of priceMap.entries()) {
            const [roomId, date] = JSON.parse(key);
            serializedArray.push({ roomId, date, value });
        }
        return serializedArray;
    }

    function serializeStringMap(priceMap: Map<String, string>) {
        const serializedArray = [];
        // @ts-ignore
        for (const [key, value] of priceMap.entries()) {
            const [roomId, date] = JSON.parse(key);
            serializedArray.push({ roomId, date, value });
        }
        return serializedArray;
    }

    function applyChanges() {


        const changes = {
            "property": selectedHotel,
            // @ts-ignore
            "propertyName": hotels.find(hotel => hotel.id == selectedHotel).name,
            "prices": serializeNumberMap(newPricesMap),
            "inventory": serializeNumberMap(newInventoryMap),
            "multiplier": serializeNumberMap(newMultiplierMap),
            "minStay": serializeNumberMap(newMinStayMap),
            "maxStay": serializeNumberMap(newMaxStayMap),
            "override": serializeStringMap(newOverrideMap),
            "rooms": data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))
        }

        setLoading(prevState => true);


        postJsonThenJsonToBackend("/cloudbeds-calendar/modify", changes)
                .then((rsp: ModifyResponse | ModifyErrorResponse) => {

                    if(isModifyResponse(rsp)){
                        if(rsp.errors.length > 0 || rsp.warnings.length > 0){
                            showToastMessage("Feedback", JSON.stringify(rsp), "info");
                        } else {
                            showToastMessage("Feedback", JSON.stringify(rsp), "success");
                        }

                        setReload(prevState => !prevState);
                    }

                    if(isModifyErrorResponse(rsp)){
                        showToastMessage("Error", JSON.stringify(rsp), "error");
                    }

                    setLoading(prevState => false);

                })
                .catch(err=> {
                    if(isReturnedErrors(err)){
                        showToastMessage("Error", err.errors[0].message, "error");
                    } else {
                        showToastMessage("Error", "Sorry, something went bad", "error");
                    }

                    setLoading(prevState => false)

                })

        }

    function isModifyResponse(rsp: any): rsp is ModifyResponse {
        return typeof rsp == "object" && "success" in rsp && "warnings" in rsp && "errors" in rsp;
    }

    function isModifyErrorResponse(rsp: any): rsp is ModifyErrorResponse {
        return typeof rsp == "object" && "code" in rsp && "error" in rsp && "message" in rsp && "success" in rsp && "type" in rsp;
    }

    function hideDialog(){
        setDialogContent("");
        setDialogHeader("");
        setShowDialog(false);
    }


    function getDateCardHeaderBgColor(date: string) : string {
        if(new Date(date).getDay() == 0 || new Date(date).getDay() == 6)
            return "!bg-blue-100";

        return "!bg-white";
    }

    const customDateTemplate = (date: CalendarDateTemplateEvent) => {
        const jsDate = new Date(date.year, date.month, date.day);

        const dayOfWeek = jsDate.getDay();

        if(dayOfWeek === 6 || dayOfWeek === 0)
            return <div className={"weekend"}>{date.day}</div>;
        else
            return date.day;
    };

    function onApplyBulkUpdate(field: string, fieldValue: string, updates: BulkUpdate[], rooms: Room[]){
        if(field == "override") {
            const updatedMap = new Map<string, string>(newOverrideMap);

            updates.forEach(update => {
                update.dates.forEach(date => {
                    if(dateToString(date) != dateToString(new Date())){
                        rooms.forEach(room => {
                            updatedMap.set(JSON.stringify([room.roomId, dateToString(date)]), fieldValue);
                        })
                    }
                })
            })

            setNewOverrideMap((prevState) => (updatedMap));
            setShowBulkUpdate(prevState => false);

        } else {
            const selectedMap = getCorrespondingMapForBulkUpdate(field);

            if(selectedMap) {

                const updatedMap = new Map<string, number>(selectedMap);

                updates.forEach(update => {
                    update.dates.forEach(date => {
                        if(dateToString(date) != dateToString(new Date())){
                            if(field == "price"){
                                rooms.filter(room => room.linked == 0).forEach(room => {
                                    updatedMap.set(JSON.stringify([room.roomId, dateToString(date)]), parseFloat(fieldValue));
                                })
                            } else {
                                rooms.forEach(room => {
                                    updatedMap.set(JSON.stringify([room.roomId, dateToString(date)]), parseFloat(fieldValue));
                                })
                            }
                        }
                    })
                })
                setCorrespondingMapForBulkUpdate(field, updatedMap);
                setShowBulkUpdate(prevState => false);
            } else {
                //todo show error message
            }
        }
    }

    function dateToString(date: Date) : string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function setCorrespondingMapForBulkUpdate(field: String, updatedMap: Map<string, number>) {
        switch (field) {
            case "inventory" :
                setNewInventoryMap(prevState => updatedMap);
                break;
            case "multiplier" :
                setNewMultiplierMap(updatedMap);
                break;
            case "price" :
                setNewPricesMap(updatedMap);
                break;
            case "min stay"  :
                setNewMinStayMap(updatedMap);
                break;
            case "max stay" :
                setNewMaxStayMap(updatedMap);
                break;
            default :
                showToastMessage("Error ", "Something went wrong", "error");
                break;
        }
    }

    function getCorrespondingMapForBulkUpdate(field: String) : Map<string, number> | undefined {
        switch (field) {
            case "inventory" :
                return newInventoryMap;
                break;
            case "multiplier" :
                return newMultiplierMap;
                break;
            case "price" :
                return newPricesMap;
                break;
            case "min stay"  :
                return newMinStayMap;
                break;
            case "max stay" :
                return newMaxStayMap;
                break;
            default:
                return undefined;
        }
    }

    return <div className="flex flex-col min-h-full bg-white beds-24-calendar-page overflow-x-scroll">
        <div className="flex flex-col md:flex-row w-full gap-4 bg-white pt-5 pl-4 mt-1 min-w-fit">
            <div className="flex flex-col">
                <label className="font-medium text-blue-500" htmlFor="start-date">Start Date</label>
                <Calendar
                    id="calendar-24h"
                    value={startDate}
                    onChange={(e) => changeStartDate(e)}
                    showIcon
                    showButtonBar
                    view="date"
                    // minDate={getMinStartDate()}
                    minDate={new Date()}
                    dateTemplate={customDateTemplate}
                    pt={{
                        day:{className: "!m-[1px] !p-[1px]"},
                        container: {className: "!p-[2px]"}
                    }}
                />
            </div>

            <div className="flex flex-col">
                <label className="font-medium text-blue-500" htmlFor="end-date">End Date</label>
                <Calendar
                    id="calendar-24h"
                    value={endDate}
                    onChange={(e) => changeEndDate(e)}
                    showIcon
                    showButtonBar
                    view="date"
                    dateTemplate={customDateTemplate}
                    pt={{
                        day:{className: "!m-[1px] !p-[1px]"},
                        container: {className: "!p-[2px]"}
                    }}
                />
            </div>

            {hotels && hotels.length > 0 && <div className="flex flex-col max-w-[150px]">
                <label className="font-medium text-blue-500" htmlFor="selected-hotel">Hotel</label>
                <select
                    className={`custom-form rounded-lg block 2.5`}
                    name="selected-hotel"
                    id="selected-hotel"
                    value={selectedHotel}
                    onChange={handleHotelChanged}
                >
                    <option key={0} value={0}>Select a hotel</option>
                    {hotels.map(hotel => <option key={hotel.id} value={hotel.id}>{hotel.name}</option>)}

                </select>
            </div>}

            <div className="flex flex-row gap-2">
                { dirty && startDate && endDate && selectedHotel != 0 && (startDate < endDate) && <div className="flex flex-col">
                    <button
                        className="text-white py-2 px-3 bg-blue-500 border border-blue-500 rounded hover:text-white hover:bg-blue-600 hover:border-blue-600 mt-auto w-fit text-xs sm:text-base"
                        onClick={getBeds24CalendarData}
                    >
                        Get Data
                    </button>
                </div>}

                { checkIfThereAreChanges() && <div className="flex flex-col">
                    <button
                        className="text-white py-2 px-3 bg-yellow-500 border border-yellow-500 rounded hover:text-white hover:bg-yellow-600 hover:border-yellow-600 mt-auto w-fit text-xs sm:text-base"
                        onClick={applyChanges}
                    >
                        Apply changes
                    </button>
                </div>}

                {(selectedHotel != 0) && (startDate && isDate(startDate)) && (endDate && isDate(endDate)) && data.length > 0 && !loading && <button
                    className="text-white py-2 px-3 bg-yellow-500 border border-yellow-500 rounded hover:text-white hover:bg-yellow-600 hover:border-yellow-600 mt-auto w-fit text-xs sm:text-base"
                    onClick={() => setShowBulkUpdate(true)}
                >
                    Bulk update
                </button>}
            </div>


            <div className="ml-auto flex flex-row mr-2">
                {data && data.length > 0 && <div className="hidden sm:block content-center custom-form border-none w-fit">
                    <MultiSelect
                        value={selectedFields}
                        onChange={(e: MultiSelectChangeEvent) => {
                            setSelectedFields(e.value)}
                        }
                        options={fields}
                        placeholder="Select Fields"
                        maxSelectedLabels={0}
                        className="w-full"
                        optionDisabled={option => option == "room" || option == "price"}
                    />
                </div>}

                <div className="flex flex-col content-center">
                    <i
                        className="fa-regular fa-circle-question fa-xl m-auto text-gray-600 hover:cursor-pointer hover:text-blue-500"
                        onClick={() => setShowLegendDialog(prevState => true)}
                    />
                </div>
            </div>

        </div>

        {loading && <div className="w-full px-5 mt-5">
            <ProgressBar mode="indeterminate" style={{height: '6px'}}/>
        </div>}

        {/*---------------DATES ROW---------------*/}
        {data && data.length > 0 && <Card className="flex flex-col overflow-x-scroll p-2 !grow relative">

            <div className="flex flex-row min-w-fit gap-2 mb-2 h-fit">
                <Card className="sticky left-0 invisible">
                    <div className="min-w-40 w-40 max-w-40 h-12 text-center"/>
                </Card>


                {data[0].calendar.map(calendarData => {
                    return <Card key={calendarData.from} pt={{body: {className: "!h-full !flex !flex-col"}, content: {className: "!h-full !justify-end !flex !flex-col"}}} className={`flex flex-col ${getDateCardHeaderBgColor(calendarData.from)}`}>
                        <div className="flex flex-col w-20 min-w-20 max-w-20 text-center poppins-font">
                            {new Date(calendarData.from).getDay() == 1 && <div className="font-bold">{getMonthName(calendarData.from)} {calendarData.from.split("-").slice(0,1)}</div>}
                            <div className="mt-auto font-bold">{daysOfWeek[new Date(calendarData.from).getDay()]}</div>
                            <div className="font-bold">{calendarData.from.split("-").slice(2,3)}</div>
                        </div>
                    </Card>
                })}
            </div>

            {data.map((roomData, index) => <div key={roomData.roomId + "_" + index} className={`flex flex-row min-w-fit gap-2 h-fit mb-2 relative`}>

                <CloudbedsCalendarTable
                    index={index}
                    selectedFields={selectedFields}
                    roomData={roomData}
                    currentUser={currentUser}
                    setChangeDialogRoom={setChangeDialogRoom}
                    setChangeDialogStartDate={setChangeDialogStartDate}
                    setShowChangeInventoryDialog={setShowChangeInventoryDialog}
                    setChangeInventoryDialogValue={setChangeInventoryDialogValue}
                    newInventoryMap={newInventoryMap}
                    setShowChangeMultiplierDialog={setShowChangeMultiplierDialog}
                    setChangeMultiplierDialogValue={setChangeMultiplierDialogValue}
                    newMultiplierMap={newMultiplierMap}
                    setShowChangePriceDialog={setShowChangePriceDialog}
                    setChangePriceDialogValue={setChangePriceDialogValue}
                    newPricesMap={newPricesMap}
                    setShowChangeMinStayDialog={setShowChangeMinStayDialog}
                    setChangeMinStayDialogValue={setChangeMinStayDialogValue}
                    newMinStayMap={newMinStayMap}
                    setShowChangeMaxStayDialog={setShowChangeMaxStayDialog}
                    setChangeMaxStayDialogValue={setChangeMaxStayDialogValue}
                    setShowChangeOverrideDialog={setShowChangeOverrideDialog}
                    setChangeOverrideDialogValue={setChangeOverrideDialogValue}
                    newMaxStayMap={newMaxStayMap}
                    newOverrideMap={newOverrideMap}
                />


                {loading && <LoadingOverlay/>}

            </div>)}



        </Card>}

        {changeDialogRoom && changeDialogStartDate && (changePriceDialogValue!=undefined) && <ChangePriceDialog
            showDialog={showChangePriceDialog}
            hideDialog={() => {
                setShowChangePriceDialog(prevState => false)
                setChangeDialogStartDate(prevState => undefined);
                setChangePriceDialogValue(prevState => undefined);
            }}
            startDate={changeDialogStartDate}
            room={changeDialogRoom}
            priceValue={changePriceDialogValue}
            onApply={changePrices}
            rooms={data.filter(roomData => roomData.linked == 0).map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))}
        />}

        {changeDialogRoom && changeDialogStartDate && (changeInventoryDialogValue!=undefined) && <ChangeInventoryDialog
            showDialog={showChangeInventoryDialog}
            hideDialog={() => {
                setShowChangeInventoryDialog(prevState => false)
                setChangeDialogStartDate(prevState => undefined);
                setChangeInventoryDialogValue(prevState => undefined);
            }}
            startDate={changeDialogStartDate}
            room={changeDialogRoom}
            inventoryValue={changeInventoryDialogValue}
            onApply={changeInventory}
            rooms={data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))}
        />}

        {changeDialogRoom && changeDialogStartDate && (changeMultiplierDialogValue!=undefined) && <ChangeMultiplierDialog
            showDialog={showChangeMultiplierDialog}
            hideDialog={() => {
                setShowChangeMultiplierDialog(prevState => false)
                setChangeDialogStartDate(prevState => undefined);
                setChangeMultiplierDialogValue(prevState => undefined);
            }}
            startDate={changeDialogStartDate}
            room={changeDialogRoom}
            multiplierValue={changeMultiplierDialogValue}
            onApply={changeMultiplier}
            rooms={data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))}
        />}

        {changeDialogRoom && changeDialogStartDate && (changeMinStayDialogValue!=undefined) && <ChangeMinStayDialog
            showDialog={showChangeMinStayDialog}
            hideDialog={() => {
                setShowChangeMinStayDialog(prevState => false)
                setChangeDialogStartDate(prevState => undefined);
                setChangeMinStayDialogValue(prevState => undefined);
            }}
            startDate={changeDialogStartDate}
            room={changeDialogRoom}
            minStayValue={changeMinStayDialogValue}
            onApply={changeMinStay}
            rooms={data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))}
        />}

        {changeDialogRoom && changeDialogStartDate && (changeMaxStayDialogValue!=undefined) && <ChangeMaxStayDialog
            showDialog={showChangeMaxStayDialog}
            hideDialog={() => {
                setShowChangeMaxStayDialog(prevState => false)
                setChangeDialogStartDate(prevState => undefined);
                setChangeMaxStayDialogValue(prevState => undefined);
            }}
            startDate={changeDialogStartDate}
            room={changeDialogRoom}
            maxStayValue={changeMaxStayDialogValue}
            onApply={changeMaxStay}
            rooms={data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))}
        />}

        {changeDialogRoom && changeDialogStartDate && changeOverrideDialogValue && <ChangeOverrideDialog
            showDialog={showChangeOverrideDialog}
            hideDialog={() => {
                setShowChangeOverrideDialog(prevState => false)
                setChangeDialogStartDate(prevState => undefined);
                setChangeOverrideDialogValue(prevState => undefined);
            }}
            startDate={changeDialogStartDate}
            room={changeDialogRoom}
            overrideValue={changeOverrideDialogValue}
            onApply={changeOverride}
            rooms={data.map(roomData => ({roomId: roomData.roomId, name: roomData.name, linked: roomData.linked}))}
        />}


        <Dialog
            header={dialogHeader}
            visible={showDialog}
            style={{ width: '50vw' }}
            onHide={hideDialog}
            pt={dialogPassThrough}
        >
            <p className="m-0">
                {dialogContent}
            </p>
        </Dialog>

        {showBulkUpdate && isDate(startDate) && isDate(endDate) && data.length > 0 && <BulkUpdateDialog
            showBulkUpdate={showBulkUpdate}
            onHide={() => setShowBulkUpdate(false)}
            startDate={startDate}
            endDate={endDate}
            data={data}
            onApply={onApplyBulkUpdate}
        />}


        {showLegendDialog && <LegendDialog
            showLegendDialog={showLegendDialog}
            onClose={() => setShowLegendDialog(prevState => false)}
        />}

    </div>

}