import {Card} from "primereact/card";
import {Tooltip} from "primereact/tooltip";
import {AuthenticatedUser, Role} from "../../../data/BackendClasses";
import React, {useState} from "react";
import {CalendarData, RoomData} from "./CloudbedsCalendarPage";
import {getStartDayTime} from "../../../lib/helpers";


export default function CloudbedsCalendarTable(
    {
        index,
        selectedFields,
        roomData,
        currentUser,
        setChangeDialogRoom,
        setChangeDialogStartDate,
        setShowChangeInventoryDialog,
        setChangeInventoryDialogValue,
        newInventoryMap,
        setShowChangeMultiplierDialog,
        setChangeMultiplierDialogValue,
        newMultiplierMap,
        setShowChangePriceDialog,
        setChangePriceDialogValue,
        newPricesMap,
        setShowChangeMinStayDialog,
        setChangeMinStayDialogValue,
        newMinStayMap,
        setShowChangeMaxStayDialog,
        setChangeMaxStayDialogValue,
        setShowChangeOverrideDialog,
        setChangeOverrideDialogValue,
        newMaxStayMap,
        newOverrideMap
    }:{
        index: number,
        selectedFields: string[],
        roomData: RoomData,
        currentUser: AuthenticatedUser,
        setChangeDialogRoom: (newValue: RoomData) => void
        setChangeDialogStartDate: (newValue: string) => void,
        setShowChangeInventoryDialog: (newValue: boolean) => void,
        setChangeInventoryDialogValue: (newValue: number) => void,
        newInventoryMap:  Map<string, number>,
        setShowChangeMultiplierDialog: (newValue: boolean) => void,
        setChangeMultiplierDialogValue: (newValue: number) => void,
        newMultiplierMap: Map<string, number>,
        setShowChangePriceDialog: (newValue: boolean) => void,
        setChangePriceDialogValue: (newValue: number) => void,
        newPricesMap: Map<string, number>,
        setShowChangeMinStayDialog: (newValue: boolean) => void,
        setChangeMinStayDialogValue: (newValue: number) => void,
        newMinStayMap: Map<string, number>,
        setShowChangeMaxStayDialog: (newValue: boolean) => void,
        setChangeMaxStayDialogValue: (newValue: number) => void,
        setShowChangeOverrideDialog: (newValue: boolean) => void,
        setChangeOverrideDialogValue: (newValue: string) => void,
        newMaxStayMap: Map<string, number>,
        newOverrideMap: Map<string, string>,

    }) {


    const [hideData, setHideData] = useState<boolean>(false);



    function getDateCardBgColor(index: number, date: string, minCheckIn: number) : string {
        if(minCheckIn != 0){
            if(isBetweenDates(date, minCheckIn)){
                return "!bg-pink-100";
            }
        }

        if(new Date(date).getDay() == 0 || new Date(date).getDay() == 6)
            return "!bg-blue-100";

        return index%2==0 ? "!bg-gray-100" : "!bg-gray-200";
    }

    function isBetweenDates(dateString: string, x: number): boolean {
        const date = new Date(dateString);
        const today = getStartDayTime(new Date());

        return date.getTime() >= today && date.getTime() <= today + (24 * 60 * 60 * 1000 * x);
    }

    function dateToString(date: Date) : string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    function getOverrideClassName(roomData: RoomData, calendarData: CalendarData) : string {
        let className = `line-clamp-1 ${newOverrideMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "!bg-yellow-500" : ""} hover:cursor-pointer`

        className = className.concat(` noCheckInOrCheckOutTarget_${roomData.roomId}_${calendarData.to}`);

        if(calendarData.override == "blackout"){
            className = className.concat(" text-white bg-black");
        } else if(calendarData.override == "none"){
            return className;
        } else {
            className = className.concat(" text-white bg-gray-400");
        }

        return className;
    }

    function prettyPrintOverride(overrideValue: string | undefined) : string{

        if(undefined == overrideValue)
            return "Undefined";

        switch (overrideValue){
            case 'none':
                return "Open";
            case 'blackout':
                return "Blackout";
            case 'noCheckIn':
                return "No check in";
            case 'noCheckOut':
                return "No check out";
            case 'noCheckInOrCheckOut':
                return "No check in/out";
            case 'exception':
                return "Exception";
            default:
                return "Error";
        }
    }


    return <>

        {/*LEFT CARD*/}
        <Card id="header-card" className={`flex flex-col text-nowrap sticky left-0 ${index%2==0 ? "!bg-gray-100" : "!bg-gray-200"}`} onClick={() => setHideData(prevState => !prevState)}>
            <div
                className={`divide-y divide-solid divide-gray-300 text-center !min-w-40 w-40 max-w-40 poppins-font`}
            >
                {selectedFields.includes("room", 0) && <>
                    <div
                        className="line-clamp-2 roomNameTarget hover:cursor-pointer font-bold "
                        data-pr-tooltip={`${roomData.name}`}
                        data-pr-position="right"
                        data-pr-at="right center"
                        data-pr-my="left center-2"
                    >
                        {roomData.name}
                    </div>
                    <Tooltip target=".roomNameTarget" />
                </>}

                {selectedFields.includes("inventory", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div className={`${hideData ? "hidden" : ""}`}>
                    <span className="mr-1">{`Inventory (${roomData.qty})`}</span>
                    <i
                        className="fa fa-question-circle inventoryQuestionTooltipTarget"
                        aria-hidden="true"
                        data-pr-tooltip={"The number of actual rooms or beds that remain for this date"}
                        data-pr-position="right"
                        data-pr-at="right center"
                    />
                    <Tooltip target=".inventoryQuestionTooltipTarget" />
                </div>}
                {selectedFields.includes("multiplier", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div className={`${hideData ? "hidden" : ""}`}>
                    <span className="mr-1">Multiplier</span>
                    <i
                        className="fa fa-question-circle multiplierQuestionTooltipTarget"
                        aria-hidden="true"
                        data-pr-tooltip={"Multiplier of the price by %"}
                        data-pr-position="right"
                        data-pr-at="right center"
                    />
                    <Tooltip target=".multiplierQuestionTooltipTarget" />
                </div>}
                {/*{selectedFields.includes("price", 0) && <div className={`${roomData.linked == 0 ? "" : "opacity-50"} ${hideData ? "hidden" : ""}`}>Price</div>}*/}
                {selectedFields.includes("min stay", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div className={`${hideData ? "hidden" : ""}`}>
                    <span className="mr-1">Min Stay</span>
                    <i
                        className="fa fa-question-circle minStayQuestionTooltipTarget"
                        aria-hidden="true"
                        data-pr-tooltip={"The minimum length of stay required"}
                        data-pr-position="right"
                        data-pr-at="right center"
                    />
                    <Tooltip target=".minStayQuestionTooltipTarget" />
                </div>}
                {selectedFields.includes("max stay", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div className={`${hideData ? "hidden" : ""}`}>
                    <span className="mr-1">Max Stay</span>
                    <i
                        className="fa fa-question-circle maxStayQuestionTooltipTarget"
                        aria-hidden="true"
                        data-pr-tooltip={"The maximum length of stay required"}
                        data-pr-position="right"
                        data-pr-at="right center"
                    />
                    <Tooltip target=".maxStayQuestionTooltipTarget" />
                </div>}
                {selectedFields.includes("override", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div className={`${hideData ? "hidden" : ""}`}>
                    <span className="mr-1">Override</span>
                    <i
                        className="fa fa-question-circle overrideQuestionTooltipTarget"
                        aria-hidden="true"
                        data-pr-tooltip={"Blackout / No check in / No check out"}
                        data-pr-position="right"
                        data-pr-at="right center"
                    />
                    <Tooltip target=".overrideQuestionTooltipTarget" />
                </div>}
            </div>
        </Card>

        {!hideData && roomData.calendar.map((calendarData, index) => <Card key={roomData.roomId + "_" + calendarData.from + "_" + index} id="date-card" className={`!flex !flex-col ${getDateCardBgColor(index, calendarData.from, roomData.min_check_in)}`}>
            <div className={`${currentUser.roles.includes(Role.CLOUDBEDS_MASTER) ? "divide-y divide-solid divide-gray-300" : ""} text-center flex-1 w-20 min-w-20 max-w-20 flex flex-col poppins-font`}>
                {/*DATE*/}
                {/*<div>{roomData.linked}</div>*/}
                {/*<div></div>*/}
                {selectedFields.includes("price", 0) && <div
                    onClick = {() => {
                        if(roomData.linked == 0 && calendarData.to != dateToString(new Date())) {
                            setChangeDialogRoom(roomData);
                            setChangeDialogStartDate(calendarData.from);
                            setShowChangePriceDialog(true);
                            setChangePriceDialogValue(calendarData.price1);
                        }
                    }}
                    className={`${newPricesMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : "bg-[#7bbf77]"} ${roomData.linked == 0 ? "hover:cursor-pointer" : "opacity-50"}`}
                >
                    {newPricesMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newPricesMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.price1} {roomData.currency_hotel}
                </div>}
                {/*INVENTORY*/}
                {selectedFields.includes("inventory", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div
                    onClick = {() => {
                        if(calendarData.to != dateToString(new Date())) {
                            setChangeDialogRoom(roomData);
                            setChangeDialogStartDate(calendarData.from);
                            setShowChangeInventoryDialog(true);
                            setChangeInventoryDialogValue(calendarData.numAvail);
                        }
                    }}
                    className={`${newInventoryMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : ""} hover:cursor-pointer`}
                >
                    {newInventoryMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newInventoryMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.numAvail}
                </div>}
                {/*MULTIPLIER*/}
                {selectedFields.includes("multiplier", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div
                    onClick = {() => {
                        if(calendarData.to != dateToString(new Date())) {
                            setChangeDialogRoom(roomData);
                            setChangeDialogStartDate(calendarData.from);
                            setShowChangeMultiplierDialog(true);
                            setChangeMultiplierDialogValue(calendarData.multiplier);
                        }
                    }}
                    className={`${newMultiplierMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : ""} hover:cursor-pointer`}
                >
                    {newMultiplierMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newMultiplierMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.multiplier*100}%
                </div>}
                {/*PRICE*/}
                {/*{selectedFields.includes("price", 0) && <div*/}
                {/*    onClick = {() => {*/}
                {/*        if(roomData.linked == 0 && calendarData.to != dateToString(new Date())) {*/}
                {/*            setChangeDialogRoom(roomData);*/}
                {/*            setChangeDialogStartDate(calendarData.from);*/}
                {/*            setShowChangePriceDialog(true);*/}
                {/*            setChangePriceDialogValue(calendarData.price1);*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    className={`${newPricesMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : "bg-[#7bbf77]"} ${roomData.linked == 0 ? "hover:cursor-pointer" : "opacity-50"}`}*/}
                {/*>*/}
                {/*    {newPricesMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newPricesMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.price1} {roomData.currency_hotel}*/}
                {/*</div>}*/}
                {/*MIN STAY*/}
                {selectedFields.includes("min stay", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div
                    onClick = {() => {
                        if(calendarData.to != dateToString(new Date())) {
                            setChangeDialogRoom(roomData);
                            setChangeDialogStartDate(calendarData.from);
                            setShowChangeMinStayDialog(true);
                            setChangeMinStayDialogValue(calendarData.minStay);
                        }
                    }}
                    className={`${newMinStayMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : ""} hover:cursor-pointer`}
                >
                    {newMinStayMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newMinStayMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.minStay}
                </div>}
                {/*MAX STAY*/}
                {selectedFields.includes("max stay", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <div
                    onClick = {() => {
                        if(calendarData.to != dateToString(new Date())) {
                            setChangeDialogRoom(roomData);
                            setChangeDialogStartDate(calendarData.from);
                            setShowChangeMaxStayDialog(true);
                            setChangeMaxStayDialogValue(calendarData.maxStay);
                        }
                    }}
                    className={`${newMaxStayMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : ""} hover:cursor-pointer`}
                >
                    {newMaxStayMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newMaxStayMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.maxStay}
                </div>}
                {/*OVERRIDE*/}
                {selectedFields.includes("override", 0) && currentUser.roles.includes(Role.CLOUDBEDS_MASTER) && <>
                    <div
                        onClick = {() => {
                            if(calendarData.to != dateToString(new Date())) {
                                setChangeDialogRoom(roomData);
                                setChangeDialogStartDate(calendarData.from);
                                setShowChangeOverrideDialog(true);
                                setChangeOverrideDialogValue(calendarData.override);
                            }
                        }}
                        className={getOverrideClassName(roomData, calendarData)}
                        data-pr-tooltip={`${newOverrideMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? prettyPrintOverride(newOverrideMap.get(JSON.stringify([roomData.roomId, calendarData.from]))) : prettyPrintOverride(calendarData.override)}`}
                        data-pr-position="right"
                        data-pr-at="right center"
                        data-pr-my="left center-2"
                    >
                        {newOverrideMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? prettyPrintOverride(newOverrideMap.get(JSON.stringify([roomData.roomId, calendarData.from]))) : prettyPrintOverride(calendarData.override)}
                    </div>
                    <Tooltip target={`.noCheckInOrCheckOutTarget_${roomData.roomId}_${calendarData.to}`} />
                </>}
            </div>
        </Card>)}

        {hideData && roomData.calendar.map((calendarData, index) => <Card pt={{content: {className: "!my-auto !h-full !content-center"}, body: {className: "!h-full"}}} key={roomData.roomId + "_" + calendarData.from + "_" + index} id="date-card" className={`!flex !flex-col ${getDateCardBgColor(index, calendarData.from, roomData.min_check_in)}`}>
            <div className={`${currentUser.roles.includes(Role.CLOUDBEDS_MASTER) ? "divide-y divide-solid divide-gray-300" : ""} text-center flex-1 w-20 min-w-20 max-w-20 flex flex-col poppins-font `}>
                {selectedFields.includes("price", 0) && <div
                    onClick = {() => {
                        if(roomData.linked == 0 && calendarData.to != dateToString(new Date())) {
                            setChangeDialogRoom(roomData);
                            setChangeDialogStartDate(calendarData.from);
                            setShowChangePriceDialog(true);
                            setChangePriceDialogValue(calendarData.price1);
                        }
                    }}
                    className={`${newPricesMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? "bg-yellow-500" : ""} ${roomData.linked == 0 ? "hover:cursor-pointer" : "opacity-50"}`}
                >
                    {newPricesMap.has(JSON.stringify([roomData.roomId, calendarData.from])) ? newPricesMap.get(JSON.stringify([roomData.roomId, calendarData.from])) : calendarData.price1} {roomData.currency_hotel}
                </div>}
            </div>
        </Card>)}

    </>
}