import {GuestChatMessage, HostChatMessage} from "./ChatPannelMessageComponents";
import {useContext, useEffect, useRef, useState} from "react";
import {Dialog} from "primereact/dialog";
import {Panel} from "primereact/panel";
import {BlockedConversation, Conversation, Message, MessageTemplate} from "../../data/BackendClasses";
import {useCurrentUser} from "../../data/user";
import {getJsonFromBackend, postJsonFileToBackend, postJsonToBackend} from "../../data/network";
import {pushErrorModal} from "../../components/ErrorModal";
import {Image} from 'primereact/image';
import {GlobalContext} from "../../data/GlobalContext";

type Template = {templateName: string, templateText: string}

export default function ChatPannel({currentConversation, blockedConversation, newMessage} : {currentConversation: Conversation|undefined, blockedConversation: BlockedConversation | undefined, newMessage: Message|undefined}) {

    const [messages, setMessages] = useState<Message[]>([]);
    const [message, setMessage] = useState<string>('');
    const [selectedImage, setSelectedImage] = useState<File|undefined>(undefined);
    const [showMessageTemplate, setShowMessageTemplate] = useState<boolean>(false);
    const [templates, setTemplates] = useState<MessageTemplate[]>([]);

    const numberOfRows = message.split("\n").length;


    var chatRef: HTMLDivElement | null | undefined = undefined;
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const chatEnd = useRef<HTMLDivElement>(null);

    const currentUser = useCurrentUser();
    const {showToastMessage} = useContext(GlobalContext);



    useEffect(() => {

        setMessage('');

        currentConversation && getJsonFromBackend("/chat/messages/get?bookingId=" + (currentConversation ? currentConversation.bookingId : null))
            .then((rsp: Message[]) => {
                setMessages(rsp);
            })
            .catch(err => {
                pushErrorModal(err);
            })
    }, [currentConversation])

    useEffect(() => {
        if(currentConversation && newMessage) {

            if (newMessage.bookingId == currentConversation.bookingId) {

                if(newMessage.id == 0){
                    setMessages([...messages, newMessage]);
                }
                else {
                    getJsonFromBackend("/chat/messages/get?bookingId=" + (currentConversation ? currentConversation.bookingId : null))
                        .then((rsp: Message[]) => {
                            setMessages(rsp);
                        })
                        .catch(err => {
                            pushErrorModal(err);
                        })
                }
            }
        }
    }, [newMessage])

    useEffect(() => {
        // @ts-ignore
        chatEnd.current.scrollIntoView({ behavior: 'smooth' });
    });

    useEffect(() => {
        currentConversation && getJsonFromBackend("/chat/messages/get-templates")
            .then((rsp: MessageTemplate[]) => {
                console.log("GOT TEMPLATES ", rsp)
                setTemplates(rsp);
            })
            .catch(err => {
                console.log("ERR TEMPLATES")
                pushErrorModal(err);
            })
    }, [currentConversation])

    function changeMessage(e: any){
        e.preventDefault();
        setMessage(e.target.value);
    }

    function sendMessage(e: any){
        e.preventDefault();

        if(selectedImage){
            const formData = new FormData();
            formData.append('image', selectedImage);
            formData.append('data', JSON.stringify({id: null, bookingId: currentConversation?.bookingId, message: "test", type: "media", sendDate: undefined}))

            postJsonFileToBackend("/chat/messages/send-image", formData)
                .then(clearImage)
                .catch(err => pushErrorModal(err));
        }

        if(message != ''){
            postJsonToBackend("/chat/messages/send", {id: null, bookingId: currentConversation?.bookingId, message: message, type: "text", sendDate: undefined})
                .then(rsp => setMessage(''))
                .catch(err => pushErrorModal(err));
        }

    }

    function handleImageUpload(e: any) {
        const file: File = e.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    }

    function clearImage() {
        if(fileInputRef != null && fileInputRef.current != null){
            fileInputRef.current.value = '';
        }
        setSelectedImage(undefined);
    }

    function buttonShouldBeDisabled() {
        return (blockedConversation == undefined) || (!!blockedConversation && blockedConversation.blockedBy != currentUser.username) || currentConversation?.unlisted;
    }

    function pickTemplate(template: Template){
        console.log("Template ", template.templateText.replace("$guest_name$", currentConversation?.guest_name? currentConversation.guest_name : "$guest_name$"))
        setMessage(template.templateText.replace("$guest_name$", currentConversation?.guest_name? currentConversation.guest_name : "$guest_name$"));
        setShowMessageTemplate(false);
    }

    function generateResponse() {
        currentConversation && postJsonToBackend("/chat/conversations/export", currentConversation.bookingId)
            .then(rsp => {
                console.log("GENERATE MESSAGE RESPONSE", rsp);
                setMessage(rsp)
            })
            .catch(err => showToastMessage("Error", "Something went wrong", "error"));
    }


    // return <div className="flex h-[calc(100% + 2.25rem)] w-1/2 mt-[-2.25rem]">
    return <div className="flex h-full w-1/2">
        <div className="flex flex-col w-full shadow-md rounded-lg overflow-hidden">
            {/* ------HEADER------ */}
            <div className="px-6 py-4 bg-white/95 flex">
                <div className="font-bold text-xl mb-2">{currentConversation ? currentConversation.guest_name : "Start chatting"}</div>
                {blockedConversation && blockedConversation.blockedBy != currentUser.username && <span className="ml-2"><i className="fas fa-exclamation-triangle"/> (currently blocked by {blockedConversation.blockedBy})</span>}
                {currentConversation && <span data-toggle="tooltip" title={messages.length + " total messages"} className="bg-white/60 p-1 rounded ml-auto">{messages.length}</span>}
            </div>


            <div className="flex flex-col flex-auto flex-grow bg-white h-full overflow-y-auto p-4" ref={el => chatRef = el}>
                <div className="flex flex-col mb-4">
                    <div className="">
                        <div className="">

                            {messages.map(message => {
                                return message.source === "guest" ? <GuestChatMessage key={message.id + message.message + message.time} message={message}/> : <HostChatMessage key={message.id + message.message + message.time} message={message}/>
                            })}

                            <div ref={chatEnd}/>

                        </div>
                    </div>
                </div>

            </div>


            {/* ------FOOTER------ */}
            <div className="px-6 py-4 bg-white/95">
                {currentConversation && <form action="#" method="post" onSubmit={(e: any) => {e.preventDefault()}}>

                    {selectedImage && <Image src={URL.createObjectURL(selectedImage)} zoomSrc={URL.createObjectURL(selectedImage)} alt="Image" width="80" height="60" preview />}
                    {selectedImage && <i className="fa fa-times ml-2 clickable" onClick={clearImage}/>}

                    <div className="flex items-center rounded">
                        <div className="pt-1 flex">

                            {/*TEMPLATES*/}
                            {templates.length > 0 && <div className="input-group-prepend mb-auto mr-1">
                                <label>
                                    <span className={"bg-gray-500 text-white p-2 rounded " + (buttonShouldBeDisabled() ? "disabled" : "")} onClick={() => !buttonShouldBeDisabled() && setShowMessageTemplate(true)}>
                                        <i className="far fa-file-alt"/>
                                    </span>
                                </label>
                                <Dialog header="Templates" visible={showMessageTemplate} style={{ width: '50vw' }} onHide={() => setShowMessageTemplate(false)}>
                                    {templates.map(template => {
                                        return <Panel key={template.id} className="mb-5" header={template.templateName} toggleable>
                                            <div className="multiple-lines-text">
                                                {template.templateText}
                                            </div>
                                            <button className="btn btn-info float-right" onClick={() => pickTemplate(template)}>Pick</button>
                                        </Panel>
                                    })}
                                </Dialog>
                            </div>}

                            <div className="input-group-prepend mb-auto mr-1">
                                <input type="file" disabled={buttonShouldBeDisabled()} ref={fileInputRef} className="hidden" id="upload-file" onChange={(e) => handleImageUpload(e)}/>
                                <label htmlFor="upload-file">
                                    <span className={"bg-gray-500 text-white p-2 rounded " + (buttonShouldBeDisabled() ? "disabled" : "")}>
                                        <i className="fa fa-paperclip"/>
                                    </span>
                                </label>
                            </div>
                        </div>



                        <textarea
                            disabled={buttonShouldBeDisabled()}
                            name="message"
                            placeholder="Type Message ..."
                            className="flex-grow p-2 rounded-l focus:outline-none border border-gray-300"
                            rows={numberOfRows < 6 ? numberOfRows : 6}
                            value={message}
                            onChange={changeMessage}
                        />
                        <button
                            type="button"
                            className={`${buttonShouldBeDisabled() ? "bg-red-700" : "bg-blue-500"} text-white p-2 rounded-r place-self-stretch`}
                            onClick={sendMessage}
                            disabled={buttonShouldBeDisabled() || (message == '' && selectedImage == undefined)}
                        >
                            Send
                        </button>

                        <button
                            type="button"
                            className={`${buttonShouldBeDisabled() ? "bg-red-700" : "bg-blue-500"} text-white p-2 rounded-md ml-2`}
                            onClick={generateResponse}
                            disabled={buttonShouldBeDisabled()}
                        >
                            Generate
                        </button>

                    </div>
                </form>}
            </div>
        </div>
    </div>




}